import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Core
import { Item } from '../model/item';
import { VisitFilterReturnModel } from '../model/visit-filter-return-model';

@Injectable({
  providedIn: 'root'
})
export class VisitFilterService {

  private arrayTextFilter = new BehaviorSubject<Item[]>([]);
  private filterType = new BehaviorSubject<string>('');
  private fromDate = new BehaviorSubject<Date>(null);
  private toDate = new BehaviorSubject<Date>(null);
  private filterDateType = new BehaviorSubject<string>('');
  private visitFilterReturnModel = new BehaviorSubject<VisitFilterReturnModel>(null);
  private filterText = new BehaviorSubject<string>('');

  constructor() {}

  setArreyTextFilter(arrayTextFilter: Item[]) {
    this.arrayTextFilter.next(arrayTextFilter);
  }

  public getArrayTextFilter = this.arrayTextFilter.asObservable();

  setFilterType(filterType: string) {
    this.filterType.next(filterType);
  }

  public getFilterType = this.filterType.asObservable();

  setFromDate(fDate: Date) {
    this.fromDate.next(fDate);
  }

  public getFromDate = this.fromDate.asObservable();

  setToDate(tDate: Date) {
    this.toDate.next(tDate);
  }

  public getToDate = this.toDate.asObservable();

  setFilterDateType(filterDateType: string) {
    this.filterDateType.next(filterDateType);
  }

  public getFilterDateType = this.filterDateType.asObservable();

  setVisitFilterReturnModel(visitFilterReturnModel: VisitFilterReturnModel) {
    this.visitFilterReturnModel.next(visitFilterReturnModel);
  }

  public getVisitFilterReturnModel = this.visitFilterReturnModel.asObservable();

  setFilterText(filterText: string) {
    this.filterText.next(filterText);
  }

  public getFilterText = this.filterText.asObservable();

}
