//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from '../account.service';
import { LaboratoryService } from '../../laboratory/laboratory.service';
import { PhlebotomistService } from '../../phlebotomist/phlebotomist.service';

//Core
import { User } from '../user';
import { ChangePassword } from '../change-password/change-password';
import { Laboratory } from '../../laboratory/laboratory';
import { Phlebotomist } from '../../phlebotomist/phlebotomist';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent extends OnDestroyMixin implements OnInit {

  public title = 'Users';
  public users: User[] = [];
  public user: User;
  public changePass: ChangePassword;
  //public userLogged: User;
  public cols: any[];
  public loading = false;
  public showResetPasswordDialog = false;

  public defaultLabDialog = false;
  public laboratories: Laboratory[] = [];
  public selectLaboratories: Laboratory[] = [];

  public defaultPhlebDialog = false;
  public phlebotomists: Phlebotomist[] = [];
  public selectPhlebotomists: Phlebotomist[] = [];
  public showUserGroupNameDialog = false;
  public selectedLaboratory: Laboratory;
  public userId: string;

  constructor(private readonly accountService: AccountService,
    private readonly laboratoryService: LaboratoryService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();

  }

  public ngOnInit(): void {

    this.user = {} as User;

    if (this.isUserLoggedIn()) {

      this.getUsers();      

    } else {

      this.router.navigate(['/login']);

    }

  }

  public getUsers(): void {

    this.loading = true;

    this.accountService.getUsers()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        //console.log(data)
        this.users = data as User[];
        this.loadColumns();
        this.loading = false;
        this.getLaboratories();
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'userskey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'phoneNumber', header: 'Phone Number' },
      { field: 'userName', header: 'Username' },
      { field: 'userGroupName', header: 'User Group Name' }
    ];
  }

  public resetPasswordModal(user: User): void {

    this.user = user;
    this.showResetPasswordDialog = true;
  }

  public resetUserPassword(): void {

    this.showResetPasswordDialog = false;

    this.changePass = { userName: this.user.userName, email: this.user.email, oldpassword: 'password', newpassword: 'password', confirmpassword: 'password' } as ChangePassword;

    this.accountService.resetUserPassword(this.changePass)
      .subscribe((res: any) => {

        let returnUser = res as User;

        this.messageService.add({ key: 'userskey', severity: 'success', summary: 'Change Password', detail: `User ${returnUser.userName} his password was reset, his new password is: 'password'.` });
      },
        (error: any) => {

          this.messageService.add({ key: 'userskey', severity: 'error', summary: 'Error', detail: error });

        });

  }

  public accountLockout(user: User): void {

    this.accountService.accountLockout(user.email)
      .subscribe((res: any) => {
        //console.log(res);
        this.getUsers();

        if (res === true) {
          //console.log('blocked');
          this.messageService.add({ key: 'userskey', severity: 'warn', summary: 'User account', detail: `The account for "${user.userName}" has been blocked.` });
        } else {
          console.log('unblocked');
          this.messageService.add({ key: 'userskey', severity: 'success', summary: 'User account', detail: `The account for "${user.userName}" has been unblocked.` });
        }
      },
        (error: any) => {

          this.messageService.add({ key: 'userskey', severity: 'error', summary: 'Error', detail: error });
        });

  }

  public showDefaultLabsDialog(userId: string) {

    this.selectLaboratories = [];
    var labslist = this.laboratories;
    this.laboratories = [];

    this.accountService.getUser(userId)
      .subscribe((res: any) => this.user = res as User,
        (error: any) => {

          console.log(error);
        },
        () => {

          this.laboratories = labslist;

          if (this.user.labLicenseNumberList !== undefined && this.user.labLicenseNumberList !== null) {           

            var labLicenseNumber: string[] = this.user.labLicenseNumberList.split(',');

            if (labLicenseNumber.length == 0 && this.user.labLicenseNumberList.length > 0) {
              labLicenseNumber.push(this.user.labLicenseNumberList);
            }

            labLicenseNumber.forEach((lab) => {

              const filterLaboratory = this.laboratories.filter(a => a.licenseNumber === lab);

              if (filterLaboratory !== undefined) {
                this.selectLaboratories.push(filterLaboratory[0]);
              }

            });
          }

          this.defaultLabDialog = true;

        });    
  }

  public setDefaultLabs(): void {

    this.defaultLabDialog = false;

    this.accountService.setDefaultLabs(this.user.id, this.selectLaboratories)
      .subscribe(() => {

        this.messageService.add({ key: 'userskey', severity: 'success', summary: 'Default laboratories', detail: 'Saved successfully.' });
      },
        (error: any) => {

          this.messageService.add({ key: 'userskey', severity: 'error', summary: 'Error', detail: error });

        });

  }

  public getLaboratories(): void {

    this.laboratories = [];

    this.laboratoryService.getLaboratories()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.laboratories = data,
        (error: any) => {
          console.log(error);
        },
        () => {
          this.getPhlebotomists();
        });
  }

  public showDefaultPhlebDialog(userId: string) {

    this.selectPhlebotomists = [];
    var phlebslist = this.phlebotomists;
    this.phlebotomists = [];

    this.accountService.getUser(userId)
      .subscribe((res: any) => this.user = res as User,
        (error: any) => {

          console.log(error);

        },
        () => {

          this.phlebotomists = phlebslist;

          if (this.user.phlebotomistList !== undefined && this.user.phlebotomistList !== null) {

            var phlebotomists: string[] = this.user.phlebotomistList.split(',');

            if (phlebotomists.length == 0 && this.user.phlebotomistList.length > 0) {
              phlebotomists.push(this.user.phlebotomistList);
            }

            phlebotomists.forEach((p) => {

              var id = Number(p);

              const filterPhlebotomist = this.phlebotomists.filter(a => a.id === id);

              if (filterPhlebotomist !== undefined) {
                this.selectPhlebotomists.push(filterPhlebotomist[0]);
              }

            });
          }

          this.defaultPhlebDialog = true;

        });    
  }

  public setDefaultPhlebotomists(): void {

    this.defaultPhlebDialog = false;

    this.accountService.setDefaultPhlebotomists(this.user.id, this.selectPhlebotomists)
      .subscribe(() => {

        this.messageService.add({ key: 'userskey', severity: 'success', summary: 'Default Phlebotomists', detail: 'Saved successfully.' });
      },
        (error: any) => {

          this.messageService.add({ key: 'userskey', severity: 'error', summary: 'Error', detail: error });

        });

  }

  public getPhlebotomists(): void {

    this.phlebotomists = [];

    this.phlebotomistService.getPhlebotomistsInactive()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.phlebotomists = data as Phlebotomist[],
        (error: any) => {
          console.log(error);
        });
  }

  public showModalUserGroupName(userId: string): void {

    this.userId = userId;
    this.selectedLaboratory = undefined;
    this.showUserGroupNameDialog = true;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public updateUserGroupName() {

    this.accountService.updateUserGroupName(this.userId, this.selectedLaboratory.name)
      .subscribe(() => {

        this.userId = '';
        this.showUserGroupNameDialog = false;
        this.getUsers();
        this.messageService.add({ key: 'userskey', severity: 'success', summary: 'Changed User Group Name', detail: 'Saved successfully.' });
      },
        (error: any) => {

          this.messageService.add({ key: 'userskey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  //public getlocalUser(): User {

  //  let userLogged = this.accountService.getLoggedInUser();

  //  if (userLogged == undefined) {
  //    this.router.navigate(['/login'])
  //  }

  //  return userLogged;
  //}
}
