//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from '../account.service';
import { UserLoggedInService } from '../user-logged-in.service';

//Core
import { UserLogin } from '../user-login';
import { ReturnToken } from '../return-token';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent extends OnDestroyMixin implements OnInit {

  public userLogin: UserLogin;
  public loading = false;
  public showPassword = false;
  public iconButtonPassword = "pi pi-eye";

  constructor(private readonly accountService: AccountService,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    this.userLogin = { username: '', password: '' } as UserLogin;

    if (this.isUserLoggedIn()) {

      this.router.navigate(['/visits']);
    }
  }

  public login(): void {

    this.loading = true;

    this.accountService.login(this.userLogin)
      .pipe(untilComponentDestroyed(this))
      .subscribe((returnToken: ReturnToken) => {

        if (returnToken) {
          this.loading = false;           
          this.accountService.storeUserData(returnToken.token, returnToken.returnUser);
          this.userLoggedInService.setUserLoggedIn(true);
          this.userLoggedInService.updateUserLogged(returnToken.returnUser);
          //this.router.navigate(['/visits']);
          window.open('/visits', "_self")
        }
      },
        (error: any) => {
          this.loading = false;
          if (error === 'Either password or username are incorrect, please try again.') {
            this.messageService.add({ key: 'loginkey', severity: 'info', summary: 'Info', detail: error });
          } else {            
            this.messageService.add({ key: 'loginkey', severity: 'error', summary: 'Error', detail: error });
          }
        });
  }

  public showAndHidePassword(): void {
    if (this.showPassword === false) {
      this.iconButtonPassword = "pi pi-eye-slash"
      this.showPassword = true;
    } else {
      this.iconButtonPassword = "pi pi-eye"
      this.showPassword = false;
    }
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
