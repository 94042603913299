//Angular
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from '../patient.service';
import { AccountService } from '../../account/account.service';
import { PostalCodeService } from '../../postal-code/postal-code.service';
import { NeighborhoodService } from '../../neighborhood/neighborhood.service';

//Core
import { Patient } from '../patient';
import { PostalCode } from '../../postal-code/postal-code';
import { User } from '../../account/user'
import { Neighborhood } from '../../neighborhood/neighborhood';

@Component({
  selector: 'app-pop-up-edit-patient',
  templateUrl: 'pop-up-edit-patient.component.html'
})
export class PopUpEditPatientComponent extends OnDestroyMixin implements OnInit {

  public patient: Patient;
  public selectPostalCode: PostalCode;
  public postalCodeSearchResults: PostalCode[];
  public neighborhoods: Neighborhood[] = [];
  public selectedNeighborhood: Neighborhood;
  public progressSpinner = false;
  public disabledNeighborhood = false;
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly postalCodeService: PostalCodeService,
    private readonly neighborhoodService: NeighborhoodService,
    private readonly router: Router) {
    super();       

  }

  public ngOnInit(): void {

    this.patient = {} as Patient;
  }

  public loadData(receivePatient: Patient): void {

    this.patient = receivePatient;

    if (this.isUserLoggedIn()) {

      this.setEditPhysicalPostalCodes(receivePatient.physicalZipCode);

      this.getNeighborhoods(receivePatient.physicalCity);
    }
  }

  public onSubmit(): void {

    this.patient.physicalCity = this.selectPostalCode.city;

    this.patientService.putPatient(this.patient)
      .subscribe((res: any) => {

        this.closeWindow.emit(false);
      },
        (error: any) => {

          console.log(error);
        });
  }

  public setEditPhysicalPostalCodes(zipCode: string): void {

    this.postalCodeService.searchByZipCode(zipCode)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        const postalCodeSearchResults = data as PostalCode[];

        const filterPhysicalPostalCode = postalCodeSearchResults.filter(a => a.code === zipCode);

        if (filterPhysicalPostalCode !== undefined) {
          this.selectPostalCode = filterPhysicalPostalCode[0];
        }

      });
  }

  public getNeighborhoods(city: string): void {

    this.neighborhoodService.getNeighborhoods(city)
      .subscribe((res: any) => this.neighborhoods = res as Neighborhood[],
        (error: any) => {

          console.log(error);
        },
        () => {

          if (this.patient.neighborhoodId > 0) {

            const filterNeighborhood = this.neighborhoods.filter(a => a.id === this.patient.neighborhoodId);

            if (filterNeighborhood !== undefined) {
              this.selectedNeighborhood = filterNeighborhood[0];
            }
          }

        });
  }

  public onChangeNeighborhood(event: any): void {
    //console.log(event);
    this.patient.neighborhoodId = event.id;
  }

  // search  postal codes
  public searchPostalCodes(event: any): void {

    this.postalCodeService.searchPostalCodes(event.query)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.postalCodeSearchResults = data;
      });
  }

  // set  postal code information
  public onPostalCodeSelection(postalCode: PostalCode): void {
    //console.log(postalCode);
    this.selectPostalCode = postalCode;
    this.patient.physicalAddressState = postalCode.state;
    this.patient.physicalZipCode = postalCode.code;
    this.getNeighborhoods(postalCode.city);
  }

  // clear postal code information
  public clearSelectedPostalCode(): void {

    this.selectPostalCode = undefined;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  }

}
