//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from './patient.service';
import { AccountService } from '../account/account.service';
import { UserLoggedInService } from '../account/user-logged-in.service';
import { PaginationService } from '../shared/pagination/pagination.service';

//Core
import { Patient } from './patient';
import { User } from '../account/user';

// PrimeNg
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html'
})
export class PatientComponent extends OnDestroyMixin implements OnInit {

  public patients: Patient[] = [];
  public totalRecords: number;
  public userLogged: User;
  public cols: any[];
  public loading = true;
  public displayOrderDialog = false;
  public patientId = 0;
  public deleteModal = false;
  public btnDelete = false;

  constructor(private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly messageService: MessageService,
    private readonly paginationService: PaginationService,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();

      this.loadColumns();

      /*this.getPatients();*/

    } else {

      this.router.navigate(['/login']);

    }

  }

  //public getPatients(): void {

  //  this.loading = true;

  //  this.patientService.getPatients()
  //    .pipe(untilComponentDestroyed(this))
  //    .subscribe((data) => {

  //      this.patients = data as Patient[];
  //      this.loadColumns();
  //      this.loading = false;
  //    },
  //      (error: any) => {

  //        this.loading = false;
  //        this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });
  //      });
  //}

  public loadPatients(event: LazyLoadEvent): void {

    const params = this.paginationService.getEventParams(event);
    this.loading = true;

    forkJoin(
      this.patientService.getPatientsAsync(params),
      this.patientService.getPatientsAsyncCount(params))
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (data) => {
          this.patients = data[0] as Patient[];
          this.totalRecords = data[1] as number;
          /*this.loadColumns();*/
          this.loading = false;          
        },
        (error: string) => {
          this.loading = false;
          this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public addNewOrder(id: number): void {

    this.router.navigate([`/patient-orders/${id}`]);
  }

  public selectPatient(id: number): void {

    this.router.navigate([`/patient-details/${id}`]);
  } 

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'fullName', header: 'Name' },
      { field: 'phoneNumber', header: 'Phone' },
      { field: 'birthDate', header: 'BirthDate' },
      { field: 'physicalCity', header: 'City' }
    ];
  }

  public showDeleteConf(id: number): void {

    this.patientId = id;
    this.deleteModal = true;
  }

  public deletePatient(): void {

    this.deleteModal = false;

    this.patientService.deletePatient(this.patientId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'patientkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          const lazyEvent: LazyLoadEvent = {

            first: 0,
            rows: 15,
            filters: undefined,
            sortField: undefined,
            sortOrder: 1
          };

          this.loadPatients(lazyEvent);

          /*this.getPatients();*/
        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged === undefined) {
      this.router.navigate(['/account/login'])
    }

    this.userLoggedInService.updateUserLogged(userLogged);

    const userRoles = userLogged.roles.filter(row => row === 'Admin' || row === 'SuperAdmin');

    if (userRoles !== undefined) {
      this.btnDelete = true;
    }
    else {
      this.btnDelete = false;
    }

    return userLogged;
  }

}
