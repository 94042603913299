// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

// Objects
import { SurveyQuestion } from './survey-question';
import { Rating } from './rating';
import { ExistRating } from './existRating';

@Injectable()
export class SurveyService extends BaseService {

  private readonly apiUrl = 'api/survey';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getSurveyQuestions(): Observable<SurveyQuestion[]> {

    return this.http.get<SurveyQuestion[]>(this.apiUrl);
  }

  public existRating(orderId: number, phlebotomistId: number): Observable<ExistRating> {

    return this.http.get<ExistRating>(`${this.apiUrl}/exist-rating/${orderId}/${phlebotomistId}`);
  }

  public postSurvey(ratings: Rating[]): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}`, ratings, { headers: this.PutPostHeaders });
  }

}
