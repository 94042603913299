// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

// Objects
import { Phlebotomist } from './phlebotomist';
import { RouteTracking } from './route-tracking/route-tracking';

@Injectable()
export class PhlebotomistService extends BaseService {

  private readonly apiUrl = 'api/phlebotomist';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getPhlebotomist(id: number): Observable<Phlebotomist> {

    return this.http.get<Phlebotomist>(`${this.apiUrl}/${id}`);
  }

  public getPhlebotomists(): Observable<Phlebotomist[]> {

    return this.http.get<Phlebotomist[]>(this.apiUrl);
  }

  public getMobilePhlebotomists(pincode): Observable<Phlebotomist[]> {

    return this.http.get<Phlebotomist[]>(`${this.apiUrl}/mobile-phlebotomists/${pincode}`);
  }

  public getPhlebotomistsInactive(): Observable<Phlebotomist[]> {

    return this.http.get<Phlebotomist[]>(`${this.apiUrl}/inactive/false`);
  }

  public getUserDefaultPhlebotomists(userId: string): Observable<Phlebotomist[]> {

    return this.http.get<Phlebotomist[]>(`${this.apiUrl}/default-phlebotomists/${userId}`);
  }

  public getRouteTracking(id: number, date: Date, orderId: number): Observable<RouteTracking[]> {

    const filterDate = this.replaceAll(`${date.toLocaleDateString('en-US')}`, '/', '-');

    return this.http.get<RouteTracking[]>(`${this.apiUrl}/route-tracking-phlebotomist/${id}/${filterDate}/${orderId}`);
  }

  public replaceAll(string, search, replace): string {

    return string.split(search).join(replace);
  }

  public putPhlebotomist(phlebotomist: Phlebotomist): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}`, phlebotomist, { headers: this.PutPostHeaders });
  } 

  public postPhlebotomist(phlebotomist: Phlebotomist): Observable<Phlebotomist> {

    return this.http.post<Phlebotomist>(`${this.apiUrl}`, phlebotomist, { headers: this.PutPostHeaders });
  }

  public deletePhlebotomist(id: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/${id}`);
  }

}
