// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//core
import { PatientOrder } from './patient-order';
import { FilterParams } from './model/filter-params';
import { VisitLogParams } from './model/filter-params';
import { VisitFilterReturnModel } from './model/visit-filter-return-model';
import { ExistVisitRangeMinutesDto } from './model/exist-visit-range-minutes-dto';
import { CompletionTimeInEachState } from '../phlebotomist/route-tracking/completion-time-in-each-state';
import { ShortPatientOrder } from './short-patient-order';
import { PatientDocument } from './model/patientDocument';

// Moment
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

@Injectable()
export class PatientOrderService extends BaseService {

  private readonly apiUrl = 'api/patientorder';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getPatientOrder(id: number): Observable<PatientOrder> {

    return this.http.get<PatientOrder>(`${this.apiUrl}/${id}`);
  }

  public getPatientDocuments(patientId: number, orderId: number, id: number): Observable<PatientDocument[]> {

    return this.http.get<PatientDocument[]>(`${this.apiUrl}/documents-by-patientId-and-orderId/${patientId}/${orderId}/${id}`);
  }

  //public getVisitsWithFilter(fromDate: Date, toDate: Date, arrayTextFilter: string[], filterType: string): Observable<PatientOrder[]> {

  //  /* tslint:disable:no-magic-numbers */
  //  fromDate.setHours(0, 0, 0, 0);
  //  toDate.setHours(23, 59, 59, 999);
  //  /* tslint:enable:no-magic-numbers */

  //  //const utcFromDate = moment.utc(fromDate).toDate();
  //  //const utcToDate = moment.utc(toDate).toDate();

  //  const fParams = {
  //    fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
  //    toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
  //    arrayTextFilter: arrayTextFilter,
  //    filterType: filterType
  //  } as FilterParams;;

  //  return this.http.post<PatientOrder[]>(`${this.apiUrl}/visits-with-filter`, fParams, { headers: this.PutPostHeaders });
  //}

  public getVisitsWithFilter(fromDate: Date, toDate: Date, arrayTextFilter: string[], filterType: string, userId: string): Observable<VisitFilterReturnModel[]> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      arrayTextFilter: arrayTextFilter,
      filterType: filterType,
      userId: userId
    } as FilterParams;
    //console.log(fParams);
    return this.http.post<VisitFilterReturnModel[]>(`${this.apiUrl}/visits-with-filter`, fParams, { headers: this.PutPostHeaders });
  }

  public getPatientToPatientOrders(patientId: number): Observable<PatientOrder[]> {

    return this.http.get<PatientOrder[]>(`${this.apiUrl}/patient-to-patient-orders/${patientId}`);
  }

  public getVisitDetails(orderid: number): Observable<PatientOrder> {

    return this.http.get<PatientOrder>(`${this.apiUrl}/visit-details/${orderid}`);
  }

  public getVisitByPhlebotomist(id: number): Observable<PatientOrder[]> {

    return this.http.get<PatientOrder[]>(`${this.apiUrl}/visits-by-phlebotomist/${id}`);
  }

  public getCompletionTimeInEachState(id: number, date: Date): Observable<CompletionTimeInEachState[]> {

    const filterDate = this.replaceAll(`${date.toLocaleDateString('en-US')}`, '/', '-');

    return this.http.get<CompletionTimeInEachState[]>(`${this.apiUrl}/completion-time-in-each-state/${id}/${filterDate}`);
  }

  public getNewVisitsByPhlebotomist(phlebotomistId: number): Observable<PatientOrder[]> {

    return this.http.get<PatientOrder[]>(`${this.apiUrl}/new-visits-by-phlebotomist/${phlebotomistId}`);
  }

  public replaceAll(string, search, replace): string {

    return string.split(search).join(replace);
  }

  public postPatientOrder(patientOrder: PatientOrder): Observable<ExistVisitRangeMinutesDto> {

    patientOrder.setDateService = `${patientOrder.dateService.toLocaleDateString('en-US')} ${patientOrder.dateService.toLocaleTimeString('en-US')}`;

    var submitDate = new Date(moment().format());

    patientOrder.submitDate = `${submitDate.toLocaleDateString('en-US')} ${submitDate.toLocaleTimeString('en-US')}`;

    return this.http.post<ExistVisitRangeMinutesDto>(`${this.apiUrl}`, patientOrder, { headers: this.PutPostHeaders });
  }

  public postSharedVisit(patientOrder: PatientOrder): Observable<any> {

    patientOrder.setDateService = `${patientOrder.dateService.toLocaleDateString('en-US')} ${patientOrder.dateService.toLocaleTimeString('en-US')}`;

    var submitDate = new Date(moment().format());

    patientOrder.submitDate = `${submitDate.toLocaleDateString('en-US')} ${submitDate.toLocaleTimeString('en-US')}`;

    return this.http.post<any>(`${this.apiUrl}/shared-visit`, patientOrder, { headers: this.PutPostHeaders });
  }

  public putPatientOrder(patientOrder: PatientOrder): Observable<any> {
    
    patientOrder.setDateService = `${patientOrder.dateService.toLocaleDateString('en-US')} ${patientOrder.dateService.toLocaleTimeString('en-US')}`;

    if (patientOrder.orderStatus === 'In Transit') {

      var inProgressDate = new Date(moment().format());
      patientOrder.inTransitDate = `${inProgressDate.toLocaleDateString('en-US')} ${inProgressDate.toLocaleTimeString('en-US')}`;

    } else if (patientOrder.orderStatus === 'Take Samples') {

      var takeSamplesDate = new Date(moment().format());
      patientOrder.takeSamplesDate = `${takeSamplesDate.toLocaleDateString('en-US')} ${takeSamplesDate.toLocaleTimeString('en-US')}`;

    } else {

      if (patientOrder.orderStatus === 'Completed') {       
        var completedDate = new Date(moment().format());
        patientOrder.completedDate = `${completedDate.toLocaleDateString('en-US')} ${completedDate.toLocaleTimeString('en-US')}`;
      }
    } 

    return this.http.put<any>(`${this.apiUrl}`, patientOrder, { headers: this.PutPostHeaders });
  }

  public putChangeVisitTime(patientOrderId: number, dateService: Date): Observable<any> {

    var strDateService = `${dateService.toLocaleDateString('en-US')}`;
    strDateService = strDateService.replace('/', '-');
    strDateService = strDateService.replace('/', '-');
    console.log(strDateService);
    var strTimeService = `${dateService.toLocaleTimeString('en-US')}`;
    var strDateTimeService = `${strDateService} ${strTimeService}`;

    return this.http.put<any>(`${this.apiUrl}/change-visit-time/${patientOrderId}/${strDateTimeService}`, { headers: this.PutPostHeaders });
  }

  public putChangeVisitsPhlebotomist(phlebotomistId: number, shortPatientOrder: ShortPatientOrder[]): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}/change-visits-phlebotomist/${phlebotomistId}`, shortPatientOrder, { headers: this.PutPostHeaders });
  }

  public deletePatientOrder(patientOrderId: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/${patientOrderId}`);
  }

  public deletePatientDocument(documentId: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/delete-document/${documentId}`);
  }

  public getVisitsWithFilterReport(fromDate: Date, toDate: Date, arrayTextFilter: string[], filterType: string, userId: string): Observable<Blob> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      arrayTextFilter: arrayTextFilter,
      filterType: filterType,
      userId: userId
    } as FilterParams;
    //const body = JSON.stringify(fParams);
    //console.log(fParams);
    return this.http.post(`${this.apiUrl}/visits-with-filter-Report`, fParams, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

  public getVisitLogReport(fromDate: Date, toDate: Date, visitId: Number): Observable<Blob> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      visitId: visitId
    } as VisitLogParams;

    return this.http.post(`api/visitlog/visit-log-Report`, fParams, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

  public uploadDocument(formData: FormData): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/uploadDocument`, formData);
  }

  public getVisitReport(fromDate: Date, toDate: Date, arrayTextFilter: string[], filterType: string, userId: string): Observable<Blob> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      arrayTextFilter: arrayTextFilter,
      filterType: filterType,
      userId: userId
    } as FilterParams;
    //const body = JSON.stringify(fParams);
    //console.log(fParams);
    return this.http.post(`${this.apiUrl}/visit-Report`, fParams, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

  public getVisitsStatusLogReport(fromDate: Date, toDate: Date, arrayTextFilter: string[], filterType: string, userId: string): Observable<Blob> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      arrayTextFilter: arrayTextFilter,
      filterType: filterType,
      userId: userId
    } as FilterParams;
    //const body = JSON.stringify(fParams);
    //console.log(fParams);
    return this.http.post(`${this.apiUrl}/visits-status-log-report`, fParams, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

}
