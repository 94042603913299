import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

//Core
import { User } from '../account/user';

@Injectable({
  providedIn: 'root'
})
export class UserLoggedInService {

  private userLoggedIn = new Subject<boolean>();

  // User Logged
  public userLogged = new BehaviorSubject<User | undefined>(undefined);
  public userLoggedScast = this.userLogged.asObservable();

  constructor() {
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }


  // User Logged
  public updateUserLogged(newUserLogged: User | undefined): void {

    this.userLogged.next(newUserLogged);
  }

}
