import { RouterModule, Routes } from '@angular/router';

//Component
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './account/login/login.component';
import { PatientComponent } from './patient/patient.component';
import { AutoLogoutComponent } from './account/auto-logout/auto-logout.component';
import { RegisterComponent } from './account/register/register.component';
import { PatientDetailsComponent } from './patient/patient-details.component';
import { PatientOrderComponent } from './patient-order/patient-order.component';
import { PatientOrderDetailsComponent } from './patient-order/patient-order-details.component';
import { PhlebotomistComponent } from './phlebotomist/phlebotomist.component';
import { VisitsComponent } from './patient-order/visit/visits.component';
import { VisitDetailsComponent } from './patient-order/visit/visit-details.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { UsersComponent } from './account/users/users.component';
import { Full_CalendarComponent } from './full-calendar/full-calendar.component';
import { FullMapComponent } from './map/full-map.component';
import { RouteTrackingComponent } from './phlebotomist/route-tracking/route-tracking.component';
import { NeighborhoodComponent } from './neighborhood/neighborhood.component';
import { SharedVisitComponent } from './patient-order/visit/shared-visit.component';
import { DownloadAppComponent } from './download-app-mobile/download-app.component';
import { SurveyComponent } from './survey/survey.component';
import { QuickAddComponent } from './quick-add/quick-add.component';
import { OrderProcedureService } from './order-procedure/order-procedure.service';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { AbsentComponent } from './phlebotomist/absent/absent.component';
import { PatientOrderAppMobileComponent } from './patient-order-app-mobile/patient-order-app-mobile.component';
import { PatientOrderAppMobileDetailsComponent } from './patient-order-app-mobile/patient-order-app-mobile-details.component';
import { ImageViewerComponent } from './patient-order-app-mobile/image-viewer/image-viewer.component';
import { SampleDeliveryComponent } from './sample-delivery/sample-delivery.component';
import { SampleDeliveryAddNewEditComponent } from './sample-delivery/sample-delivery-addnew-edit.component';
import { TemperatureLogComponent } from './temperature-log/temperature-log.component';

export const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'patient', component: PatientComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'patient-details/:id', component: PatientDetailsComponent },
  { path: 'patient-orders/:patientId', component: PatientOrderComponent },
  { path: 'patient-order-details/:patientId/:orderId', component: PatientOrderDetailsComponent },
  { path: 'phlebotomist', component: PhlebotomistComponent },
  { path: 'visits', component: VisitsComponent },
  { path: 'visit-details/:orderId', component: VisitDetailsComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'users', component: UsersComponent },
  { path: 'full-calendar', component: Full_CalendarComponent },
  { path: 'full-map', component: FullMapComponent },
  { path: 'route-tracking/:phlebotomistId', component: RouteTrackingComponent },
  { path: 'shared-visit/:patientId/:orderId', component: SharedVisitComponent },
  { path: 'survey/:phlebotomistId/:orderId', component: SurveyComponent },
  { path: 'download-app', component: DownloadAppComponent },
  { path: 'quick-add', component: QuickAddComponent },
  { path: 'documents/:patientId/:orderId/:id', component: DocumentViewerComponent },
  { path: 'absent', component: AbsentComponent },
  { path: 'patient-order-app-mobile/:orderStatus', component: PatientOrderAppMobileComponent },
  { path: 'order-app-mobile/:id', component: PatientOrderAppMobileDetailsComponent },
  { path: 'orderImages/:patientId/:orderId/:laboratoryLicense/:securityId', component: ImageViewerComponent },
  { path: 'sample-delivery', component: SampleDeliveryComponent },
  { path: 'sample-delivery-addnew-edit/:patientId/:Id', component: SampleDeliveryAddNewEditComponent },
  { path: 'temperature-log', component: TemperatureLogComponent },
  {path: '**', redirectTo: ''}
];

export const AppRoutes = RouterModule.forRoot(routes);
