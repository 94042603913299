//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { MapsAPILoader } from '@agm/core';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PhlebotomistService } from '../phlebotomist.service';
import { AccountService } from '../../account/account.service';
import { PatientOrderService } from '../../patient-order/patient-order.service';

//Core
import { Phlebotomist } from '../phlebotomist';
import { RouteTracking } from './route-tracking';
import { CompletionTimeInEachState } from './completion-time-in-each-state';
import { User } from '../../account/user'
import { PhlebotomistMapLocation } from '../../map/location-model';

//Primeng
import { MessageService } from 'primeng/api';
//import { count } from 'rxjs/operators';

@Component({
  selector: 'app-route-tracking',
  templateUrl: './route-tracking.component.html',
  styleUrls: ['./route-tracking.component.scss'],
  styles: []
})
export class RouteTrackingComponent extends OnDestroyMixin implements OnInit {

  public phlebotomistId = 0;
  public phlebotomist: Phlebotomist;
  public routeTracking: RouteTracking[] = [];
  public completionTimeInEachState: CompletionTimeInEachState[] = [];
  public filterDate: Date;
  public loading = false;
  public cols: any[];
  public markers: PhlebotomistMapLocation[] = [];
  public lat = 18.042216923829237;
  public lng = -67.05580472946167;
  public zoom = 11;
  public displayBasic = false;

  constructor(private readonly phlebotomistService: PhlebotomistService,
    private readonly accountService: AccountService,
    private readonly patientOrderService: PatientOrderService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    this.phlebotomist = {} as Phlebotomist;
    this.filterDate = new Date();

    if (this.isUserLoggedIn()) {

      this.phlebotomistId = this.route.snapshot.params['phlebotomistId'];

      this.getPhlebotomist();

    } else {

      this.router.navigate(['/login']);
    }

  }

  public getPhlebotomist(): void {

    if (this.phlebotomistId !== undefined) {

      this.phlebotomistService.getPhlebotomist(this.phlebotomistId)
        .subscribe((res: any) => this.phlebotomist = res as Phlebotomist,
          (error: any) => {

            this.messageService.add({ key: 'routetrackingkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {
            this.getCompletionTimeInEachState();
          });
    }
  }  

  public getCompletionTimeInEachState(): void {

    this.loading = true;
    this.completionTimeInEachState = [];

    this.patientOrderService.getCompletionTimeInEachState(this.phlebotomistId, this.filterDate)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.completionTimeInEachState = data as CompletionTimeInEachState[],
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'routetrackingkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          this.loadColumns();
          this.loading = false;
          var counter = 0;

          this.completionTimeInEachState.forEach((row) => {

            counter = counter + 1;

            if (counter === 1) {              
              row.markerIcon = "color1-marker.png";
            } else if (counter === 2) {
              row.markerIcon = "color4-marker.png";
            } else if (counter === 3) {              
              row.markerIcon = "color3-marker.png";
            } else if (counter === 4) {             
              row.markerIcon = "color6-marker.png";
            } else if (counter === 5) {              
              row.markerIcon = "color8-marker.png";
            } else if (counter === 6) {              
              row.markerIcon = "color9-marker.png";
            } else if (counter === 7) {             
              row.markerIcon = "color10-marker.png";
            } else if (counter === 8) {             
              row.markerIcon = "color11-marker.png";
            } else if (counter === 9) {              
              row.markerIcon = "color2-marker.png";
            } else if (counter === 10) {             
              row.markerIcon = "color7-marker.png";
            } else {             
              row.markerIcon = "yellow-marker.png";              
            }

          });

          const unknownRow: CompletionTimeInEachState = {
            orderId: 0, patientId: 0, patientName: 'unknown', totalTakeSamplesTime: '', totalTransitTime: '', flagTakeSamplesTime: false, flagTransitTime: false, estimatedTimeText: '', markerIcon: 'yellow-marker.png', sharedVisit: false
          }
          this.completionTimeInEachState.push(unknownRow);

          var orderId = this.completionTimeInEachState[0].orderId;

          this.getRouteTracking(orderId, "color1-marker.png");

        });
  }

  public getRouteTracking(orderId: number, iconUrl: string): void {

    this.loading = true;
    this.routeTracking = [];

    this.phlebotomistService.getRouteTracking(this.phlebotomistId, this.filterDate, orderId)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.routeTracking = data as RouteTracking[];
        //console.log(this.routeTracking);
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'routetrackingkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          this.loading = false;          
          this.createMarkers(iconUrl);
        });
  }

  public createMarkers(iconUrl: string): void {

    if (this.routeTracking != null && this.routeTracking.length > 0) {

      this.markers = [];
      var counter = 0;
      var textColor = 'red';
      var laboratoryName = '';

      var lat = '18.042216923829237';
      var lng = '-67.05580472946167';

      if (this.routeTracking[0].laboratoryName !== undefined) {
        laboratoryName = this.routeTracking[0].laboratoryName;
      }

      const labLocation: PhlebotomistMapLocation = {
        name: laboratoryName,
        lat: parseFloat(this.routeTracking[0].laboratoryLatitude),
        lng: parseFloat(this.routeTracking[0].laboratoryLongitude),
        iconUrl: '/assets/lig-dot.png',
        labelOptions: {
          color: textColor,
          fontFamily: 'sans-serif',
          fontSize: '11px',
          fontWeight: '500',
          text: ' '
        }
      };
      this.markers.push(labLocation);

      this.routeTracking.forEach((r) => {

        if (lat != r.latitude && lng != r.longitude) {
          lat = r.latitude;
          lng = r.longitude;

          counter = counter + 1;

          if (iconUrl === 'color3-marker.png') {
            textColor = 'red';
          } else if (iconUrl === 'color2-marker.png') {
            textColor = 'red';
          } else if (iconUrl === 'color4-marker.png') {
            textColor = 'yellow';
          } else if (iconUrl === 'color11-marker.png') {
            textColor = 'red';
          } else if (iconUrl === 'color12-marker.png') {
            textColor = 'red';
          } else if (iconUrl === 'color5-marker.png') {
            textColor = 'yellow';
          } else if (iconUrl === 'color6-marker.png') {
            textColor = 'yellow';
          } else if (iconUrl === 'color7-marker.png') {
            textColor = 'yellow';
          } else if (iconUrl === 'color8-marker.png') {
            textColor = 'yellow';
          } else if (iconUrl === 'color9-marker.png') {
            textColor = 'yellow';
          } else if (iconUrl === 'color10-marker.png') {
            textColor = 'yellow';
          } else if (iconUrl === 'color1-marker.png') {
            textColor = 'yellow';
          } else {
            textColor = 'red';
          }

          const location: PhlebotomistMapLocation = {
            name: `${r.strintDateTime} OrderId: ${r.orderId}`,
            lat: parseFloat(r.latitude),
            lng: parseFloat(r.longitude),
            iconUrl: `/assets/${iconUrl}`,
            labelOptions: {
              color: textColor,
              fontFamily: 'sans-serif',
              fontSize: '16px',
              fontWeight: '500',
              text: counter.toString()
            }
          };
          this.markers.push(location);
        }

      });

      const patientLocation: PhlebotomistMapLocation = {
        name: this.routeTracking[0].patientName,
        lat: parseFloat(this.routeTracking[0].patientLatitude),
        lng: parseFloat(this.routeTracking[0].patientLongitude),
        iconUrl: '/assets/green-dot.png',
        labelOptions: {
          color: textColor,
          fontFamily: 'sans-serif',
          fontSize: '11px',
          fontWeight: '500',
          text: ' '
        }
      };
      this.markers.push(patientLocation);
      //console.log(this.markers);
    } else {
      this.displayBasic = true;
    }

  }  

  public mapReading(): void {  
  }

  public onCalendarSelect() {

    this.getRouteTracking(this.phlebotomist.id, "color1-marker.png");
    this.getCompletionTimeInEachState();
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'orderId', header: 'OrderId' },
      { field: 'patientName', header: 'Patient Name' },
      { field: 'estimatedTimeText', header: 'Estimated Transit Time' },
      { field: 'totalTransitTime', header: 'Total Transit Time' },
      { field: 'totalTakeSamplesTime', header: 'Total Take Samples Time' }
    ];
  }
   
  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

}
