import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from '../account/account.service';
import { PatientOrderAppMobileService } from './patient-order-app-mobile.service';

//Core
import { PatientOrderAppMobile } from './patient-order-app-mobile';
import { User } from '../account/user';


//Primeng
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-patient-order-app-mobile',
  templateUrl: './patient-order-app-mobile.component.html'
})
export class PatientOrderAppMobileComponent extends OnDestroyMixin implements OnInit {

  public title = 'Orders By Laboratories';
  public patientOrders: PatientOrderAppMobile[] = [];
  public userLogged: User = {} as User;
  public orderStatus = '';
  public cols: any[] = [];
  public loading = false;
  @ViewChild('dt') table!: Table

  constructor(private readonly patientOrderAppMobileService: PatientOrderAppMobileService,
    private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    this.orderStatus = this.route.snapshot.params['orderStatus'];

    if (this.isUserLoggedIn()) {

      this.userLogged = this.accountService.getLoggedInUser();

      this.getPatientOrderByOrderStatus();

    } else {

      this.router.navigate(['/login']);
    }
  }

  public getPatientOrderByOrderStatus(): void {

    this.loading = true;

    this.patientOrderAppMobileService.getPatientOrderByLicenseNumberOrderStatus(this.userLogged.labLicenseNumberList, this.orderStatus)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: data => {
          this.patientOrders = data as PatientOrderAppMobile[];
          console.log(this.patientOrders);
        },
        error: error => {
          this.loading = false;
          this.messageService.add({ key: 'patientorderappmobilekey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {
          this.loading = false;
          this.loadColumns();
        }
      });
  }  

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'Order #.' },
      { field: 'patientName', header: 'Patient Name' },
      { field: 'dateService', header: 'Date Service' },
      { field: 'orderStatus', header: 'Order Status' },
      { field: 'laboratoryName', header: 'Laboratory Name' }
    ];
  }

  public onDateSelect(value: any) {
    this.table.filter(this.formatDate(value), 'dateService', 'equals')
    console.log(this.formatDate(value));
  }

  public formatDate(date: any) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day + 'T00:00:00';
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
