import { Component, OnInit } from '@angular/core';

//fullcalendar
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

//Service
import { VisitFilterService } from '../patient-order/visit/visit-filter.service';

//Core
import { VisitFilterReturnModel } from '../patient-order/model/visit-filter-return-model';
import { Event } from '../patient-order/model/event';

//Primeng
import { MessageService } from 'primeng/api';

// Moment
import * as moment from 'moment';

@Component({
  selector: 'app-full-calendar',
  templateUrl: './full-calendar.component.html'
})
export class Full_CalendarComponent implements OnInit {

  public events: Event[] = [];
  public options: any;
  public defaultDate: Date;
  public visitFilterReturnModel: VisitFilterReturnModel;
  public filterText: string = '';

  constructor(private readonly visitFilterService: VisitFilterService) {    

    //this.events = [
    //  {
    //    "id": 1,
    //    "title": "All Day Event",
    //    "start": "2021-03-11T16:55:58",
    //    "backgroundColor": "green"
    //  },
    //  {
    //    "id": 2,
    //    "title": "Long Event",
    //    "start": "2021-02-07",
    //    "end": "2021-02-10"
    //  },
    //  {
    //    "id": 3,
    //    "title": "Repeating Event",
    //    "start": "2021-02-09T13:00:00"
    //  }
    //];
  }

  public ngOnInit(): void {

    this.visitFilterService.getVisitFilterReturnModel.subscribe(x => this.visitFilterReturnModel = x);
    this.visitFilterService.getFilterText.subscribe(x => this.filterText = x);

    this.createNewModelFullCalendar();

    this.defaultDate = new Date(moment().startOf('day').format('l'));

    this.options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      defaultDay: this.defaultDate,
      defaultView: 'dayGridWeek',
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,timeGridDay'
      },
      editable: false
    };

  }

  public createNewModelFullCalendar(): void {

    let counter = 0;

    if (this.visitFilterReturnModel != null) {

      this.visitFilterReturnModel.patientOrders.forEach((o) => {

        const event: Event = {
          id: counter,
          title: o.patientName,
          start: o.dateService.toString(),
          backgroundColor: "green"
        };

        this.events.push(event);
      });

    }

  }

}
