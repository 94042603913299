//Angular
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NeighborhoodService } from './neighborhood.service';
import { PostalCodeService } from '../postal-code/postal-code.service';

//Interface
import { OnlyGroupedCities } from './only-grouped-cities';
import { Neighborhood } from './neighborhood';
import { NeighborhoodGroupedCities } from './neighborhood-grouped-cities';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-neighborhood',
  templateUrl: './neighborhood.component.html'
})
export class NeighborhoodComponent extends OnDestroyMixin implements OnInit {
    
  public onlyGroupedCities: OnlyGroupedCities[] = [];
  public cities: SelectItem[] = [];
  public selectedCity: string;
  public neighborhood: Neighborhood;
  public neighborhoodText = '';  
  public neighborhoodGroupedCities: NeighborhoodGroupedCities[] = [];
  public neighborhoodGroupedCitie: NeighborhoodGroupedCities;
  public cols: any[];
  public deleteModalNeighborhood = false;
  public neighborhoodId = 0;
  public disabledNeighborhood = true;
  public progressSpinner = false;
  public isNewNeighborhood = false;
  @ViewChild('neighborhoodForm', { static: false }) public neighborhoodForm: NgForm;
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly neighborhoodService: NeighborhoodService,
    private readonly postalCodeService: PostalCodeService,
    private readonly messageService: MessageService) {
    super();
  }

  public ngOnInit(): void {
    
    this.neighborhood = {} as Neighborhood;
    this.neighborhoodGroupedCitie = { neighborhoods: [] } as NeighborhoodGroupedCities;
  }

  public openNeighborhoodWindow(actionType: boolean): void {

    if (actionType === true) {

      this.isNewNeighborhood = true;
      this.addNewNeighborhood();
    } else {

      this.isNewNeighborhood = false;
      this.editNeighborhood();
    }
   
  }

  public addNewNeighborhood() {

    this.neighborhoodForm.reset();
    this.neighborhoodText = '';
    this.neighborhood = {} as Neighborhood;
    this.isNewNeighborhood = true;
    this.cities = [];
    this.getCities();
  }

  public editNeighborhood(): void {

    this.neighborhoodForm.reset();
    this.neighborhoodText = '';
    this.neighborhood = {} as Neighborhood;
    this.isNewNeighborhood = false;
    this.loadColumns()
    this.cities = [];
    this.neighborhoodGroupedCitie = { neighborhoods: [] } as NeighborhoodGroupedCities;
    this.getNeighborhoodGroupedCities();
  }  

  public onSubmitNeighborhood(): void {

    if (this.isNewNeighborhood === true) {

      this.progressSpinner = true;
      this.neighborhood.neighborhoodName = this.neighborhoodText;

      this.neighborhoodService.postNeighborhood(this.neighborhood)
        .pipe(untilComponentDestroyed(this))
        .subscribe((data) => this.neighborhood = data as Neighborhood,
          (error: string) => {
            this.progressSpinner = false;
            this.messageService.add({ key: 'neighborhoodkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {
            this.progressSpinner = false;
            this.closeWindow.emit(false);
            //this.messageService.add({ key: 'patientdetailskey', severity: 'success', summary: 'Neighborhood Created', detail: `${this.neighborhood.neighborhoodName} was created successfully.` });
          });
    }
  }

  public getCities(): void {

    this.postalCodeService.getOnlyGroupedCities()
      .subscribe((res: any) => this.onlyGroupedCities = res as OnlyGroupedCities[],
        (error: any) => {

          this.messageService.add({ key: 'neighborhoodkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          //for (let i = 0; i < this.onlyGroupedCities.length; i++) {

          //  this.cities.push({ label: this.onlyGroupedCities[i].city, value: this.onlyGroupedCities[i].city + ' ' + i });
          //}

          this.onlyGroupedCities.forEach((item) => {

            const newRow: SelectItem = {
              label: item.city,
              value: item.city
            };
            this.cities.push(newRow);

          });

        });
  }

  public getNeighborhoodGroupedCities(): void {

    this.neighborhoodService.GetNeighborhoodGroupedCities()
      .subscribe((res: any) => this.neighborhoodGroupedCities = res as NeighborhoodGroupedCities[],
        (error: any) => {

          this.messageService.add({ key: 'neighborhoodkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewNeighborhood === false) {            

            this.neighborhoodGroupedCities.forEach((item) => {

              const newRow: SelectItem = {
                label: item.groupedCity,
                value: item.groupedCity
              };
              this.cities.push(newRow);

            });

            this.selectedCity = this.cities[0].value;

            if (this.neighborhoodGroupedCities !== undefined) {

              const filterNeighborhood = this.neighborhoodGroupedCities.filter(a => a.groupedCity === this.selectedCity);

              if (filterNeighborhood !== undefined) {
                this.neighborhoodGroupedCitie = filterNeighborhood[0];
              }

            }

          }

        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'city', header: 'City' },
      { field: 'neighborhoodName', header: 'Neighborhood Name' }
    ];
  }

  public showDeleteNeighborhood(id: number): void {

    this.neighborhoodId = id;
    this.deleteModalNeighborhood = true;
  }

  public deleteNeighborhood(): void {

    this.deleteModalNeighborhood = false;

    this.neighborhoodService.deleteNeighborhood(this.neighborhoodId)
      .subscribe((res: any) => {

        this.closeWindow.emit(false);
      },
        (error: any) => {

          this.messageService.add({ key: 'neighborhoodkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public onChangeCity(event: any): void {

    if (event !== undefined) {
      this.neighborhood.city = event;

      if (this.isNewNeighborhood === false && this.neighborhoodGroupedCities !== undefined) {

        const filterNeighborhood = this.neighborhoodGroupedCities.filter(a => a.groupedCity === this.neighborhood.city);

        if (filterNeighborhood !== undefined) {
          this.neighborhoodGroupedCitie = filterNeighborhood[0];
        }

      }
    }
  }

}
