//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from '../patient/patient.service';
import { PatientOrderService } from './patient-order.service';
import { AccountService } from '../account/account.service';
import { VisitFilterService } from './visit/visit-filter.service';

//Core
import { Patient } from '../patient/patient';
import { PatientOrder } from './patient-order';
import { User } from '../account/user';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-patient-order',
  templateUrl: './patient-order.component.html'
})
export class PatientOrderComponent extends OnDestroyMixin implements OnInit {

  public title = 'PATIENT ORDERS';
  public patienToPatientOrder: PatientOrder[] = [];
  public patient: Patient;
  public userLogged: User;
  public cols: any[];
  public loading = false;
  public patientOrderId = 0;
  public deleteModal = false;

  constructor(private readonly patientOrderService: PatientOrderService,
    private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly visitFilterService: VisitFilterService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {
      
      this.patient = {} as Patient;

      const id = this.route.snapshot.params['patientId'];

      this.userLogged = this.getlocalUser();      

      if (id !== undefined) {

        this.getPatient(id);
      }

    } else {

      this.router.navigate(['/login']);

    }

  }

  public getPatient(id: number): void {

    this.patientService.getPatient(id)
      .subscribe((res: any) => this.patient = res as Patient,
        (error: any) => {

          this.messageService.add({ key: 'patientorderkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.title = `Visits from ${this.patient.fullName}`;
          this.getPatientToPatientOrders();
        });
  }

  public getPatientToPatientOrders(): void {

    this.loading = true;

    this.patientOrderService.getPatientToPatientOrders(this.patient.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.patienToPatientOrder = data as PatientOrder[];
        this.loadColumns();
        this.loading = false;
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'patientorderkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'Visit #' },
      { field: 'laboratoryName', header: 'Laboratory' },
      { field: 'getDateService', header: 'Date Service' },
      { field: 'orderStatus', header: 'Visit Status' },
      { field: 'phlebotomistName', header: 'Phlebotomist' }
    ];
  }

  public addNewOrder(): void {

    this.visitFilterService.setArreyTextFilter([]);
    this.visitFilterService.setFilterType('');
    this.visitFilterService.setFromDate(null);
    this.visitFilterService.setToDate(null);

    this.router.navigate([`/patient-order-details/${this.patient.id}/0`])
  }

  public editOrder(orderId: number): void {

    this.visitFilterService.setArreyTextFilter([]);
    this.visitFilterService.setFilterType('');
    this.visitFilterService.setFromDate(null);
    this.visitFilterService.setToDate(null);

    this.router.navigate([`/patient-order-details/${this.patient.id}/${orderId}`])
  }

  public showVisitDetails(orderid: number): void {

    this.router.navigate([`/visit-details/${orderid}`]);
  } 

  public showDeleteConf(id: number): void {

    this.patientOrderId = id;
    this.deleteModal = true;
  }

  public deletePatientOrder(): void {

    this.deleteModal = false;

    this.patientOrderService.deletePatientOrder(this.patientOrderId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'patientorderkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'patientorderkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          this.getPatientToPatientOrders();
        });

  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  }

}
