//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientOrderService } from '../patient-order.service';
import { AccountService } from '../../account/account.service';
import { LaboratoryService } from '../../laboratory/laboratory.service';
import { PhlebotomistService } from '../../phlebotomist/phlebotomist.service';
import { VisitFilterService } from './visit-filter.service';
import { UserLoggedInService } from '../../account/user-logged-in.service';
import { PatientService } from '../../patient/patient.service';

//Core
import { Patient } from '../../patient/patient';
import { PatientOrder } from '../patient-order';
import { User } from '../../account/user';
import { Item } from '../model/item';
import { Laboratory } from '../../laboratory/laboratory';
import { Phlebotomist } from '../../phlebotomist/phlebotomist';
import { VisitFilterReturnModel } from '../model/visit-filter-return-model';
import { PatientDocument } from '../model/patientDocument'

//Primeng
import { MessageService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

// Moment
import * as moment from 'moment';
/*import { count } from 'rxjs/operators';*/

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html'
})
export class VisitsComponent extends OnDestroyMixin implements OnInit {

  public title = 'VISITS';
  public patients: Patient[] = [];
  public visitFilterReturnModel: VisitFilterReturnModel[] = [];
  public userLogged: User;
  public fromDate: Date;
  public toDate: Date;
  public cols: any[];
  public loading = false;
  public loading2 = false;
  public visitsParamsFilterList: Item[] = [];
  public selectParamFilterList: Item[] = [];
  public patientOrderId = 0;
  public deleteModal = false;
  public arrayTextFilter: string[] = [];
  public filterType: string = 'status';
  public laboratories: Laboratory[] = [];
  public phlebotomists: Phlebotomist[] = [];
  public showTableItemStatus = false;
  public showTableItemLaboratory = false;
  public showTableItemPhlebotomist = false;
  public tableDataKey = 'orderStatus';
  public filterDateType: string = '';
  public filterText: string = '';
  public dialogHeader: string = '';
  public displayPatientDialog = false;
  public sharedVisit = false;
  public btnDelete = false;
  public waitingScreen = false;
  public itemsButtonReports: MenuItem[];
  // Documents
  public visitDetails: PatientOrder = {} as PatientOrder;
  public patientDocument: PatientDocument = {} as PatientDocument;
  public displayModalDocument = false;
  public headerDiagDocument = 'header';
  public imageId = 0;
  public deleteModalDocument = false;
  public progressSpinner = false;
  public selectedDocument = 'Medical Order';
  public showDocument = false;
  public internalHtml: any;
  public selectedSharedPatients: Patient[] = [];

  constructor(private readonly patientOrderService: PatientOrderService,
    private readonly accountService: AccountService,
    private readonly laboratoryService: LaboratoryService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly visitFilterService: VisitFilterService,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly patientService: PatientService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer ) {
    super();
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();
      this.loadItemsButtonReports();
      this.loadVisitTextFilter();        

    } else {

      this.router.navigate(['/login']);
    }

  }

  // ** Visit Filter Service ***
  public loadVisitTextFilter(): void {

    this.visitFilterService.getArrayTextFilter.subscribe(x => this.selectParamFilterList = x);
    this.visitFilterService.getFilterType.subscribe(x => this.filterType = x);
    
    if (this.selectParamFilterList.length > 0 && this.filterType.length > 0) {

      this.visitFilterService.getFromDate.subscribe(x => this.fromDate = x);
      this.visitFilterService.getToDate.subscribe(x => this.toDate = x);
      this.visitFilterService.getFilterDateType.subscribe(x => this.filterDateType = x);

      if (this.filterType === 'status') {

        this.onChangeCkeFilterStatus(false);

      } else if (this.filterType === 'laboratory') {

        this.onChangeCkeFilterLaboratory(false);        

      } else if (this.filterType === 'phlebotomist') {

        this.onChangeCkeFilterPhlebotomist(false);
      }

      this.selectParamFilterList.forEach((p) => {

        this.arrayTextFilter.push(p.value);
      });

      this.getVisitsWithFilter();

    } else {      

      this.initFirstTime();   
    }

  }

  public initFirstTime(): void {

    this.filterDateType = 'single';
    this.fromDate = new Date(moment().startOf('day').format('l'));
    this.toDate = new Date(moment().endOf('day').format('l'));

    this.onChangeCkeFilterStatus(false);

    const selectParamFilter: Item = {
      label: 'New Visit',
      value: 'New Visit'
    };
    this.selectParamFilterList.push(selectParamFilter);

    const selectParamFilter1: Item = {
      label: 'In Transit',
      value: 'In Transit'
    };
    this.selectParamFilterList.push(selectParamFilter1);

    const selectParamFilter2: Item = {
      label: 'Take Samples',
      value: 'Take Samples'
    };
    this.selectParamFilterList.push(selectParamFilter2);

    this.filterType = 'status';    
    this.arrayTextFilter.push('New Visit');
    this.arrayTextFilter.push('In Transit');
    this.arrayTextFilter.push('Take Samples');
    this.getVisitsWithFilter();    
  }

  public guardarFiltros() {

    // *** guardar filtros ***
    this.visitFilterService.setFromDate(this.fromDate);
    this.visitFilterService.setToDate(this.toDate);
    this.visitFilterService.setArreyTextFilter(this.selectParamFilterList);
    this.visitFilterService.setFilterType(this.filterType);
    this.visitFilterService.setFilterDateType(this.filterDateType);
  }

  public getVisitsWithFilter(): void {

    if (!moment(this.fromDate).isSameOrBefore(this.toDate)) {

      this.messageService.add({ key: 'orderskey', severity: 'warn', summary: 'Warnning', detail: 'From Date must be greater than To Date.' });

      return;
    }

    // *** guardar filtros ***
    this.guardarFiltros();
    this.loading = true;

    this.visitFilterReturnModel = [];
    this.showTableItemStatus = false;
    this.showTableItemLaboratory = false;
    this.showTableItemPhlebotomist = false;

    this.patientOrderService.getVisitsWithFilter(this.fromDate, this.toDate, this.arrayTextFilter, this.filterType, this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.visitFilterReturnModel = data as VisitFilterReturnModel[];
        this.displayFiltersText();
        //console.log(this.visitFilterReturnModel);
        this.loading = false;
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'orderskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.visitFilterReturnModel.forEach((x) => {

            if (x.orderStatus.length > 0) {
              this.showTableItemStatus = true;
              this.tableDataKey = 'orderStatus';
            } else if (x.laboratoryName.length > 0) {
              this.showTableItemLaboratory = true;
              this.tableDataKey = 'laboratoryName';
            }
            else if (x.phlebotomistName.length > 0) {
              this.showTableItemPhlebotomist = true;
              this.tableDataKey = 'phlebotomistName';
            }
            
          });
        });
  }

  public displayFiltersText() {

    this.filterText = '';

    this.selectParamFilterList.forEach((f) => {
      if (this.arrayTextFilter.length === 1) {
        this.filterText = ` + ${f.label}`;
      } else {
        this.filterText = `${this.filterText} + ${f.label} `;
      }
    });

    var strFromDate = moment(this.fromDate).format('MM/DD/YYYY');
    var strToDate = moment(this.toDate).format('MM/DD/YYYY');

    if (this.filterDateType == 'single') {
      this.filterText = `Filter: ${strFromDate} ${this.filterText}`;
    } else {
      this.filterText = `Filter: from: ${strFromDate} to: ${strToDate} ${this.filterText}`;
    }
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'patientName', header: 'Patient' },
      { field: 'laboratoryName', header: 'Laboratory' },
      { field: 'getDateService', header: 'Date Service' },
      { field: 'orderStatus', header: 'Visit Status' },
      { field: 'phlebotomistName', header: 'Phlebotomist' }
    ];    
  }

  public onChangeVisitsWithFilter(event: any): void {

    if (this.arrayTextFilter.length > 0) {

      this.arrayTextFilter = [];
    }

    event.forEach((p) => {
      
      this.arrayTextFilter.push(p.value);
    });
    
  }

  public editOrder(order: PatientOrder, sharedVisit: boolean): void {

    if (sharedVisit === false) {
      this.router.navigate([`/patient-order-details/${order.patientId}/${order.id}`])
    } else {
      this.router.navigate([`/shared-visit/${order.patientId}/${order.id}`])
    }    
  }

  public showVisitDetails(orderid: number): void {

    this.router.navigate([`/visit-details/${orderid}`]);
  } 

  public showDeleteConf(id: number): void {

    this.patientOrderId = id;
    this.deleteModal = true;
  }

  public deletePatientOrder(): void {

    this.deleteModal = false;

    this.patientOrderService.deletePatientOrder(this.patientOrderId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'patientorderkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'patientorderkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          this.getVisitsWithFilter();
        });
  }

  public onChangeCkeFilterStatus(isUser: boolean): void {

    this.visitsParamsFilterList = [
      { label: 'New Visit', value: 'New Visit' },
      { label: 'In Transit', value: 'In Transit' },
      { label: 'Take Samples', value: 'Take Samples' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Cancelled', value: 'Cancelled' }
    ];

    if (Boolean(isUser) === true) {
      this.selectParamFilterList = [];
      this.arrayTextFilter = [];
    }
  }

  public onChangeCkeFilterLaboratory(isUser: boolean): void {
    
    this.laboratoryService.getUserDefaultLaboratories(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.laboratories = data as Laboratory[],
        (error: any) => {

          console.log(error);
        },
        () => {
          this.visitsParamsFilterList = [];

          this.laboratories.forEach((p) => {

            const labOption: Item = {
              label: p.name,
              value: p.licenseNumber
            };

            this.visitsParamsFilterList.push(labOption);
          });

          if (Boolean(isUser) === true) {
            this.selectParamFilterList = [];
            this.arrayTextFilter = [];
          }

        });
  }

  public onChangeCkeFilterPhlebotomist(isUser: boolean): void {

    this.phlebotomistService.getUserDefaultPhlebotomists(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.phlebotomists = data as Phlebotomist[],
        (error: any) => {

          console.log(error);
        },
        () => {
          this.visitsParamsFilterList = [];

          this.phlebotomists.forEach((p) => {

            const phlebOption: Item = {
              label: p.name,
              value: p.id.toString()
            };

            this.visitsParamsFilterList.push(phlebOption);
          });

          if (Boolean(isUser) === true) {
            this.selectParamFilterList = [];
            this.arrayTextFilter = [];
          }
          
        });
  }

  public onCalendarSelect() {

    if(this.filterDateType == 'single') {      
      if (this.fromDate !== null) {
        this.toDate = new Date(this.fromDate);

        this.getVisitsWithFilter();
      }
    }
  }

  public showFullCalendar(orderRow: VisitFilterReturnModel): void {

    this.visitFilterService.setVisitFilterReturnModel(orderRow);
    this.visitFilterService.setFilterText(this.filterText);
    this.router.navigate(['/full-calendar'])
    //console.log(orderRow);
  }

  public showPatientDialog(sharedVisit: boolean): void {
    this.sharedVisit = sharedVisit;

    if (sharedVisit === false) {
      this.dialogHeader = "CREATE NEW VISIT";
    } else {
      this.dialogHeader = "SELECT LOCATION MAIN PATIENT";
    }    
    
    this.displayPatientDialog = true;
    this.getPatients();
  }

  public getPatients(): void {

    this.loading = true;

    this.patientService.getPatients()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.patients = data as Patient[];
        this.loadPatientColumns();
        this.loading = false;
      },
        (error: any) => {
          this.loading = false;
          this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public loadPatientColumns(): void {

    this.cols = [
      { field: 'firstName', header: 'FirstName' },
      { field: 'middleName', header: 'MiddleName' },
      { field: 'lastName', header: 'LastName' },
      { field: 'secondLastName', header: 'SecondLastName' },
      { field: 'physicalCity', header: 'City' },      
      { field: 'birthDate', header: 'BirthDate' },
      { field: 'phoneNumber', header: 'Phone' }
    ];
  }

  public addNewOrder(id: number): void {

    this.displayPatientDialog = false;

    if (this.sharedVisit === false) {
      this.router.navigate([`/patient-order-details/${id}/0`])
    } else {
      this.router.navigate([`/shared-visit/${id}/0`])
    }
    
  }

  public showFullMap(orderRow: VisitFilterReturnModel): void {
    // *** guardar filtros ***
    this.visitFilterService.setFilterDateType(this.filterDateType);
    this.visitFilterService.setFromDate(this.fromDate);
    this.visitFilterService.setToDate(this.toDate);
    this.visitFilterService.setArreyTextFilter(this.selectParamFilterList);
    this.visitFilterService.setFilterType(this.filterType);
    this.visitFilterService.setVisitFilterReturnModel(orderRow);
    this.visitFilterService.setFilterText(this.filterText);
    this.router.navigate(['/full-map'])
    //console.log(orderRow);
  }

  public mergeObjectsFullMap(): void {

    var orderRow: VisitFilterReturnModel = { patientOrders: [] } as VisitFilterReturnModel;

    this.visitFilterReturnModel.forEach((visitFilter: VisitFilterReturnModel) => {      

      visitFilter.patientOrders.forEach((patientOrder: PatientOrder) => {        

        orderRow.patientOrders.push(patientOrder);
      });      

    });

    // *** guardar filtros ***
    this.visitFilterService.setFilterDateType(this.filterDateType);
    this.visitFilterService.setFromDate(this.fromDate);
    this.visitFilterService.setToDate(this.toDate);
    this.visitFilterService.setArreyTextFilter(this.selectParamFilterList);
    this.visitFilterService.setFilterType(this.filterType);
    this.visitFilterService.setVisitFilterReturnModel(orderRow);
    this.visitFilterService.setFilterText(this.filterText);
    this.router.navigate(['/full-map'])
    //console.log(orderRow);
  }

  public openPdfReport() {
    this.waitingScreen = true;
    this.patientOrderService.getVisitsWithFilterReport(this.fromDate, this.toDate, this.arrayTextFilter, this.filterType, this.userLogged.id).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
      this.waitingScreen = false;
      //saveAs(blob, 'TestReport.' + this.selectedFormat.toLowerCase());
    });
  }

  public openVisitLogReport() {
    this.waitingScreen = true;
    this.patientOrderService.getVisitLogReport(this.fromDate, this.toDate, 0).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
      this.waitingScreen = false;
      //saveAs(blob, 'TestReport.' + this.selectedFormat.toLowerCase());
    });
  }

  public openVisitReport() {
    this.waitingScreen = true;
    this.patientOrderService.getVisitReport(this.fromDate, this.toDate, this.arrayTextFilter, this.filterType, this.userLogged.id).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
      this.waitingScreen = false;
      //saveAs(blob, 'TestReport.' + this.selectedFormat.toLowerCase());
    });
  }

  public openVisitsStatusLogReport() {
    this.waitingScreen = true;
    this.patientOrderService.getVisitsStatusLogReport(this.fromDate, this.toDate, this.arrayTextFilter, this.filterType, this.userLogged.id).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
      this.waitingScreen = false;
      //saveAs(blob, 'TestReport.' + this.selectedFormat.toLowerCase());
    });
  }

  public onClickAttach(orderId: number): void {

    this.displayModalDocument = true;

    this.patientOrderService.getVisitDetails(orderId)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.visitDetails = data as PatientOrder;
        this.headerDiagDocument = this.visitDetails.patientName;        
      },
        (error: any) => {
          this.messageService.add({ key: 'orderskey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public previewDocument(document: PatientDocument): void {

    this.patientDocument = document;
    this.showDocument = true;
  }

  public downloadDocument(document: PatientDocument): void {

    const nav = (window.navigator as any);

    if (nav && nav.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(image.pathFile);
    } else {
      const fileURL = document.uriDocumentPreview;
      window.open(fileURL);
    }
  }

  public showDeleteConfDocument(id: number): void {

    this.imageId = id;
    this.deleteModalDocument = true;
  }

  public deleteDocument(): void {

    this.patientOrderService.deletePatientDocument(this.imageId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'orderskey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.onClickAttach(this.visitDetails.id);
       // this.visitDetails = {} as PatientOrder;
        this.deleteModalDocument = false;
        //this.displayModalDocument = false;
        //this.loadVisitTextFilter();
      },
        (error: any) => {
          this.deleteModalDocument = false;
          this.messageService.add({ key: 'orderskey', severity: 'error', summary: 'Error', detail: error });
        });
  }  

  public fileChangeEvent(fileInput: any): void {
    this.onUploadImage(fileInput.target.files[0]);
  }

  public onUploadImage(file: any): void {

    if (this.isUserLoggedIn()) {

      this.progressSpinner = true;

      const formData = new FormData();

      formData.append(file.name, file);
      formData.append('patientId', this.visitDetails.patientId.toString());
      formData.append('orderId', this.visitDetails.id.toString());
      formData.append('documentType', this.selectedDocument);

      this.patientOrderService.uploadDocument(formData)
        .subscribe((res: any) => {

          this.messageService.add({ key: 'orderskey', severity: 'success', summary: 'Image', detail: 'File upload successful' });

          this.progressSpinner = false;
          this.onClickAttach(this.visitDetails.id);
        },
          (error: any) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'orderskey', severity: 'error', summary: 'Error', detail: error });
          });
    }
  }

  public goToTomaDeMuestra(): void {

    // window.open('assets/toma_de_muestra.html', '_blank');
    window.open('assets/toma_de_muestra3.pdf', '_blank');
  }

  public onGoToSharedPatients(patientOrder: PatientOrder, event: any, overlaypanel: OverlayPanel) {

    if (patientOrder.sharedVisitPatientIds !== undefined && patientOrder.sharedVisitPatientIds !== null) {

      this.selectedSharedPatients = [];

      var lstPatientIds: string[] = patientOrder.sharedVisitPatientIds.split(',');

      if (lstPatientIds.length == 0 && patientOrder.sharedVisitPatientIds.length > 0) {        
        lstPatientIds.push(patientOrder.sharedVisitPatientIds);
      }

      if (lstPatientIds.length > 0) {
        lstPatientIds.push(patientOrder.patientId.toString());
      }

      this.loading2 = true;

      overlaypanel.toggle(event);

      this.patientService.getPatientsByIds(`${patientOrder.sharedVisitPatientIds}, ${patientOrder.patientId.toString()}`)
        .pipe(untilComponentDestroyed(this))
        .subscribe((data) => {

          this.patients = data as Patient[];          
        },
          (error: any) => {
            this.loading2 = false;
            this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.loading2 = false;            

            lstPatientIds.forEach((id) => {

              const filterPatient = this.patients.filter(p => p.id === +id);

              if (filterPatient !== undefined) {
                this.selectedSharedPatients.push(filterPatient[0]);
              }

            });            

          });
    }

    //console.log(patientOrder);
    //console.log(this.selectedSharedPatients);
  }

  public loadItemsButtonReports(): void {

    this.itemsButtonReports = [
      {
        label: 'TOMA DE MUESTRA', icon: 'pi pi-file', command: () => {
          this.goToTomaDeMuestra();
        }
      },
      {
        label: 'VISIT REPORT', icon: 'pi pi-file', command: () => {
          this.openVisitReport();
        }
      },
      {
        label: 'VISIT LOG REPORT', icon: 'pi pi-file', command: () => {
          this.openVisitLogReport();
        }
      },
      {
        label: 'VISITS STATUS LOG REPORT', icon: 'pi pi-file', command: () => {
          this.openVisitsStatusLogReport();
        }
      }
    ];
  }

  public goToPatientOrderAppMobile(): void {
    this.router.navigate(['/patient-order-app-mobile/all']);
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    this.userLoggedInService.updateUserLogged(userLogged);

    const userRoles = userLogged.roles.filter(row => row === 'Admin' || row === 'SuperAdmin');

    if (userRoles !== undefined) {
      this.btnDelete = true;
    }
    else {
      this.btnDelete = false;
    }

    return userLogged;
  }
}
