//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from '../account/account.service';
import { PatientOrderService } from '../patient-order/patient-order.service';

//Core
import { User } from '../account/user'
import { PatientDocument } from '../patient-order/model/patientDocument'

//Primeng
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html'
})
export class DocumentViewerComponent extends OnDestroyMixin implements OnInit {

  public patientDocuments: PatientDocument[] = [];
  public patientName = '';
  public userLogged: User;  

  constructor(private readonly patientOrderService: PatientOrderService,    
    private readonly accountService: AccountService,    
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();    
  }

  public ngOnInit(): void {

    const patientId = this.route.snapshot.params['patientId'];
    const orderId = +this.route.snapshot.params['orderId'];
    const id = +this.route.snapshot.params['id'];

    this.userLogged = this.getlocalUser();

    this.getPatientDocuments(patientId, orderId, id);
  }  

  public getPatientDocuments(patientId: number, orderId: number, id: number): void {

    this.patientOrderService.getPatientDocuments(patientId, orderId, id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.patientDocuments = data as PatientDocument[];
        this.patientName = this.patientDocuments[0].patientName;
        console.log(this.patientDocuments);
      },
        (error: any) => {

          this.messageService.add({ key: 'documentviewerkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

  public downloadDocument(document: PatientDocument): void {

    const nav = (window.navigator as any);

    if (nav && nav.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(image.pathFile);
    } else {
      const fileURL = document.uriDocumentPreview;
      window.open(fileURL);
    }
  }

}
