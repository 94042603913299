// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

// Objects
import { PostalCode } from './postal-code';
import { OnlyGroupedCities } from '../neighborhood/only-grouped-cities';

@Injectable()
export class PostalCodeService extends BaseService {

  private readonly apiUrl = 'api/postalcode';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getPostalCodes(): Observable<PostalCode[]> {

    return this.http.get<PostalCode[]>(this.apiUrl);
  }

  public getOnlyGroupedCities(): Observable<OnlyGroupedCities[]> {

    const url = `${this.apiUrl}/only-grouped-cities`;

    return this.http.get<OnlyGroupedCities[]>(url);
  }

  public searchPostalCodes(searchText: string): Observable<PostalCode[]> {

    const url = `${this.apiUrl}/search/postal-codes/${searchText}`;

    return this.http.get<PostalCode[]>(url);
  }

  public searchByZipCode(zipCode: string): Observable<PostalCode[]> {

    const url = `${this.apiUrl}/search-zipcode/postal-codes/${zipCode}`;

    return this.http.get<PostalCode[]>(url);
  }

}
