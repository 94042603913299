import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from '../account/account.service';
import { UserLoggedInService } from '../account/user-logged-in.service';
import { PatientOrderAppMobileService } from './patient-order-app-mobile.service';
import { LaboratoryService } from '../laboratory/laboratory.service';
import { PatientOrderGlobalObjectService } from './patient-order-global-objecte.service';

//Core
import { PatientOrderAppMobileDetails } from './patient-order-app-mobile-details';
import { OrderFileAppMobile } from './order-file-app-mobile';
import { User } from '../account/user';
import { Laboratory } from '../laboratory/laboratory';
import { PatientDocument } from './patient-document';

//Primeng
import { MessageService } from 'primeng/api';

interface Status {
  label: string;
  value: string;
}

@Component({
  selector: 'app-patient-order-app-mobile-details',
  templateUrl: './patient-order-app-mobile-details.component.html'
})
export class PatientOrderAppMobileDetailsComponent extends OnDestroyMixin implements OnInit {

  public title = '';
  public patientOrder: PatientOrderAppMobileDetails = {} as PatientOrderAppMobileDetails;
  public statusList: Status[] = [];
  public selectStatus: Status = {} as Status;
  public progressSpinner = false;
  public userLogged: User | undefined;
  public showDocument = false;
  public orderFile: OrderFileAppMobile = {} as OrderFileAppMobile;
  public laboratories: Laboratory[] = [];
  public selectLaboratory: Laboratory = {} as Laboratory;
  public patientDocuments: PatientDocument[] = [{} as PatientDocument];
  public displayMedicalOrderDialog = false;

  constructor(private readonly patientOrderAppMobileService: PatientOrderAppMobileService,
    private readonly accountService: AccountService,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly laboratoryService: LaboratoryService,
    private readonly patientOrderGlobalObjectService: PatientOrderGlobalObjectService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();

    this.statusList = [
      { label: 'Nueva', value: 'Nueva' },
      { label: 'Aceptada', value: 'Aceptada' },
      //{ label: 'En proceso', value: 'En proceso' },
      //{ label: 'Completada', value: 'Completada' },
      { label: 'Cancelada', value: 'Cancelada' }
    ];
  }

  public ngOnInit(): void {

    const id = +this.route.snapshot.params['id'];

    this.patientOrder.orderFiles = [];

    if (this.isUserLoggedIn()) {

      this.userLogged = this.accountService.getLoggedInUser();
      this.getPatientOrderById(id);

    } else {

      this.router.navigate(['/login']);
    }
  }

  public onSubmit(): void {

    this.progressSpinner = true;

    if (this.userLogged !== undefined) {
      this.patientOrder.limsUserName = this.userLogged.name;
    }

    this.patientOrderAppMobileService.putPatientOrder(this.patientOrder)
      .subscribe({
        next: data => {
          this.progressSpinner = false;
          this.messageService.add({ key: 'patientorderdetailskey', severity: 'success', summary: 'Service Edited', detail: `The record from ${this.patientOrder.patientName} was edited successfully.` });
        },
        error: error => {
          this.progressSpinner = false;
          this.messageService.add({ key: 'patientorderdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {
          this.goBack();
        }
      });
  }

  public getPatientOrderById(id: number): void {

    this.patientOrderAppMobileService.getPatientOrderById(id)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: data => {
          this.patientOrder = data as PatientOrderAppMobileDetails;
          console.log(this.patientOrder);
        },
        error: error => {
          if (this.patientOrder.orderFiles.length === 0) {
            //this.emptyPatientOrder = true;
          }
          this.messageService.add({ key: 'patientorderdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {
          this.title = `Order #. ${this.patientOrder.id}  |  ${this.patientOrder.laboratoryName}`;
          let dateService = this.patientOrder.dateService.toString();
          this.patientOrder.dateService = new Date(dateService);
          this.selectStatus = this.statusList.filter((x) => x.value === this.patientOrder.orderStatus)[0];
          this.getPatientDocuments(this.patientOrder.patientId);          
        }
      });
  }

  public getPatientDocuments(patientId: number) {

    this.patientOrderAppMobileService.getPatientDocuments(patientId)
      .subscribe({
        next: data => {

          this.patientDocuments = data as PatientDocument[];
        },
        error: error => {
          this.messageService.add({ key: 'patientorderdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {
          this.getLaboratories();
        }
      });
  }

  public getLaboratories() {

    this.laboratoryService.getLaboratories()
      .subscribe({
        next: data => {

          this.laboratories = data as Laboratory[];
        },
        error: error => {
          this.messageService.add({ key: 'patientorderdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {
          this.selectLaboratory = this.laboratories.filter((x) => x.licenseNumber === this.patientOrder.laboratoryLicenseNumber)[0];
        }
      });
  }  

  public onChangeLaboratory(event: any): void {

    this.patientOrder.laboratoryLicenseNumber = event.licenseNumber;
    this.patientOrder.laboratoryName = event.name;
  }

  public onChangeStatus(event: any): void {

    this.patientOrder.orderStatus = event.value;
  }

  public previewDocument(orderFile: OrderFileAppMobile): void {

    this.orderFile = orderFile;
    this.showDocument = true;
  }

  public downloadDocument(orderFile: OrderFileAppMobile): void {

    const nav = (window.navigator as any);

    if (nav && nav.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(image.pathFile);
    } else {
      const fileURL = orderFile.uriDocumentPreview;
      window.open(fileURL);
    }
  }

  public addPatientOrderAppMobileInfo() {

    this.patientOrderGlobalObjectService.SetPatientOrderAppMobileData(this.patientOrder);
    this.router.navigate(['/patient-details/0']);
  }

  public showMedicalOrder() {

    this.displayMedicalOrderDialog = true;
  }

  public goBack() {
    
    this.router.navigate(['/patient-order-app-mobile', 'all']);
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
