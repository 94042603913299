//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Service
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { VisitFilterService } from '../patient-order/visit/visit-filter.service';
import { PatientOrderService } from '../patient-order/patient-order.service';
import { AccountService } from '../account/account.service';

//Core
import { VisitFilterReturnModel } from '../patient-order/model/visit-filter-return-model';
import { User } from '../account/user'
import { MapLocation } from '../map/location-model';
import { MapDirection } from '../map/location-model';
import { Origin } from '../map/location-model';
import { Destination } from '../map/location-model';
import { MarkerOption, OriginMarkerOption, DestinationMarkerOption } from '../map/location-model';
import { Item } from '../patient-order/model/item';
import { PatientOrder } from '../patient-order/patient-order';
import { MapLocationAndDirectionArray } from '../map/location-model';


//Primeng
import { MessageService } from 'primeng/api';

// Moment
import * as moment from 'moment';

@Component({
  selector: 'app-full-map',
  templateUrl: './full-map.component.html'
})
export class FullMapComponent extends OnDestroyMixin implements OnInit {

  public visitFilterReturnModels: VisitFilterReturnModel[] = [];
  public visitFilterReturnModel: VisitFilterReturnModel;
  public selectParamFilterList: Item[] = [];
  public arrayTextFilter: string[] = [];
  public filterType: string = 'status';
  public filterText: string = '';
  public filterDateType: string = '';
  public fromDate: Date;
  public toDate: Date;
  public userLogged: User;
  public markers: MapLocation[] = [];
  public directions: MapDirection[] = [];
  public lat = 18.042216923829237;
  public lng = -67.05580472946167;
  public zoom = 11;
  public loadingCoordinates = false;
  public displayChangeTimeDialog = false;
  public patientOrderId = 0;
  public dateService: Date;

  constructor(private readonly visitFilterService: VisitFilterService,
    private readonly patientOrderService: PatientOrderService,
    private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();    
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {
      this.dateService = new Date();
      this.userLogged = this.getlocalUser();
      this.visitFilterService.getVisitFilterReturnModel.subscribe(x => this.visitFilterReturnModel = x);
      this.visitFilterReturnModel.patientOrders.sort((a, b) => (a.allStatusDate > b.allStatusDate) ? 1 : -1)
      this.visitFilterService.getArrayTextFilter.subscribe(x => this.selectParamFilterList = x);
      this.visitFilterService.getFilterType.subscribe(x => this.filterType = x);
      this.visitFilterService.getFilterDateType.subscribe(x => this.filterDateType = x);
      this.visitFilterService.getFromDate.subscribe(x => this.fromDate = x);
      this.visitFilterService.getToDate.subscribe(x => this.toDate = x);
      this.visitFilterService.getFilterText.subscribe(x => this.filterText = x);

      this.selectParamFilterList.forEach((p) => {

        this.arrayTextFilter.push(p.value);
      });

      this.createPatientMarkers();

    } else {

      this.router.navigate(['/login']);
    }
   
  }

  public getVisitsWithFilter(): void {

    this.loadingCoordinates = true;

    var arrayTextFilter: string[] = this.arrayTextFilter;

    if (this.visitFilterReturnModel.orderStatus.length > 0) {
      arrayTextFilter = [];
      arrayTextFilter = [this.visitFilterReturnModel.orderStatus];
    }

    this.patientOrderService.getVisitsWithFilter(this.fromDate, this.toDate, arrayTextFilter, this.filterType, this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.loadingCoordinates = false;
        this.visitFilterReturnModels = [];
        this.visitFilterReturnModels = data as VisitFilterReturnModel[];
        this.visitFilterReturnModel.patientOrders = [];
        
        if (this.visitFilterReturnModels.length > 0) {          

          if (this.filterType === 'status') {

            if (this.existStatusRefreshButton() === false) {

              this.visitFilterReturnModel = {} as VisitFilterReturnModel;
              this.visitFilterReturnModel = this.visitFilterReturnModels[0];

              this.filterText = '';

              var strFromDate = moment(this.fromDate).format('MM/DD/YYYY');
              var strToDate = moment(this.toDate).format('MM/DD/YYYY');

              if (this.filterDateType == 'single') {
                this.filterText = `Filter: ${strFromDate} ${this.filterText} + ${this.visitFilterReturnModel.orderStatus}`;
              } else {
                this.filterText = `Filter: from: ${strFromDate} to: ${strToDate} ${this.filterText} + ${this.visitFilterReturnModel.orderStatus}`;
              }

            } else {

              const filterOrderStatus = this.visitFilterReturnModels.filter(a => a.orderStatus === this.visitFilterReturnModel.orderStatus);

              if (filterOrderStatus !== undefined) {
                this.visitFilterReturnModel = {} as VisitFilterReturnModel;
                this.visitFilterReturnModel = filterOrderStatus[0];
              }
            }
            console.log(this.visitFilterReturnModel.patientOrders);
          } else if (this.filterType === 'laboratory') {

            const filterLaboratory = this.visitFilterReturnModels.filter(a => a.laboratoryName === this.visitFilterReturnModel.laboratoryName);

            if (filterLaboratory !== undefined) {
              this.visitFilterReturnModel = {} as VisitFilterReturnModel;
              this.visitFilterReturnModel = filterLaboratory[0];
            }

          } else if (this.filterType === 'phlebotomist') {

            const filterPhlebotomist = this.visitFilterReturnModels.filter(a => a.phlebotomistName === this.visitFilterReturnModel.phlebotomistName);

            if (filterPhlebotomist !== undefined) {
              this.visitFilterReturnModel = {} as VisitFilterReturnModel;
              this.visitFilterReturnModel = filterPhlebotomist[0];
            }
          }

          this.createPatientMarkers();

          this.messageService.add({ key: 'fullmapkey', severity: 'success', summary: 'Google Maps', detail: 'Map updated!' });
          
        } else {

          this.loadingCoordinates = false;
          this.directions = [];
          this.markers = [];
        }
      },
        (error: any) => {
          this.loadingCoordinates = false;
          this.messageService.add({ key: 'fullmapkey', severity: 'error', summary: 'Error', detail: error });
        });

  }

  public existStatusRefreshButton(): boolean {

    this.visitFilterReturnModels.forEach((x) => {

      x.patientOrders.forEach((order) => {

        if (order.orderStatus === this.visitFilterReturnModel.orderStatus) {
          return true;
        }

      });
    });

    return false;
  }

  public ngOnDestroy(): void {    
  }
  
  public createPatientMarkers(): void {

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker('../app.worker', { type: 'module' });
      worker.onmessage = ({ data }) => {
        var visitFilterReturnModel = data as MapLocationAndDirectionArray;
        this.markers = visitFilterReturnModel.MapLocations;
        this.directions = visitFilterReturnModel.MapDirections;
        this.visitFilterReturnModel = visitFilterReturnModel.visitFilterReturnModel;
      };
      worker.postMessage(this.visitFilterReturnModel);
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }

  }

  public renderOptions = {
    suppressMarkers: true,
  }

  public showChangeTimeDialog(patientOrderId: number, dateService: any): void {

    this.patientOrderId = patientOrderId;
    this.dateService = new Date(dateService);
    this.displayChangeTimeDialog = true;
  }

  public changeVisitTime(): void {

    this.patientOrderService.putChangeVisitTime(this.patientOrderId, this.dateService)
      .subscribe(() => {
        
        this.messageService.add({ key: 'fullmapkey', severity: 'success', summary: 'Patient Order Edited', detail: 'The record was edited successfully.' });
        this.displayChangeTimeDialog = false;
        this.getVisitsWithFilter();
      },
        (error: any) => {

          this.messageService.add({ key: 'fullmapkey', severity: 'error', summary: 'Error', detail: error });
        });

  }

  public onClickAttach(orderId: number): void {

    if (this.visitFilterReturnModel.patientOrders !== undefined) {

      const filterPatientOrders = this.visitFilterReturnModel.patientOrders.filter(a => a.id === orderId);

      if (filterPatientOrders !== undefined) {

        var patientOrder = filterPatientOrders[0];
        
        const existDocuments = patientOrder.documents.filter(a => a.orderId === orderId);

        if (existDocuments.length === 0) {
          this.router.navigate([`/patient-details/${patientOrder.patientId}`])
        } else {
          this.router.navigate([`/visit-details/${orderId}`])
        }
      }
    }    
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  }

}
