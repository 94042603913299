import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Core
import { QuickApp } from './quick-add';

@Injectable({
  providedIn: 'root'
})
export class QuickAddGlobalObjectService {

  private quickAddPatient = new BehaviorSubject<QuickApp>(null);
  public shareQuickAddPatientData = this.quickAddPatient.asObservable();

  private dateService = new BehaviorSubject<Date>(null);
  public shareDateService = this.dateService.asObservable();

  constructor() { }

  public SetQuickAddPatientData(quickAdd: QuickApp): void {

    this.quickAddPatient.next(quickAdd);
  }

  public SetDateService(dateServ: Date): void {

    this.dateService.next(dateServ);
  }
}
