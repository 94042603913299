// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//core
import { OrderProcedureDto } from './order-procedure-dto';

@Injectable()
export class OrderProcedureService extends BaseService {

  private readonly apiUrl = 'api/orderprocedure';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public postOrderProcedure(orderProcedures: OrderProcedureDto[]): Observable<any> {

    return this.http.post<any>(this.apiUrl, orderProcedures, { headers: this.PutPostHeaders });
  }

  public getOrderProcedures(patientId: number, orderId): Observable<OrderProcedureDto[]> {

    return this.http.get<OrderProcedureDto[]>(`${this.apiUrl}/order-procedures-by-patientId-and-OrderId/${patientId}/${orderId}`);
  }
}
