// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//core
import { CountNewOrder } from './count-new-order';
import { PatientOrderAppMobile } from './patient-order-app-mobile';
import { PatientOrderAppMobileDetails } from './patient-order-app-mobile-details';
import { OrderImageDto } from './image-viewer/order-image-dto';
import { PatientDocument } from './patient-document';


@Injectable()
export class PatientOrderAppMobileService extends BaseService {

  private readonly apiUrl = 'api/patientorderappmobile';
  private readonly apiUrlLig = 'api/patientdocumentlig';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getPatientOrderById(id: number): Observable<PatientOrderAppMobile> {

    return this.http.get<PatientOrderAppMobile>(`${this.apiUrl}/patient-order-by-id/${id}`);
  }

  public countNewOrder(labsLicenses: string): Observable<CountNewOrder[]> {

    return this.http.get<CountNewOrder[]>(`${this.apiUrl}/count-new-orders/${labsLicenses}`);
  }

  public getPatientOrderByLicenseNumberOrderStatus(labsLicenses: string, orderStatus: string): Observable<PatientOrderAppMobile[]> {

    return this.http.get<PatientOrderAppMobile[]>(`${this.apiUrl}/patient-order-by-labsLicenses-orderStatus/${labsLicenses}/${orderStatus}`);
  }

  public getOrderImages(patientId: number, orderId: number, laboratoryLicense: string): Observable<OrderImageDto[]> {

    return this.http.get<OrderImageDto[]>(`${this.apiUrl}/order-images-by-Ids/${patientId}/${orderId}/${laboratoryLicense}/${orderId}${laboratoryLicense}${patientId}`);
  }

  public getPatientDocuments(patientId: number): Observable<PatientDocument[]> {

    return this.http.get<PatientDocument[]>(`${this.apiUrlLig}/patientDocument-by-patientId/${patientId}`);
  }

  public putPatientOrder(patientOrder: PatientOrderAppMobileDetails): Observable<any> {

    patientOrder.setDateService = `${patientOrder.dateService.toLocaleDateString('en-US')}`;

    return this.http.put<any>(`${this.apiUrl}`, patientOrder, { headers: this.PutPostHeaders });
  }

}
