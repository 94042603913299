// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../../core/base.service';

// Objects
import { AbsentPhlebotomist } from './absent';
import { GetAbsentParameter } from './get-absent-parameter';

@Injectable()
export class AbsentService extends BaseService {

  private readonly apiUrl = 'api/absentphlebotomist';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getAbsentPhlebotomists(): Observable<AbsentPhlebotomist[]> {

    return this.http.get<AbsentPhlebotomist[]>(this.apiUrl);
  }

  public postAbsentByPhlebotomistIdAndServiceDate(phlebotomistId: number, serviceDate: Date): Observable<any> {

    const fParams = {
      phlebotomistId: phlebotomistId,
      serviceDate: `${serviceDate.toLocaleDateString('en-US')} ${serviceDate.toLocaleTimeString('en-US')}`
    } as GetAbsentParameter;

    return this.http.post<any>(`${this.apiUrl}/get-absent-by-phleId-serviceDate`, fParams, { headers: this.PutPostHeaders });
  }

  public postAbsentPhlebotomist(absentPhlebotomist: AbsentPhlebotomist): Observable<AbsentPhlebotomist> {

    absentPhlebotomist.startDateTime = `${absentPhlebotomist.startDateTime2.toLocaleDateString('en-US')} ${absentPhlebotomist.startDateTime2.toLocaleTimeString('en-US')}`;
    absentPhlebotomist.endDateTime = `${absentPhlebotomist.endDateTime2.toLocaleDateString('en-US')} ${absentPhlebotomist.endDateTime2.toLocaleTimeString('en-US')}`;

    return this.http.post<AbsentPhlebotomist>(`${this.apiUrl}`, absentPhlebotomist, { headers: this.PutPostHeaders });
  }

  public putAbsentPhlebotomist(absentPhlebotomist: AbsentPhlebotomist): Observable<any> {

    absentPhlebotomist.startDateTime = `${absentPhlebotomist.startDateTime2.toLocaleDateString('en-US')} ${absentPhlebotomist.startDateTime2.toLocaleTimeString('en-US')}`;
    absentPhlebotomist.endDateTime = `${absentPhlebotomist.endDateTime2.toLocaleDateString('en-US')} ${absentPhlebotomist.endDateTime2.toLocaleTimeString('en-US')}`;

    return this.http.put<any>(`${this.apiUrl}`, absentPhlebotomist, { headers: this.PutPostHeaders });
  } 

  public deleteAbsentPhlebotomist(id: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/${id}`);
  }

}
