// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../core/base.service';

// core
import { UserLogin } from './user-login';
import { ReturnToken } from './return-token';
import { User } from './user';
import { Register } from './register/register';
import { OnlyToken } from './auto-logout/only-token';
import { ReturnOnlyToken } from './auto-logout/return-only-token';
import { ChangePassword } from './change-password/change-password';
import { Laboratory } from '../laboratory/laboratory';
import { Phlebotomist } from '../phlebotomist/phlebotomist';
 
@Injectable()
export class AccountService extends BaseService {

  private readonly apiUrl = 'api/account';
  public userKey = 'user';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public login(userLogin: UserLogin): Observable<ReturnToken> {

    const body = JSON.stringify(userLogin);

    return this.http.post<ReturnToken>(`${this.apiUrl}/login`, body, { headers: this.PutPostHeaders });
  }

  public logout(): Observable<any> {

    return this.http.post(`${this.apiUrl}/logout`, undefined, { headers: this.PutPostHeaders, responseType: 'text' });
  }

  public refreshToken(onlyToken: OnlyToken): Observable<ReturnOnlyToken> {

    const body = JSON.stringify(onlyToken);

    return this.http.post<ReturnOnlyToken>(`${this.apiUrl}/refresh-token`, body, { headers: this.PutPostHeaders });
  }

  public changePassword(changePass: ChangePassword): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}/change-password`, changePass, { headers: this.PutPostHeaders });
  }

  public getUser(id: string): Observable<User> {

    return this.http.get<User>(`${this.apiUrl}/${id}`);
  }

  public getUsers(): Observable<User[]> {

    return this.http.get<User[]>(`${this.apiUrl}/users`);
  }  

  public isUserStillLogged(): Observable<void> {

    return this.http.get(`${this.apiUrl}/heartbeat`, { responseType: 'text' })
      .pipe(
        map(() => { }));
  }

  public getExpiredSessionTime(): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/expired-session-time`);
  }

  public register(newUser: Register): Observable<User> {

    return this.http.post<User>(`${this.apiUrl}/register`, newUser, { headers: this.PutPostHeaders });
  }

  public resetUserPassword(user: ChangePassword): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}/reset-user-password/`, user, { headers: this.PutPostHeaders });
  }

  public setDefaultLabs(userId: string, labs: Laboratory[]): Observable<void> {

    return this.http.put<void>(`${this.apiUrl}/update-default-labs/${userId}`, labs, { headers: this.PutPostHeaders });
  }

  public setDefaultPhlebotomists(userId: string, phlebotomists: Phlebotomist[]): Observable<User> {

    return this.http.put<User>(`${this.apiUrl}/update-default-phlebotomists/${userId}`, phlebotomists, { headers: this.PutPostHeaders });
  }

  public updateUserGroupName(userId: string, userGroupName: string): Observable<User> {

    return this.http.put<User>(`${this.apiUrl}/update-user-group-name/${userId}/${userGroupName}`, { headers: this.PutPostHeaders });
  }

  public accountLockout(email: string): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}/account-lockout/${email}`, { headers: this.PutPostHeaders });
  }

  public storeUserData(token, user): void {

    localStorage.setItem('token', token);
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  public isUserAuthenticated(): boolean {

    if (localStorage.getItem(this.userKey)) {
      // logged in so return true
      return true;
    }

    return false;
  }

  public getLoggedInUser(): User {

    let user: User = {} as User;

    if (this.isUserAuthenticated()) {

      const userString: any = localStorage.getItem(this.userKey);

      user = JSON.parse(userString) as User;

      return user;
    }

    return user;
  }

}
