//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

//Services
import { AccountService } from '../account.service';
import { LaboratoryService } from '../../laboratory/laboratory.service';

//Core
import { Register } from './register';
import { User } from '../user';
import { Laboratory } from '../../laboratory/laboratory';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html'
})
export class RegisterComponent extends OnDestroyMixin implements OnInit {

  public title = 'Register';
  public newUser: Register;;
  public FormIsValid = true;
  public loading = false;
  public isCreatedUser = false;
  @ViewChild('registerForm', { static: false }) public registerForm: NgForm;
  public laboratories: Laboratory[] = [];
  public selectedLaboratory: Laboratory;

  constructor(private readonly accountService: AccountService,
    private readonly laboratoryService: LaboratoryService,
    private readonly messageService: MessageService) {
    super();
  }

  public ngOnInit(): void {

    this.newUser = {} as Register;
    this.getLaboratories();
  }

  public register(): void {

    if (this.newUser.password !== this.newUser.confirmPassword) {

      this.FormIsValid = false;

      this.messageService.add({ key: 'registerkey', severity: 'error', summary: 'Error', detail: 'Password and confirm password must match.' });
    }
    else {

      this.FormIsValid = true;
      this.loading = true;
      this.newUser.userGroupName = this.selectedLaboratory.name;

      this.accountService.register(this.newUser)
        .subscribe((user: User) => {
          console.log(user);
          this.isCreatedUser = true;
          this.loading = false;
          this.messageService.add({ key: 'registerkey', severity: 'success', summary: 'Register', detail: `${user.name} was registered successfully.` });
        },
          (error: any) => {

            this.isCreatedUser = false;
            this.loading = false;
            this.messageService.add({ key: 'registerkey', severity: 'error', summary: 'Error', detail: error });
          });
    }
  }

  public getLaboratories(): void {

    this.laboratoryService.getLaboratories()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.laboratories = data as Laboratory[],
        (error: any) => {
          console.log(error);        
        });
  }

  public onCloseToast(): void {

    if (this.isCreatedUser === true) {
      this.registerForm.form.reset();
      this.newUser = {} as Register;
    }
  }

}
