//Angular
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MapsAPILoader } from '@agm/core';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from './patient.service';
import { AccountService } from '../account/account.service';
import { PostalCodeService } from '../postal-code/postal-code.service';
import { PatientOrderService } from '../patient-order/patient-order.service';
import { NeighborhoodService } from '../neighborhood/neighborhood.service';
import { QuickAddGlobalObjectService } from '../quick-add/quick-add-global-object.service';
import { PatientOrderGlobalObjectService } from '../patient-order-app-mobile/patient-order-global-objecte.service';

//Component
import { NeighborhoodComponent } from '../neighborhood/neighborhood.component';

//Core
import { Patient } from './patient';
import { PostalCode } from '../postal-code/postal-code';
import { User } from '../account/user'
import { PatientDocument } from '../patient-order/model/patientDocument'
import { Neighborhood } from '../neighborhood/neighborhood';
import { QuickApp } from '../quick-add/quick-add';
import { PatientOrderAppMobileDetails } from '../patient-order-app-mobile/patient-order-app-mobile-details';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';
/*import { resolve } from 'url';*/

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html'
})
export class PatientDetailsComponent extends OnDestroyMixin implements OnInit {

  public title = 'PATIENT DETAILS';
  public patient: Patient;
  public userLogged: User;
  public selectPostalCode: PostalCode;
  public postalCodeSearchResults: PostalCode[];
  public genderList: SelectItem[] = [];
  public selectGender: SelectItem;
  public limsList: SelectItem[] = [];
  public selectLims: SelectItem;
  public locationUpdateFromDevices: SelectItem[] = [];
  public locationUpdateFromDevice: SelectItem;
  public progressSpinner = false;
  public isNewPatient = false;
  public todayDate = new Date();
  public loadingCoordinates = false;
  public stringAddressToGoogleMap: string;
  public patientId = 0;
  private geoCoder;
  public imageId = 0;
  public deleteModal = false;
  public patientDocument: PatientDocument;
  public showDocument = false;
  public dialogUpdatePatientLocation = false;
  public displayNeighborhoodDialog = false;
  public titleNeighborhood = 'Add New Neighborhood';
  public birthDateValidator: boolean = false;
  @ViewChild('patientform', { static: false }) public patientform: NgForm;
  @ViewChild('neighborhoodWindow', { static: false }) private readonly neighborhoodWindow: NeighborhoodComponent;
    
  public neighborhoods: Neighborhood[] = [];
  public selectedNeighborhood: Neighborhood;  
  public disabledNeighborhood = true;
  private confirQuickAdd = {} as QuickApp;
  private confirPatientOrderAppMobile = {} as PatientOrderAppMobileDetails;

  public showMap = false;
  public lat = 18.042216923829237;
  public lng = -67.05580472946167;
  public zoom = 15;

  constructor(private readonly patientService: PatientService,
    private readonly patientOrderService: PatientOrderService,
    private readonly accountService: AccountService,
    private readonly postalCodeService: PostalCodeService,
    private readonly neighborhoodService: NeighborhoodService,
    private readonly quickAddGlobalObjectService: QuickAddGlobalObjectService,
    private readonly patientOrderGlobalObjectService: PatientOrderGlobalObjectService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly clipboard: Clipboard,
    private readonly _location: Location,
    private mapsAPILoader: MapsAPILoader,
    private zone: NgZone) {
    super();    

    this.genderList = [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' },
      { label: 'Other', value: 'Other' }
    ];

    this.limsList = [
      { label: 'BestLab', value: 'BestLab' },
      { label: 'BlueVita LIMS', value: 'BlueVita LIMS' }
    ];

    this.locationUpdateFromDevices = [
      { label: 'Confirmed', value: 'true' },
      { label: 'Unconfirmed', value: 'false' }
    ];    

  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.patient = { neighborhoodId: 0 } as Patient;
      this.patientDocument = {} as PatientDocument;
      this.selectedNeighborhood = { id: 0 } as Neighborhood;

      this.patientId = +this.route.snapshot.params['id'];

      this.userLogged = this.getlocalUser();

      this.quickAddGlobalObjectService.shareQuickAddPatientData.subscribe(x => this.confirQuickAdd = x);
      this.patientOrderGlobalObjectService.sharePatientOrderAppMobileData.subscribe(x => this.confirPatientOrderAppMobile = x);

      if (this.confirQuickAdd !== null) {

        this.isNewPatient = true;
        this.patient = {id: 0, firstName: this.confirQuickAdd.firstName, middleName: this.confirQuickAdd.middleName, lastName: this.confirQuickAdd.lastName, secondLastName: this.confirQuickAdd.secondLastName, phoneNumber: this.confirQuickAdd.phoneNumber, quickAddId: this.confirQuickAdd.id } as Patient;
                      
      } else if (this.confirPatientOrderAppMobile !== null) {

        this.isNewPatient = true;
        let gender = '';
        if (this.confirPatientOrderAppMobile.gender === 'Masculino') {
          gender = 'Male';
        } else if (this.confirPatientOrderAppMobile.gender === 'Femenino') {
          gender = 'Female';
        } else {
          gender = 'Other';
        }
        this.patient = {
          id: 0,
          firstName: this.confirPatientOrderAppMobile.firstName,
          middleName: this.confirPatientOrderAppMobile.middleName,
          lastName: this.confirPatientOrderAppMobile.lastName,
          secondLastName: this.confirPatientOrderAppMobile.secondLastName,
          physicalAddressLine1: this.confirPatientOrderAppMobile.physicalAddressLine1,
          physicalAddressLine2: this.confirPatientOrderAppMobile.physicalAddressLine2,
          physicalCity: this.confirPatientOrderAppMobile.physicalAddressCity,
          physicalAddressState: this.confirPatientOrderAppMobile.physicalAddressState,
          physicalZipCode: this.confirPatientOrderAppMobile.physicalAddressZipCode,
          birthDate: this.confirPatientOrderAppMobile.birthDate,
          gender: gender,
          phoneNumber: this.confirPatientOrderAppMobile.phoneNumber,
          email: this.confirPatientOrderAppMobile.email          
        } as Patient;
        this.birthDateValidator = true;
        this.patient.birthDate = new Date(this.confirPatientOrderAppMobile.birthDate.toString());
        this.setEditPhysicalPostalCodes(this.confirPatientOrderAppMobile.physicalAddressZipCode);
        const filterGender = this.genderList.filter(a => a.value === gender);
        if (filterGender !== undefined) {
          this.selectGender = filterGender[0];
        }

      } else {

        if (this.patientId === 0) {
          this.patient = { quickAddId: 0 } as Patient;
          this.title = 'ADD NEW PATIENT';
          this.isNewPatient = true;
        }
        else {

          this.title = `EDIT PATIENT: ${this.patientId}`;
          this.isNewPatient = false;
          this.getPatient(this.patientId);
        }
      }

    } else {

      this.router.navigate(['/account/login']);
    }

  }

  public onSubmit(): void {    

    this.patient.userId = this.userLogged.id;
    this.patient.physicalCity = this.selectPostalCode.city;

    if (this.stringAddressToGoogleMap !== null && this.isNewPatient === false) {
      // Edit
      var stringAddress: string = '';
      if (this.patient.physicalAddressLine2 !== null) {
        stringAddress = `${this.patient.physicalAddressLine1} ${this.patient.physicalAddressLine2} ${this.patient.physicalCity} ${this.patient.physicalAddressState} ${this.patient.physicalZipCode}`
      } else {
        stringAddress = `${this.patient.physicalAddressLine1} ${this.patient.physicalCity} ${this.patient.physicalAddressState} ${this.patient.physicalZipCode}`
      }

      var address1 = this.stringAddressToGoogleMap.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").trim()
      var address2 = stringAddress.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").trim()

      if (address1 === address2) {
        this.editPatient();
      } else {
        if (this.patient.locationUpdateFromDevice === true) {
          this.dialogUpdatePatientLocation = true;
        } else {
          this.getMapLocationAndAddNewPatient();
        }       
      }

    } else {
      // Add New
      this.getMapLocationAndAddNewPatient();
    }    
  }

  public updatePatientLocation(): void {
    this.getMapLocationAndAddNewPatient();
  }

  public notUpdatePatientLocation(): void {
    this.editPatient();
  }
   
  public getPatient(id: number): void {

    this.patientService.getPatient(id)
      .subscribe((res: any) => this.patient = res as Patient,
        (error: any) => {

          this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewPatient === false) {

            if (this.patient.birthDate !== null) {
              this.patient.birthDate = new Date(this.patient.birthDate.toString());
              this.birthDateValidator = true;
            }

            const filterLims = this.limsList.filter(a => a.value === this.patient.lims);

            if (filterLims !== undefined) {
              this.selectLims = filterLims[0];
            }

            const filterGender = this.genderList.filter(a => a.value === this.patient.gender);

            if (filterGender !== undefined) {
              this.selectGender = filterGender[0];
            }
            
            this.setEditPhysicalPostalCodes(this.patient.physicalZipCode);

            this.setAddressToGoogleMap();

            this.getNeighborhoods(this.patient.physicalCity);
          }

        });
  }

  public addNewPatient(): void {

    this.progressSpinner = true;

    this.patientService.postPatient(this.patient)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (data) => {
          this.patient = data as Patient;
          this.patientId = this.patient.id;
        },
        (error: string) => {

          this.progressSpinner = false;
          this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.progressSpinner = false;
          this.messageService.add({ key: 'patientdetailskey', severity: 'success', summary: 'Patient Created', detail: `${this.patient.fullName} was created successfully.` });
          this.patientform.reset();
          this.patient = {} as Patient;

          // Paciente de orden del app mobile ya existe en Home Service
          if (this.confirPatientOrderAppMobile !== null) {
            this.isNewPatient = false;
          }

          // Quick App Patient
          this.quickAddGlobalObjectService.SetDateService(this.confirQuickAdd.dateService);
          this.quickAddGlobalObjectService.SetQuickAddPatientData(null);

          //this.router.navigate([`/patient-order-details/${this.patientId}/0`])          
        });
  }

  public editPatient(): void {

    this.progressSpinner = true;

    this.patientService.putPatient(this.patient)
      .subscribe((res: any) => {

        this.progressSpinner = false;
        this.messageService.add({ key: 'patientdetailskey', severity: 'success', summary: 'Patient Edited', detail: `The record from ${this.patient.fullName} was edited successfully.` });
      },
        (error: any) => {

          this.progressSpinner = false;
          this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
        });

  }

  public getMapLocationAndAddNewPatient() {

    if (this.patient.physicalAddressLine2 !== null) {
      this.stringAddressToGoogleMap = `${this.patient.physicalAddressLine1} ${this.patient.physicalAddressLine2} ${this.patient.physicalCity} ${this.patient.physicalAddressState} ${this.patient.physicalZipCode}`
    } else {
      this.stringAddressToGoogleMap = `${this.patient.physicalAddressLine1} ${this.patient.physicalCity} ${this.patient.physicalAddressState} ${this.patient.physicalZipCode}`
    }

    if (this.stringAddressToGoogleMap !== null) {

      this.mapsAPILoader.load().then(() => {

        this.geoCoder = new google.maps.Geocoder;

        this.loadingCoordinates = true;

        this.geoCoder.geocode({ 'address': this.stringAddressToGoogleMap }, (results, status) => {

          if (status === 'OK') {
            this.patient.latitude = results[0].geometry.location.lat();
            this.patient.longitude = results[0].geometry.location.lng();
          } else {
            alert('Geocode was not successful for the following reason: ' + status);
          }
          this.loadingCoordinates = false;

          if (this.isNewPatient === true) {
            this.addNewPatient();
          } else {
            this.editPatient();
          }
          
        });

      });     
    }   
  }

  public setAddressToGoogleMap(): void {

    if (this.patient.physicalAddressLine2 !== null) {
      this.stringAddressToGoogleMap = `${this.patient.physicalAddressLine1} ${this.patient.physicalAddressLine2} ${this.patient.physicalCity} ${this.patient.physicalAddressState} ${this.patient.physicalZipCode}`
    } else {
      this.stringAddressToGoogleMap = `${this.patient.physicalAddressLine1} ${this.patient.physicalCity} ${this.patient.physicalAddressState} ${this.patient.physicalZipCode}`
    }    

    if (this.patient.latitude !== null && this.patient.longitude !== null) {

      this.showMap = true;
      this.lat = parseFloat(this.patient.latitude);
      this.lng = parseFloat(this.patient.longitude);
    } else {

      this.showMap = false;

      if (this.stringAddressToGoogleMap !== null) {

        this.mapsAPILoader.load().then(() => {

          this.geoCoder = new google.maps.Geocoder;

          this.loadingCoordinates = true;

          this.geoCoder.geocode({ 'address': this.stringAddressToGoogleMap }, (results, status) => {

            if (status === 'OK') {
              this.patient.latitude = results[0].geometry.location.lat();
              this.patient.longitude = results[0].geometry.location.lng();
            } else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
            
            this.patientService.putPatient(this.patient)
              .subscribe(() => {

                this.showMap = true;
                this.lat = parseFloat(this.patient.latitude);
                this.lng = parseFloat(this.patient.longitude);
              },
                (error: any) => {
                  this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
                });

            this.loadingCoordinates = false;
          });
        });         
      }
    }
  }

  public showCopyClipboard() {

    this.messageService.add({ key: 'patientdetailskey', severity: 'info', summary: '', detail: 'The address was copied.' });
  }

  public setEditPhysicalPostalCodes(zipCode: string): void {

    this.postalCodeService.searchByZipCode(zipCode)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        const postalCodeSearchResults = data as PostalCode[];

        const filterPhysicalPostalCode = postalCodeSearchResults.filter(a => a.code === zipCode);

        if (filterPhysicalPostalCode !== undefined) {
          this.selectPostalCode = filterPhysicalPostalCode[0];
        }

      });
  }

  // search  postal codes
  public searchPostalCodes(event: any): void {

    this.postalCodeService.searchPostalCodes(event.query)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.postalCodeSearchResults = data;
      });
  }

  // set  postal code information
  public onPostalCodeSelection(postalCode: PostalCode): void {
    //console.log(postalCode);
    this.selectPostalCode = postalCode;
    this.patient.physicalAddressState = postalCode.state;
    this.patient.physicalZipCode = postalCode.code;
    this.getNeighborhoods(postalCode.city);
  }

  // clear postal code information
  public clearSelectedPostalCode(): void {

    this.selectPostalCode = undefined;
  }

  public onChangeGender(event: any): void {

    this.patient.gender = event.value;
  }

  public onChangeLims(event: any): void {

    this.patient.lims = event.value;
  }

  public onChangeLocationUpdated(event: any): void {
    //console.log(event.value)
    this.patient.locationUpdateFromDevice = event.value;
  }  

  public onCloseToast(): void {

    if (this.isNewPatient === false) {

      this.zone.run(() => {
        this.router.navigate([`/patient-orders/${this.patientId}`]);
      });
      
    } else {

      this.zone.run(() => {
        this.router.navigate([`/patient`]); 
      });
      
    }
    
  }

  public previewDocument(document: PatientDocument): void {

    this.patientDocument = document;
    this.showDocument = true;
  }

  public downloadDocument(document: PatientDocument): void {

    const nav = (window.navigator as any);

    if (nav && nav.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(image.pathFile);
    } else {
      const fileURL = document.uriDocumentPreview;
      window.open(fileURL);
    }
  }

  public showDeleteConf(id: number): void {

    this.imageId = id;
    this.deleteModal = true;
  }

  public deleteDocument(): void {

    this.deleteModal = false;

    this.patientOrderService.deletePatientDocument(this.imageId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'patientdetailskey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.patient = {} as Patient;
        this.getPatient(this.patientId);

      },
        (error: any) => {

          this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
        });
  }  

  public addNewNeighborhood() {

    this.neighborhoodWindow.openNeighborhoodWindow(true);
    this.titleNeighborhood = 'Add New Neighborhood';
    this.displayNeighborhoodDialog = true;  
  }

  public editNeighborhood(): void {

    this.neighborhoodWindow.openNeighborhoodWindow(false);
    this.titleNeighborhood = 'Edit Neighborhood';
    this.displayNeighborhoodDialog = true;
  }

  public closeNeighborhoodWindow(): void {

    this.displayNeighborhoodDialog = false;
  }

  public onChangeNeighborhood(event: any): void {
    //console.log(event);
    this.patient.neighborhoodId = event.id;
  }

  public getNeighborhoods(city: string): void {

    this.neighborhoodService.getNeighborhoods(city)
      .subscribe((res: any) => this.neighborhoods = res as Neighborhood[],
        (error: any) => {

          this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.neighborhoods.length > 0) {
            this.disabledNeighborhood = false;
          } else {
            this.disabledNeighborhood = true;
          }

          if (this.isNewPatient === false && this.patient.neighborhoodId > 0) {

            const filterNeighborhood = this.neighborhoods.filter(a => a.id === this.patient.neighborhoodId);

            if (filterNeighborhood !== undefined) {
              this.selectedNeighborhood = filterNeighborhood[0];
            }
          }

        });
  }

  public onChangeBirthDate(event: any) {

    if (event === null) {
      this.birthDateValidator = false;
    } else {
      this.birthDateValidator = true;
    }
  }  
  
  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  }

}
