import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Core
import { PatientOrderAppMobileDetails } from './patient-order-app-mobile-details';

@Injectable({
  providedIn: 'root'
})
export class PatientOrderGlobalObjectService {

  private patientOrderAppMobile = new BehaviorSubject<PatientOrderAppMobileDetails>(null);
  public sharePatientOrderAppMobileData = this.patientOrderAppMobile.asObservable();

  constructor() { }

  public SetPatientOrderAppMobileData(entity: PatientOrderAppMobileDetails): void {

    this.patientOrderAppMobile.next(entity);
  }

}
