// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//core
import { QuickApp } from './quick-add';

@Injectable()
export class QuickAddService extends BaseService {

  private readonly apiUrl = 'api/quickadd';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getQuickAdds(userId: string): Observable<QuickApp[]> {

    return this.http.get<QuickApp[]>(`${this.apiUrl}/quick-adds/${userId}`);
  }

  public post(quickAdd: QuickApp): Observable<QuickApp> {

    quickAdd.setDateService = `${quickAdd.dateService.toLocaleDateString('en-US')} ${quickAdd.dateService.toLocaleTimeString('en-US')}`;

    return this.http.post<QuickApp>(`${this.apiUrl}`, quickAdd, { headers: this.PutPostHeaders });
  }

  public put(quickAdd: QuickApp): Observable<any> {

    quickAdd.setDateService = `${quickAdd.dateService.toLocaleDateString('en-US')} ${quickAdd.dateService.toLocaleTimeString('en-US')}`;    

    return this.http.put<any>(`${this.apiUrl}`, quickAdd, { headers: this.PutPostHeaders });
  }

  public delete(Id: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/${Id}`);
  }

}
