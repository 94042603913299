//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientOrderAppMobileService } from '../patient-order-app-mobile.service';

//Core
import { OrderImageDto } from '../image-viewer/order-image-dto'

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html'
})
export class ImageViewerComponent extends OnDestroyMixin implements OnInit {

  public orderImages: OrderImageDto[] = [];

  constructor(private readonly patientOrderAppMobileService: PatientOrderAppMobileService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    const patientId = +this.route.snapshot.params['patientId'];
    const orderId = +this.route.snapshot.params['orderId'];
    const laboratoryLicense = this.route.snapshot.params['laboratoryLicense'];

    this.getOrderImages(patientId, orderId, laboratoryLicense);
  }

  public getOrderImages(patientId: number, orderId: number, laboratoryLicense: string): void {

    this.patientOrderAppMobileService.getOrderImages(patientId, orderId, laboratoryLicense)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: data => {
          this.orderImages = data as OrderImageDto[];
          //console.log(this.orderImages);
        },
        error: error => {

          this.messageService.add({ key: 'imageviewerkey', severity: 'error', summary: 'Error', detail: error });
        }
      });
  }

}
