import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TemperatureLogService } from './temperature-log.service';
import { AccountService } from '../account/account.service';
import { PhlebotomistService } from '../phlebotomist/phlebotomist.service';

//Core
import { TemperatureLog } from './temperature-log';
import { User } from '../account/user';
import { Phlebotomist } from '../phlebotomist/phlebotomist';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';

// Moment
import * as moment from 'moment';

@Component({
  selector: 'app-temperature-log',
  templateUrl: './temperature-log.component.html'
})
export class TemperatureLogComponent extends OnDestroyMixin implements OnInit {

  title = 'TEMPERATURE LOG';
  public temperatureLogs: TemperatureLog[] = [];
  public userLogged: User;
  public iconSpinner = false;
  public loading = false;  
  public cols: any[];  
  public progressSpinner = false;  
  public deleteModal = false;  
  public fromDate: Date;
  public toDate: Date;
  public filterDateType: string = '';
  public displaySetFilterParams = false;
  public waitingScreen = false;
  public phlebotomists: Phlebotomist[] = [];
  public selectedPhlebotomist: Phlebotomist;

  constructor(private readonly temperatureLogService: TemperatureLogService,
    private readonly accountService: AccountService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();

    this.filterDateType = 'single';
    this.fromDate = new Date(moment().startOf('day').format('l'));
    this.toDate = new Date(moment().endOf('day').format('l'));
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();

      this.getTemperatureLogs();

    } else {

      this.router.navigate(['/login']);
    }
  }

  public getTemperatureLogs(): void {

    if (!moment(this.fromDate).isSameOrBefore(this.toDate)) {

      this.messageService.add({ key: 'temperaturelogkey', severity: 'warn', summary: 'Warnning', detail: 'From Date must be greater than To Date.' });

      return;
    }

    this.loading = true;

    this.temperatureLogService.getTemperatureLogsWithFilter(this.fromDate, this.toDate)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.temperatureLogs = data as TemperatureLog[],
        (error: any) => {
          this.loading = false;
          this.messageService.add({ key: 'temperaturelogkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          //console.log(this.temperatureLogs);
          this.loading = false;
          this.loadColumns();
          this.getPhlebotomists();
        });
  }

  public onCalendarSelect() {

    if (this.filterDateType == 'single') {
      if (this.fromDate !== null) {
        this.toDate = new Date(this.fromDate.toString());

        this.getTemperatureLogs();
      }
    }
  }

  public loadColumns(): void {

    this.cols = [
      /*{ field: 'id', header: 'ID' },*/
      { field: 'temperatureDate', header: 'Date' },
      { field: 'temperature', header: 'Temperature' },
      { field: 'phlebotomistName', header: 'Phlebotomist Name' },
      { field: 'comment', header: 'Comment' }
    ];
  }

  public getPhlebotomists(): void {

    this.phlebotomists = [];

    this.phlebotomistService.getPhlebotomists()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.phlebotomists = data as Phlebotomist[],
        (error: any) => {
          console.log(error);
        });
  }

  public getTemperatureLogReport() {
    this.displaySetFilterParams = false;
    this.waitingScreen = true;
    this.temperatureLogService.getTemperatureLogReport(this.fromDate, this.toDate, this.selectedPhlebotomist.name).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
      this.waitingScreen = false;
      //saveAs(blob, 'TestReport.' + this.selectedFormat.toLowerCase());
    });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }    

    return userLogged;
  }

}
