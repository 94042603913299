//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AbsentService } from './absent.service';
import { PhlebotomistService } from '../phlebotomist.service';
import { AccountService } from '../../account/account.service';
import { UserLoggedInService } from '../../account/user-logged-in.service';

//Core
import { AbsentPhlebotomist } from './absent';
import { Phlebotomist } from '../phlebotomist';
import { User } from '../../account/user'

// Moment
import * as moment from 'moment';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-absent',
  templateUrl: './absent.component.html'
})
export class AbsentComponent extends OnDestroyMixin implements OnInit {

  public absentPhlebotomists: AbsentPhlebotomist[] = [];
  public absentPhlebotomist: AbsentPhlebotomist = {} as AbsentPhlebotomist;
  public phlebotomists: Phlebotomist[] = [];
  public selectPhlebotomist: Phlebotomist;
  public displayAbsentPhlebotomistDialog = false;
  public isNewAbsentPhlebotomist = false;
  public dialogHeader: string;
  public userLogged: User;
  public progressSpinner = false;
  public cols: any[];
  public loading: boolean;
  public minDate = new Date();
  public maxDate = new Date();
  public deleteModal = false;
  public absentPhlebotomistId = 0;
  @ViewChild('absentForm', { static: false }) public absentForm: NgForm;

  constructor(private readonly absentService: AbsentService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly accountService: AccountService,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();

      this.getAbsentPhlebotomists();
      
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      let prevMonth = (month === 0) ? 11 : month - 1;
      let prevYear = (prevMonth === 11) ? year - 1 : year;
      let nextMonth = (month === 10) ? 0 : month + 2;
      let nextYear = (nextMonth === 0) ? year + 1 : year;
      this.minDate.setMonth(prevMonth);
      this.minDate.setFullYear(prevYear);
      this.maxDate.setMonth(nextMonth);
      this.maxDate.setFullYear(nextYear);

    } else {

      this.router.navigate(['/login']);
    }

  }

  public getAbsentPhlebotomists(): void {

    this.loading = true;

    this.absentService.getAbsentPhlebotomists()
      .subscribe((res: any) => this.absentPhlebotomists = res as AbsentPhlebotomist[],
        (error: any) => {
          this.loading = false;
          this.messageService.add({ key: 'absentkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          this.loading = false;
          this.loadColumns();
          this.getPhlebotomists();
        });
  }

  public getPhlebotomists(): void {

    this.loading = true;
    this.phlebotomists = [];

    this.phlebotomistService.getPhlebotomists()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.phlebotomists = data as Phlebotomist[];
        this.loading = false;
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'absentkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public onSubmit(): void {    

    if (!moment(this.absentPhlebotomist.startDateTime2).isSameOrBefore(this.absentPhlebotomist.endDateTime2)) {

      this.messageService.add({ key: 'absentkey', severity: 'warn', summary: 'Warnning', detail: 'From date must be greater than To date.' });

      return;
    }

    this.progressSpinner = true;

  if (this.isNewAbsentPhlebotomist === true) {

    this.absentService.postAbsentPhlebotomist(this.absentPhlebotomist)
        .pipe(untilComponentDestroyed(this))
        .subscribe((data) => this.absentPhlebotomist = data as AbsentPhlebotomist,
          (error: string) => {
            this.progressSpinner = false;
            this.messageService.add({ key: 'absentkey', severity: 'error', summary: 'Error', detail: error });

          },
          () => {

            this.messageService.add({ key: 'absentkey', severity: 'success', summary: 'Absent Phlebotomist Created', detail: `${this.absentPhlebotomist.phlebotomistName} was created successfully.` });
            this.absentForm.reset();
            this.absentPhlebotomist = {} as AbsentPhlebotomist;
            this.progressSpinner = false;
            this.displayAbsentPhlebotomistDialog = false;
            this.getAbsentPhlebotomists();
          });

    } else {

      this.absentService.putAbsentPhlebotomist(this.absentPhlebotomist)
        .subscribe((res: any) => {

          this.messageService.add({ key: 'absentkey', severity: 'success', summary: 'Absent Phlebotomist Edited', detail: `The record from ${this.absentPhlebotomist.phlebotomistName} was edited successfully.` });
        },
          (error: any) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'absentkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.absentForm.reset();
            this.absentPhlebotomist = {} as AbsentPhlebotomist;
            this.progressSpinner = false;
            this.displayAbsentPhlebotomistDialog = false;
            this.getAbsentPhlebotomists();
          });
    }
  }

  public addNewAbsentPhlebotomist(): void {

    this.absentForm.reset();
    this.absentPhlebotomist = {} as AbsentPhlebotomist;
    this.isNewAbsentPhlebotomist = true;
    this.dialogHeader = "ADD NEW ABSENT PHLEBOTOMIST";
    this.displayAbsentPhlebotomistDialog = true;
  }

  public editAbsentPhlebotomist(absentPhlebotomist: AbsentPhlebotomist): void {

    this.absentPhlebotomist = {} as AbsentPhlebotomist;
    this.absentPhlebotomist = absentPhlebotomist;

    this.isNewAbsentPhlebotomist = false;
    this.dialogHeader = "EDIT ABSENT PHLEBOTOMIST";
    const filterPhlebotomist = this.phlebotomists.filter(a => a.id === this.absentPhlebotomist.phlebotomistId);
    if (filterPhlebotomist !== undefined) {
      this.selectPhlebotomist = filterPhlebotomist[0];
    }    
    this.absentPhlebotomist.startDateTime2 = new Date(this.absentPhlebotomist.startDateTime);
    this.absentPhlebotomist.endDateTime2 = new Date(this.absentPhlebotomist.endDateTime);
    this.displayAbsentPhlebotomistDialog = true;
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'phlebotomistName', header: 'Phlebotomist' },
      { field: 'startDateTime', header: 'Start Date Time' },
      { field: 'endDateTime', header: 'End Date Time' }
    ];
  }    

  public onChangePhlebotomist(event: any): void {

    this.absentPhlebotomist.phlebotomistId = event.id;
    this.absentPhlebotomist.phlebotomistName = event.name;
  }

  public showDeleteConf(id: number): void {

    this.absentPhlebotomistId = id;
    this.deleteModal = true;
  }

  public deleteAbsentPhlebotomist(): void {

    this.deleteModal = false;

    this.absentService.deleteAbsentPhlebotomist(this.absentPhlebotomistId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'absentkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'absentkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.getAbsentPhlebotomists();
        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    this.userLoggedInService.updateUserLogged(userLogged);

    const userRoles = userLogged.roles.filter(row => row === 'Admin' || row === 'SuperAdmin');

    //if (userRoles !== undefined) {
    //  this.btnChangeVisitsToPhleb = true;
    //  this.showPinCodeByAdminUser = true;
    //}
    //else {
    //  this.btnChangeVisitsToPhleb = false;
    //}

    return userLogged;
  }

}
