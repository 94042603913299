//Angular
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from '../patient/patient.service';
import { PatientOrderService } from './patient-order.service';
import { AccountService } from '../account/account.service';
import { LaboratoryService } from '../laboratory/laboratory.service';
import { PhlebotomistService } from '../phlebotomist/phlebotomist.service';
import { VisitFilterService } from './visit/visit-filter.service';
import { ProcedureService } from '../procedure/procedure.service';
import { QuickAddGlobalObjectService } from '../quick-add/quick-add-global-object.service';
import { AbsentService } from '../phlebotomist/absent/absent.service';

//Component
import { PopUpEditPatientComponent } from '../patient/pop-up/pop-up-edit-patient.component';

//Core
import { Patient } from '../patient/patient';
import { PatientOrder } from './patient-order';
import { User } from '../account/user'
import { Laboratory } from '../laboratory/laboratory';
import { Phlebotomist } from '../phlebotomist/phlebotomist';
import { Item } from './model/item';
import { ExistVisitRangeMinutesDto } from './model/exist-visit-range-minutes-dto';
import { MapLocation } from '../map/location-model'
import { Procedure } from '../procedure/procedure';
import { OrderProcedureDto } from '../order-procedure/order-procedure-dto';


//Primeng
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-patient-order-details',
  templateUrl: './patient-order-details.component.html'
})
export class PatientOrderDetailsComponent extends OnDestroyMixin implements OnInit {

  public title = 'PATIENT ORDERS';
  public patientOrders: PatientOrder[] = [];
  public patientOrder: PatientOrder;
  public patient: Patient;
  public userLogged: User;
  public isNewOrder = false;
  public laboratories: Laboratory[] = [];
  public selectLaboratory: Laboratory;
  public labInfo: string;
  public phlebotomists: Phlebotomist[] = [];
  public selectPhlebotomist: Phlebotomist;
  public progressSpinner = false;
  public minDate = new Date();
  public maxDate = new Date();
  public orderStatusList: Item[] = [];
  public selectOrderStatus: Item;
  public readonlyOrderStatus = false;
  public orderStatusValidator = true;
  public isCancelledOrderStatus = false;
  public arrayTextFilter: Item[] = [];
  public filterType: string = '';
  public existVisitRangeMinutesDto: ExistVisitRangeMinutesDto;
  public selectVisitDialog = false;
  public cols: any[];
  public loading = false;
  public displayEditPatientDialog = false;
  public titleEditPatient = 'Edit Patient';
  public patientId: number = 0;
  private geoCoder;
  public strEstimatedTime = '';
  public progressSpinnerDistance = false;
  public mapMarkers: google.maps.Marker[] = [];  
  @ViewChild('patientOrderDetailsForm', { static: false }) public patientOrderDetailsForm: NgForm;
  public procedures: Procedure[] = [];
  public filteredProcedures: Procedure[] = [];
  public selectedProcedures: Procedure[] = [];
  private confirDateService: Date;
  public absentPhlebotomist = false;

  public markers: MapLocation[] = [];
  public lat = 18.042216923829237;
  public lng = -67.05580472946167;
  public zoom = 11;

  @ViewChild(PopUpEditPatientComponent) popupEditPatient: PopUpEditPatientComponent;

  constructor(private readonly patientOrderService: PatientOrderService,
    private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly laboratoryService: LaboratoryService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly visitFilterService: VisitFilterService,
    private readonly procedureService: ProcedureService,
    private readonly quickAddGlobalObjectService: QuickAddGlobalObjectService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly absentService: AbsentService,
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly zone: NgZone) {
    super();

    this.orderStatusList = [
      { label: 'New Visit', value: 'New Visit' },
      { label: 'In Transit', value: 'In Transit' },
      { label: 'Take Samples', value: 'Take Samples' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Cancelled', value: 'Cancelled' }
    ];
  }

  public ngOnInit(): void {
    
    if (this.isUserLoggedIn()) {

      this.patient = {} as Patient;

      this.patientOrder = { id: 0 } as PatientOrder;

      this.selectOrderStatus = {} as Item;

      this.existVisitRangeMinutesDto = { existVisit: false } as ExistVisitRangeMinutesDto;

      const patientId = this.route.snapshot.params['patientId'];
      const orderId = this.route.snapshot.params['orderId'];

      this.userLogged = this.getlocalUser();

      if (patientId !== undefined) {

        this.getPatient(patientId);
      }

      this.maxDate.setDate(this.minDate.getDate() + 122);
      

      if (orderId === '0') {

        this.title = 'ADD NEW VISIT';
        this.isNewOrder = true;
        this.patientOrder.estimatedTime = 15;
        this.patientOrder.dateService = undefined;

        this.quickAddGlobalObjectService.shareDateService.subscribe(x => this.confirDateService = x);

        if (this.confirDateService !== null) {
          this.patientOrder.dateService = new Date(this.confirDateService);
        } else {
          this.patientOrder.dateService = new Date();
        }
        
      } else {

        this.title = `EDIT VISIT: ${orderId}`;
        this.isNewOrder = false;
        this.getPatientOrder(orderId);
      }

      this.getVisitTextFilter();

    } else {

      this.router.navigate(['/login']);
    }

  }

  public getPatient(id: number): void {

    this.patientService.getPatient(id)
      .subscribe((res: any) => this.patient = res as Patient,
        (error: any) => {

          this.messageService.add({ key: 'patientordersdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewOrder === true) {

            this.title = `Add new visit to ${this.patient.fullName}`;

            this.getLaboratories();

            this.mapsAPILoader.load().then(() => {

              this.geoCoder = new google.maps.DistanceMatrixService;
              this.getLaboratories();
            });
            
          } else {

            this.title = `Edit visit to ${this.patient.fullName}`;
            this.patientId = this.patient.id;
          }

          if (this.patient !== undefined && this.patient.latitude !== undefined) {

            this.patientOrder.latitude = this.patient.latitude
            this.lat = parseFloat(this.patient.latitude);
          }

          if (this.patient !== undefined && this.patient.longitude !== undefined) {

            this.patientOrder.longitude = this.patient.longitude
            this.lng = parseFloat(this.patient.longitude);
          }                  

        });
  }

  public getPatientOrder(id: number): void {

    this.patientOrderService.getPatientOrder(id)
      .subscribe((res: any) => this.patientOrder = res as PatientOrder,
        (error: any) => {

          this.messageService.add({ key: 'patientordersdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewOrder === false) {

            if (this.patientOrder.dateService !== null) {

              this.patientOrder.dateService = new Date(this.patientOrder.dateService);
            }

            const filterOrderStatus = this.orderStatusList.filter(a => a.value === this.patientOrder.orderStatus);

            if (filterOrderStatus !== undefined) {
              this.selectOrderStatus = filterOrderStatus[0];              
            }

            if (this.patientOrder.orderStatus === 'Cancelled') {
              this.readonlyOrderStatus = true;
              this.isCancelledOrderStatus = true;
            } else {
              this.readonlyOrderStatus = false;
              this.isCancelledOrderStatus = false;
            }

            if (this.patientOrder.orderProcedures.length > 0) {
              this.loadProcedures(this.patientOrder.orderProcedures);
            }
            this.getLaboratories();            

            this.mapsAPILoader.load().then(() => {

              this.geoCoder = new google.maps.DistanceMatrixService;
              this.getLaboratories();
            });
          }

        });
  }

  public onSubmit(): void {

    this.progressSpinner = true;

    //Add procedures
    if (this.selectedProcedures.length > 0) {

      this.patientOrder.orderProcedures = [];

      this.selectedProcedures.forEach(procedure => {
        let newRow = { patientId: this.patient.id, patientOrderId: this.patientOrder.id, sharedVisit: false, quickSearchCode: procedure.quickSearchCode, descriptionCustom: procedure.descriptionCustom } as OrderProcedureDto;
        this.patientOrder.orderProcedures.push(newRow);
      });
      //console.log(this.patientOrder.tests);
    }

    this.absentService.postAbsentByPhlebotomistIdAndServiceDate(this.patientOrder.phlebotomistId, this.patientOrder.dateService)
      .subscribe((res: any) => {

        this.absentPhlebotomist = false;

        if (this.isNewOrder === true) {

          this.addNewOrder();

        } else {

          this.editOrder();
        }

      },
        (error: any) => {
          this.absentPhlebotomist = true;
          this.messageService.add({ key: 'patientordersdetailskey', severity: 'error', summary: 'Error', detail: error });
        });
    
  }

  public addNewOrder() {

    this.patientOrder.patientId = this.patient.id
    this.patientOrder.userId = this.userLogged.id;
    this.patientOrder.orderStatus = "New Visit";

    this.patientOrderService.postPatientOrder(this.patientOrder)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.existVisitRangeMinutesDto = data as ExistVisitRangeMinutesDto,
        (error: string) => {

          this.messageService.add({ key: 'patientordersdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.existVisitRangeMinutesDto.existVisit === true) {

            this.patientOrder.dateService = undefined;
            this.patientOrder.dateService = new Date(this.existVisitRangeMinutesDto.nextAvailableDate);
            this.messageService.add({ key: 'patientordersdetailskey', severity: 'warn', summary: 'Warning', detail: this.existVisitRangeMinutesDto.message });
          } else {

            this.messageService.add({ key: 'patientordersdetailskey', severity: 'success', summary: 'Patient Order Created', detail: 'Patient Order was created successfully.' });
            this.patientOrderDetailsForm.reset();
            this.patientOrder = {} as PatientOrder;
          }
          this.quickAddGlobalObjectService.SetDateService(null);
        });
  }

  public editOrder() {

    this.patientOrderService.putPatientOrder(this.patientOrder)
      .subscribe(() => {

        this.messageService.add({ key: 'patientordersdetailskey', severity: 'success', summary: 'Patient Order Edited', detail: 'The record was edited successfully.' });
      },
        (error: any) => {

          this.messageService.add({ key: 'patientordersdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.patientOrderDetailsForm.reset();
          this.patientOrder = {} as PatientOrder;
        });
  }

  public getLaboratories(): void {

    this.laboratoryService.getUserDefaultLaboratories(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.laboratories = data as Laboratory[],
        (error: any) => {

          console.log(error);
        },
        () => {
          
          if (this.patientOrder !== undefined) {

            this.strEstimatedTime = this.patientOrder.estimatedTimeText

            const filterLaboratory = this.laboratories.filter(a => a.licenseNumber === this.patientOrder.labLicenseNumber);

            if (filterLaboratory !== undefined) {
              this.selectLaboratory = filterLaboratory[0];
              this.createLabAndPatientMarkers();            
            }
          }

          if (this.isNewOrder === true && this.laboratories.length === 1) {

            this.selectLaboratory = this.laboratories[0];
            this.patientOrder.labLicenseNumber = this.laboratories[0].licenseNumber;
            this.patientOrder.laboratoryName = this.laboratories[0].name;
            this.createLabAndPatientMarkers();
            if (this.selectLaboratory !== undefined) {              
              this.getDistancia(`${this.selectLaboratory.latitude},${this.selectLaboratory.longitude}`, `${this.patient.latitude},${this.patient.longitude}`)
            }
          }

          this.getPhlebotomists();
          
        });
  }

  public onChangeLaboratory(event: any): void {

    this.patientOrder.labLicenseNumber = event.licenseNumber;
    this.patientOrder.laboratoryName = event.name;
    this.labInfo = `${event.physicalAddressLine1} | Tel. ${event.phoneNumber}`;
    this.createLabAndPatientMarkers();
    this.getDistancia(`${event.latitude},${event.longitude}`, `${this.patient.latitude},${this.patient.longitude}`)
  }

  public getDistancia(origen: string, destino: string) {
    this.progressSpinnerDistance = true;
    return this.geoCoder.getDistanceMatrix({ 'origins': [origen], 'destinations': [destino], travelMode: 'DRIVING' }, (results: any) => {
      //console.log('resultados distancia (mts) -- ', results.rows[0].elements[0].distance.value)
      //console.log('Estimated time: ', results.rows[0].elements[0].duration.text)
      var duration = results.rows[0].elements[0].duration.value;
      // Los +15 minutos es para sacar las muestras.
      var min = duration / 60;
      //this.patientOrder.estimatedTime = Math.round(min + 15);
      this.patientOrder.estimatedTime = Math.round(min);
      this.patientOrder.estimatedTimeText = `${this.patientOrder.estimatedTime} (min) \n ${results.rows[0].elements[0].distance.text}`;
      this.strEstimatedTime = this.patientOrder.estimatedTimeText
      //console.log(results.rows[0].elements[0].duration.text);
      this.progressSpinnerDistance = false;
    });
  }

  public createLabAndPatientMarkers(): void {

    this.mapMarkers = [];
    this.markers = [];

    if (this.selectLaboratory !== undefined) {
      const labLocation: MapLocation = {
        name: this.selectLaboratory.name,
        lat: parseFloat(this.selectLaboratory.latitude),
        lng: parseFloat(this.selectLaboratory.longitude),
        iconUrl: '/assets/green-dot.png',
        label: ''
      };
      this.markers.push(labLocation);
    }

    const patientLocation: MapLocation = {
      name: this.patient.fullName,
      lat: parseFloat(this.patient.latitude),
      lng: parseFloat(this.patient.longitude),
      iconUrl: '/assets/red-dot.png',
      label: ''
    };
    this.markers.push(patientLocation);
  }

  public getPhlebotomists(): void {

    this.phlebotomists = [];

    this.phlebotomistService.getUserDefaultPhlebotomists(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.phlebotomists = data as Phlebotomist[],
        (error: any) => {

          console.log(error);
        },
        () => {

          if (this.patientOrder !== undefined) {

            const filterPhlebotomist = this.phlebotomists.filter(a => a.id === this.patientOrder.phlebotomistId);

            if (filterPhlebotomist !== undefined) {
              this.selectPhlebotomist = filterPhlebotomist[0];
            }
          }

          if (this.isNewOrder === true && this.phlebotomists.length === 1) {

            this.selectPhlebotomist = this.phlebotomists[0];
            this.patientOrder.phlebotomistId = this.phlebotomists[0].id;
          }

          if (this.isNewOrder === true && this.phlebotomists.length > 0) {

            var isNotNeighborhood = false;

            this.phlebotomists.forEach(phlebotomist => {

              if (phlebotomist.neighborhoodList !== undefined && phlebotomist.neighborhoodList !== null) {

                var neighborhoods: string[] = phlebotomist.neighborhoodList.split(',');

                neighborhoods.forEach((n) => {

                  var id = Number(n);

                  if (this.patient.neighborhoodId === id) {

                    const filterPhlebotomist = this.phlebotomists.filter(a => a.id === phlebotomist.id);

                    if (filterPhlebotomist !== undefined) {
                      this.selectPhlebotomist = filterPhlebotomist[0];
                      this.patientOrder.phlebotomistId = filterPhlebotomist[0].id;
                      isNotNeighborhood = true;
                    }

                  }

                });
              }

            });

            if (isNotNeighborhood === false) {

              this.selectPhlebotomist = this.phlebotomists[0];
              this.patientOrder.phlebotomistId = this.phlebotomists[0].id;
            }           
          }

          this.getProcedures();

        });
  }

  public onChangePhlebotomist(event: any): void {

    this.patientOrder.phlebotomistId = event.id;
  }

  public onCloseToast(): void {

    this.progressSpinner = false;

    if (this.absentPhlebotomist === false) {
      this.goToVisits();
    }
  }

  public goToVisits(): void {

    if (this.arrayTextFilter.length > 0 && this.filterType.length > 0) {      
      this.zone.run(() => {
        this.router.navigate(['/visits']);
      });
    } else {
      if (this.existVisitRangeMinutesDto.existVisit === false) {

        this.zone.run(() => {
          this.router.navigate([`/patient-orders/${this.patient.id}`]);
        }); 
      }
           
    }
    
  }

  public onChangeOrderStatus(event: any): void {

    if (event.value === 'Cancelled') {
      this.isCancelledOrderStatus = true;
      this.orderStatusValidator = false;
    } else {
      this.isCancelledOrderStatus = false;
      this.orderStatusValidator = true;
      this.patientOrder.cancelOrderComment = '';
    }

    this.patientOrder.orderStatus = event.value;
  }

  public onKeyCancelOrderComment(value: string): void {

    if (value.length > 2) {
      this.orderStatusValidator = true;
    } else {
      this.orderStatusValidator = false;
    }

  }

  //Visit Filter
  public getVisitTextFilter(): void {

    this.visitFilterService.getArrayTextFilter.subscribe(x => this.arrayTextFilter = x);

    this.visitFilterService.getFilterType.subscribe(x => this.filterType = x);    
  }

  //public onChangeImportTravelTime() {

  //  if (this.patientOrder.importTravelTime === true) {

  //    if (this.patientOrder.phlebotomistId === undefined) {
  //      this.messageService.add({ key: 'patientordersdetailskey', severity: 'warn', summary: 'Warning', detail: 'Select phlebotomist first and try again.' });
  //    } else {

  //      this.getVisitByPhlebotomist();
  //      this.selectVisitDialog = true;

  //    }      
  //  }       
  //}

  public getVisitByPhlebotomist(): void {

    this.loading = true;

    this.patientOrderService.getVisitByPhlebotomist(this.patientOrder.phlebotomistId)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.patientOrders = data as PatientOrder[];
        this.loadColumns();
        this.loading = false;        
      },
        (error: any) => {
          this.loading = false;
          console.log(error);
        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'patientName', header: 'Patient Name' },
      { field: 'laboratoryName', header: 'Laboratory' },
      { field: 'getDateService', header: 'Date Service' },
      { field: 'orderStatus', header: 'Visit Status' }
    ];
  }

  public selectVisit(patientOrder: PatientOrder): void {

    this.selectVisitDialog = false;

    if (this.patientOrder.dateService !== null) {
      this.patientOrder.dateService = new Date(patientOrder.dateService);
    }

    if (this.patientOrder.estimatedTime !== null) {
      this.patientOrder.estimatedTime = patientOrder.estimatedTime;
    }
  }

  public popUpEditPatient(): void {

    //this.neighborhoodWindow.openNeighborhoodWindow(false);
    this.popupEditPatient.loadData(this.patient);
    this.titleEditPatient = `Edit Patient: ${this.patient.fullName}`;
    this.displayEditPatientDialog = true;
  }

  public closeEditPatientWindow(): void {

    this.displayEditPatientDialog = false;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

  public getProcedures(): void {

    this.procedureService.getProcedures()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.procedures = data as Procedure[];        
      },
        (error: any) => {
          console.log(error);
        });
  }

  public filterProcedures(event) {    
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.procedures.length; i++) {
      let procedure = this.procedures[i];
      if (isNaN(query)) {
        if (procedure.descriptionCustom.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(procedure);
        }        
      } else {
        if (procedure.quickSearchCode.toString().indexOf(query) == 0) {
          filtered.push(procedure);
        }
      }
    }

    this.filteredProcedures = filtered;
  }

  public selectProcedures(event) {

    console.log(event);
  }

  public loadProcedures(procedures: OrderProcedureDto[]) {
    let json = JSON.stringify(procedures);
    this.selectedProcedures = JSON.parse(json);
    //console.log(json);
    //this.selectedProcedures = [ { quickSearchCode: 1, descriptionCustom: 'CBC & DIFFERENTIAL' },
    //  { quickSearchCode: 611, descriptionCustom: 'COMPREHENSIVE NUTRITIONAL PANEL' } ];
  }

}
