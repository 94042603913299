// Angular
import { Injectable } from '@angular/core';

// Objects
import { PaginationParams } from './pagination';

// PrimeNG
import { LazyLoadEvent } from 'primeng/api';
import { BaseService } from '../../core/base.service';

// Validator
import { isDefined } from 'class-validator';

@Injectable()
export class PaginationService extends BaseService {

  public constructor() {

    super();
  }

  public getEventParams(event: LazyLoadEvent): PaginationParams {

    const params: PaginationParams = {
      firstRow: event.first,
      numberOfRows: event.rows,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
      filters: []
    };

    if (isDefined(event.filters)) {

      for (const field of Object.keys(event.filters!)) {

        if (event.filters!.hasOwnProperty(field)) {

          const prop = event.filters![field];

          params.filters.push({
            filterColumn: field,
            matchMode: prop.matchMode,
            value: prop.value
          });
        }
      }
    }

    return params;
  }
}
