//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientOrderService } from '../patient-order.service';
import { PatientService } from '../../patient/patient.service';
import { AccountService } from '../../account/account.service';

//Core
import { PatientOrder } from '../patient-order';
import { User } from '../../account/user';
import { PatientDocument } from '../model/patientDocument'
import { MobilePatient } from '../../patient/mobile-patient';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-visit-details',
  templateUrl: './visit-details.component.html'
})
export class VisitDetailsComponent extends OnDestroyMixin implements OnInit {

  public title = 'VISITS DETAILS';
  public visitDetails: PatientOrder;
  public userLogged: User;
  public orderId = 0;
  public imageId = 0;
  public deleteModal = false;
  public patientDocument: PatientDocument;
  public showDocument = false;
  public mobilePatients: MobilePatient[] = [];

  constructor(private readonly patientOrderService: PatientOrderService,
    private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    this.visitDetails = {} as PatientOrder;
    this.patientDocument = {} as PatientDocument;

    if (this.isUserLoggedIn()) {

      this.orderId = this.route.snapshot.params['orderId'];

      if (this.orderId > 0) {
        this.getVisitDetails(this.orderId);
      }      

    } else {

      this.router.navigate(['/login']);
    }

  }

  public getVisitDetails(orderid: number): void {

    this.patientOrderService.getVisitDetails(orderid)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.visitDetails = data as PatientOrder,
        (error: any) => {

          this.messageService.add({ key: 'visitdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.visitDetails.sharedVisit === false) {
            this.title = this.visitDetails.patientName;
            //console.log(this.visitDetails.documents);            
          } else {
            this.title = "Shared Visit:"
            this.getPatientsByIds(`${this.visitDetails.patientId},${this.visitDetails.sharedVisitPatientIds}`);
          }

        });
  }

  public getPatientsByIds(ids: string): void {

    this.patientService.getPatientsByIds(ids)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.mobilePatients = data as MobilePatient[],
        (error: any) => {

          this.messageService.add({ key: 'visitdetailskey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public previewDocument(document: PatientDocument): void {

    this.patientDocument = document;
    this.showDocument = true;
  }

  public downloadDocument(document: PatientDocument): void {

    const nav = (window.navigator as any);

    if (nav && nav.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(image.pathFile);
    } else {
      const fileURL = document.uriDocumentPreview;
      window.open(fileURL);
    }
  }

  public showDeleteConf(id: number): void {

    this.imageId = id;
    this.deleteModal = true;
  }

  public deleteDocument(): void {

    this.deleteModal = false;

    this.patientOrderService.deletePatientDocument(this.imageId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'visitdetailskey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.visitDetails = {} as PatientOrder;
        this.getVisitDetails(this.orderId);

      },
        (error: any) => {

          this.messageService.add({ key: 'visitdetailskey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  }

}
