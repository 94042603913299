import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SampleDeliveryService } from '../sample-delivery/sample-delivery.service';
import { AccountService } from '../account/account.service';
import { PatientService } from '../patient/patient.service';
import { PhlebotomistService } from '../phlebotomist/phlebotomist.service';
import { LaboratoryService } from '../laboratory/laboratory.service';

//Core
import { SampleDelivery } from './sample-delivery';
import { User } from '../account/user';
import { Patient } from '../patient/patient';
import { Phlebotomist } from '../phlebotomist/phlebotomist';
import { Laboratory } from '../laboratory/laboratory';
import { SignaturePinCode } from './signature-pincode';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-sample-delivery-addnew-edit',
  templateUrl: './sample-delivery-addnew-edit.component.html'
})
export class SampleDeliveryAddNewEditComponent extends OnDestroyMixin implements OnInit {

  title = 'SAMPLE DELIVERY';
  public userLogged: User;
  public sampleDelivery: SampleDelivery = {} as SampleDelivery;
  public patient: Patient;
  public isNewRecord = false;
  public patientId: number = 0;
  public minDate = new Date();
  public maxDate = new Date();
  public displaySearchPhlebotomist = false;
  public pinCode = '';
  public signingPincode = '';
  public phlebotomistValidator = false;
  public phlebotomists: Phlebotomist[] = [];
  public laboratories: Laboratory[] = [];
  public selectLaboratory: Laboratory;
  public progressSpinner = false;
  public displaySearchSignature = false;
  public signaturePincode: SignaturePinCode = {} as SignaturePinCode;
  @ViewChild('sampleDeliveryAddNewEditForm', { static: false }) public sampleDeliveryAddNewEditForm: NgForm;

  constructor(private readonly sampleDeliveryService: SampleDeliveryService,
    private readonly accountService: AccountService,
    private readonly patientService: PatientService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly laboratoryService: LaboratoryService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,) {
    super();
  }

  public ngOnInit(): void {
    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();

      const patientId = +this.route.snapshot.params['patientId'];
      const Id = +this.route.snapshot.params['Id'];

      if (patientId !== undefined && patientId > 0) {

        this.getPatient(patientId);
      }

      if (Id === 0) {

        this.title = 'ADD NEW RECORD';
        this.isNewRecord = true;
        this.sampleDelivery.deliveryDate = new Date();
        this.maxDate.setDate(this.minDate.getDate() + 31);

      } else {

        this.title = `EDIT RECORD: ${Id}`;
        this.isNewRecord = false;
        this.phlebotomistValidator = true;
        this.getSampleDelivery(Id);
      }

    } else {

      this.router.navigate(['/login']);
    }
  }

  public onSubmit(): void {

    this.progressSpinner = true;

    if (this.isNewRecord === true) {

      this.addNewRow();

    } else {

      this.editRow();
    }

  }

  public addNewRow() {

    this.sampleDeliveryService.postSampleDelivery(this.sampleDelivery)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.sampleDelivery = data as SampleDelivery,
        (error: string) => {

          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          this.sampleDeliveryAddNewEditForm.reset();
          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'success', summary: 'Record Created', detail: 'Record was created successfully.' });
        });
  }

  public editRow() {

    this.sampleDeliveryService.putSampleDelivery(this.sampleDelivery)
      .subscribe(() => {
        this.sampleDeliveryAddNewEditForm.reset();
        this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'success', summary: 'Record Edited', detail: 'The record was edited successfully.' });
      },
        (error: any) => {

          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getSampleDelivery(id: number): void {

    this.sampleDeliveryService.getSampleDelivery(id)
      .subscribe((res: any) => this.sampleDelivery = res as SampleDelivery,
        (error: any) => {

          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.sampleDelivery.deliveryDate !== null) {
            this.sampleDelivery.deliveryDate = new Date(this.sampleDelivery.deliveryDate.toString());
          }

        });
  }

  public getPatient(id: number): void {

    this.patientService.getPatient(id)
      .subscribe((res: any) => this.patient = res as Patient,
        (error: any) => {

          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewRecord === true) {

            this.title = `Add new record: ${this.patient.fullName}`;
            this.sampleDelivery.patientId = this.patient.id;
            this.sampleDelivery.patientName = this.patient.fullName;
          } else {

            this.title = `Edit record to ${this.patient.fullName}`;
            this.patientId = this.patient.id;
          }

          this.getLaboratories();

        });
  }

  public findPhlebotomist(): void{
    
    this.displaySearchPhlebotomist = false;

    this.phlebotomistService.getMobilePhlebotomists(this.pinCode)
      .subscribe((res: any) => {
        this.phlebotomists = res as Phlebotomist[];
        this.pinCode = '';
        if (this.phlebotomists.length > 0) {
          this.sampleDelivery.phlebotomistName = this.phlebotomists[0].name;
          this.phlebotomistValidator = true;
        } else {
          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'warn', summary: 'Warn', detail: 'Phlebotomist not found.' });
        }
      },
        (error: any) => {
          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getLaboratories(): void {

    this.laboratoryService.getUserDefaultLaboratories(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.laboratories = data as Laboratory[],
        (error: any) => {

          console.log(error);
        },
        () => {

          if (this.sampleDelivery !== undefined) {

            const filterLaboratory = this.laboratories.filter(a => a.name === this.sampleDelivery.laboratoryName);

            if (filterLaboratory !== undefined) {
              this.selectLaboratory = filterLaboratory[0];
            }
          }

        });
  }

  public findSignatureName(): void {

    this.displaySearchSignature = false;

    if (this.sampleDelivery.sampleDeliveryDetails.length === 0) {
      this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'warn', summary: 'Warn', detail: 'Please add details first and try again.' });
      return;
    }    

    this.sampleDeliveryService.getSignaturePinCode(+this.signingPincode)
      .subscribe((data: any) => {
        this.signaturePincode = data as SignaturePinCode;
        this.signingPincode = '';
        if (this.signaturePincode !== undefined) {
          this.sampleDelivery.signatureName = this.signaturePincode.nameSigning;
          this.sampleDelivery.signature = true;
          this.editRow();
        } else {
          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'warn', summary: 'Warn', detail: 'Signature pincode not found.' });
        }
      },
        (error: any) => {
          this.messageService.add({ key: 'sampledeliveryaddneweditkey', severity: 'error', summary: 'Error', detail: error });
        },
      );
  }

  public onChangeLaboratory(event: any): void {

    this.sampleDelivery.laboratoryName = event.name;
  }

  public onCloseToast(): void {

    this.progressSpinner = false;
    window.open('/sample-delivery');
    //this.router.navigate(['/sample-delivery']); 
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  }

}
