import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { QuickAddService } from './quick-add.service';
import { AccountService } from '../account/account.service';
import { QuickAddGlobalObjectService } from './quick-add-global-object.service';

//core
import { QuickApp } from './quick-add';
import { User } from '../account/user';

// PrimeNg
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html'
})
export class QuickAddComponent extends OnDestroyMixin implements OnInit {

  private quickAdds: QuickApp[] = [];
  private quickAdd = {} as QuickApp;
  private userLogged: User;
  private progressSpinner = false;
  private isNewRecord = false;
  private displayQuickAddDialog = false;
  private dialogHeader = 'QUICK ADD PATIENT';
  private loading = true;
  private cols: any[];
  private patientId = 0;
  private deleteModal = false;

  @ViewChild('quickAddform', { static: false }) public quickAddform: NgForm;

  constructor(private readonly quickAddService: QuickAddService,
    private readonly quickAddGlobalObjectService: QuickAddGlobalObjectService,
    private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {
        
    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();
      this.getQuickAdds();

      //if (this.isNewRecord === true) {

      //  this.dialogHeader = 'ADD NEW PATIENT';
      //}
      //else {

      //  //this.dialogHeader = `EDIT PATIENT: ${this.Id}`;
      //  //this.getPatient(this.patientId);
      //}
    } else {
      this.router.navigate(['/account/login']);
    }
  }

  public getQuickAdds(): void {

    this.loading = true;

    this.quickAddService.getQuickAdds(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.quickAdds = data as QuickApp[];
        this.loadColumns();
        this.loading = false;
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public showPopupScreenNewRecord() {

    this.dialogHeader = 'ADD NEW PATIENT';
    this.displayQuickAddDialog = true;
    this.isNewRecord = true;
  }

  public showPopupScreenEditRecord(entity: QuickApp) {

    this.dialogHeader = `EDIT PATIENT: ${entity.firstName}`;
    this.quickAdd = entity;
    this.displayQuickAddDialog = true;
    this.isNewRecord = false;

    if (this.quickAdd.dateService !== null) {
      this.quickAdd.dateService = new Date(this.quickAdd.dateService);
    }
  }

  public onSubmit(): void {

    this.progressSpinner = true;
    this.quickAdd.userId = this.userLogged.id;

    if (this.isNewRecord === true) {
      // Add New
      this.quickAddService.post(this.quickAdd)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (data) => {
            this.quickAdd = data as QuickApp;
          },
          (error: string) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'quickaddkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {
            
            this.progressSpinner = false;
            this.displayQuickAddDialog = false;
            this.messageService.add({ key: 'quickaddkey', severity: 'success', summary: 'Patient Created', detail: 'Created successfully.' });
            this.quickAddform.reset();
            this.quickAdd = {} as QuickApp;
            this.getQuickAdds();
          });      

    } else {
     // Edit
      this.progressSpinner = true;

      this.quickAddService.put(this.quickAdd)
        .subscribe((res: any) => {

          this.progressSpinner = false;
          this.displayQuickAddDialog = false;
          this.messageService.add({ key: 'quickaddkey', severity: 'success', summary: 'Patient Edited', detail: `The record from ${this.quickAdd.fullName} was edited successfully.` });
          this.quickAddform.reset();
          this.quickAdd = {} as QuickApp;
          this.getQuickAdds();
        },
          (error: any) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'quickaddkey', severity: 'error', summary: 'Error', detail: error });
          });
    }
  }

  public addPatientBasicInfo(entity: QuickApp) {

    this.quickAddGlobalObjectService.SetQuickAddPatientData(entity);
    this.router.navigate(['/patient-details/0']);
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'fullName', header: 'Name' },
      { field: 'phoneNumber', header: 'Phone' },
      { field: 'dateService', header: 'Date Service' }
    ];
  }

  public showDeleteConf(id: number): void {

    this.patientId = id;
    this.deleteModal = true;
  }

  public delete(): void {

    this.deleteModal = false;

    this.quickAddService.delete(this.patientId)
      .subscribe((res: any) => {

        this.getQuickAdds();
        this.messageService.add({ key: 'quickaddkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'quickaddkey', severity: 'error', summary: 'Error', detail: error });
        });
  }
     
  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  } 

}
