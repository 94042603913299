//Angular
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SurveyService } from './survey.service';

// Objects
import { SurveyQuestion } from './survey-question';
import { Rating } from './rating';
import { ExistRating } from './existRating';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html'
})
export class SurveyComponent extends OnDestroyMixin implements OnInit {

  public phlebotomistId = 0;
  public orderId = 0;
  public surveyQuestions: SurveyQuestion[] = [];
  public surveyQuestion: SurveyQuestion;
  public ratings: Rating[] = [];
  public title: string;
  public rate: number = 0;
  public comment: string;
  public orderBy = 1;
  public hideButtonAndText = false;
  public existRating: ExistRating;
  public divisionValue: number = 0
  public percentValue: number = 0;

  constructor(private readonly surveyService: SurveyService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    this.surveyQuestion = {} as SurveyQuestion;
    this.existRating = {} as ExistRating;
    this.title = "Para nosotros evaluar el servicio ofrecido al hogar por nuestro personal escoja las estrellas del 1 al 5 siendo el 5 la puntuación mas alta de la calificación.";

    this.phlebotomistId = this.route.snapshot.params['phlebotomistId'];
    this.orderId = this.route.snapshot.params['orderId'];

    if (this.phlebotomistId !== undefined && this.orderId !== undefined) {
      this.getExistRating();
      this.getSurveyQuestions();
    }

  }

  public getSurveyQuestions(): void {

    this.surveyQuestions = [];

    this.surveyService.getSurveyQuestions()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.surveyQuestions = data as SurveyQuestion[],
        (error: any) => {
          this.messageService.add({ key: 'surveykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          const filterQuestions = this.surveyQuestions.filter(a => a.orderBy === this.orderBy);

          if (filterQuestions !== undefined) {
            this.surveyQuestion = filterQuestions[0];
          }

          this.divisionValue = 100 / this.surveyQuestions.length;

        });
  }

  public buttonBack(): void {

    this.orderBy = this.orderBy - 1;

    this.percentValue = this.percentValue - this.divisionValue;

    if (this.orderBy === 1) {

      this.percentValue = 0;
    }

    this.percentValue = +Math.round(this.percentValue).toFixed(2);

    const filterQuestions = this.surveyQuestions.filter(a => a.orderBy === this.orderBy);

    if (filterQuestions !== undefined) {
      this.surveyQuestion = filterQuestions[0];
    }

    this.ratings.splice(this.orderBy - 1);

    this.rate = 0;
    this.comment = '';
    console.log(this.ratings);
  }

  public buttonNext(): void {

    const submitDate = new Date();

    const rating: Rating = {
      id: 0,
      surveyQuestionId: this.surveyQuestion.id,
      phlebotomistId: this.phlebotomistId,
      orderId: this.orderId,
      RatingPoints: this.rate,
      comment: this.comment,
      submitDate: `${submitDate.toLocaleDateString('en-US')} ${submitDate.toLocaleTimeString('en-US')}`
    };
    this.ratings.push(rating);

    this.orderBy = this.orderBy + 1;

    this.percentValue = this.percentValue + this.divisionValue;

    this.percentValue = +Math.round(this.percentValue).toFixed(2);

    const filterQuestions = this.surveyQuestions.filter(a => a.orderBy === this.orderBy);

    if (filterQuestions !== undefined) {
      this.surveyQuestion = filterQuestions[0];
    }    

    this.rate = 0;
    this.comment = '';
    console.log(this.ratings);
  }

  public buttonSend(): void {

    this.percentValue = this.percentValue + this.divisionValue;

    const submitDate = new Date();

    const rating: Rating = {
      id: 0,
      surveyQuestionId: this.surveyQuestion.id,
      phlebotomistId: this.phlebotomistId,
      orderId: this.orderId,
      RatingPoints: this.rate,
      comment: this.comment,
      submitDate: `${submitDate.toLocaleDateString('en-US')} ${submitDate.toLocaleTimeString('en-US')}`
    };
    this.ratings.push(rating);

    this.surveyService.postSurvey(this.ratings)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (res: any) => {
          this.messageService.add({ key: 'surveykey', severity: 'success', summary: 'Survey', detail: 'The data was sent successfully.' });
        },
        (error: string) => {
          this.messageService.add({ key: 'surveykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.orderBy = 0;
          this.surveyQuestion = {} as SurveyQuestion;
          this.hideButtonAndText = true;
          this.title = "Gracias, su opinión es importante para nosotros."
          this.ratings = [];
        });
  }

  public getExistRating(): void {

    this.surveyService.existRating(this.orderId, this.phlebotomistId)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.existRating = data as ExistRating,
        (error: any) => {
          this.messageService.add({ key: 'surveykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.existRating.existRating) {

            this.orderBy = 0;
            this.surveyQuestion = {} as SurveyQuestion;
            this.hideButtonAndText = true;
            this.title = this.existRating.message
            this.ratings = [];
          }

        });
  }

}
