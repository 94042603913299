// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';
import { PaginationParams } from '../shared/pagination/pagination';

//core
import { Patient } from './patient';
import { MobilePatient } from './mobile-patient';

@Injectable()
export class PatientService extends BaseService {

  private readonly apiUrl = 'api/patient';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getPatient(id: number): Observable<Patient> {

    return this.http.get<Patient>(`${this.apiUrl}/${id}`);
  }

  public getPatients(): Observable<Patient[]> {

    return this.http.get<Patient[]>(this.apiUrl);
  }
  
  public getPatientsAsync(paginationParams: PaginationParams): Observable<Patient[]> {

    const params = paginationParams;

    //console.log("event: getPatientsAsyn");
    //console.log(params);

    return this.http.post<Patient[]>(`${this.apiUrl}/pagination`, params, { headers: this.PutPostHeaders });
  }

  public getPatientsAsyncCount(paginationParams: PaginationParams): Observable<number> {

    const params = paginationParams;

    return this.http.post<number>(`${this.apiUrl}/count`, params, { headers: this.PutPostHeaders });
  }

  public getPatientsByIds(ids: string): Observable<MobilePatient[]> {

    return this.http.get<MobilePatient[]>(`${this.apiUrl}/patients-by-ids/${ids}`);
  }
    
  public putPatient(patient: Patient): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}`, patient, { headers: this.PutPostHeaders });
  }

  public postPatient(patient: Patient): Observable<Patient> {

    return this.http.post<Patient>(`${this.apiUrl}`, patient, { headers: this.PutPostHeaders });
  }

  public deletePatient(patientId: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/${patientId}`);
  }

}
