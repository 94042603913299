//Angular
import { Component, OnInit } from '@angular/core';
// https://www.npmjs.com/package/@aspnet/signalr   *** npm i @aspnet/signalr ****
import { HubConnectionBuilder, HubConnection, HttpTransportType } from '@aspnet/signalr';
import { Router } from '@angular/router'

//Service
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { AccountService } from '../account/account.service';
import { UserLoggedInService } from '../account/user-logged-in.service';
import { FileService } from '../shared/service/file.service';;
import { PatientOrderAppMobileService } from '../patient-order-app-mobile/patient-order-app-mobile.service';


//Core
import { User } from '../account/user';
import { MessageNewOrder } from '../patient-order-app-mobile/message-new-order'
import { CountNewOrder } from '../patient-order-app-mobile/count-new-order';

import { saveAs } from 'file-saver';

//Primeng
import { MessageService } from 'primeng/api';
//import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent extends OnDestroyMixin implements OnInit {

  //public items: MenuItem[];
  public userLogged: User = {} as User;
  public isExpanded = false;
  public isAdmin = false;
  public isSuperAdmin = false;
  public newOrders = 0;
  public messageNewOrder: MessageNewOrder = {} as MessageNewOrder;
  public hubConnection!: HubConnection;
  public countNewOrders: CountNewOrder[] = [];
  public passwordExpired = false;

  constructor(private readonly accountService: AccountService,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly fileService: FileService,
    private readonly patientOrderAppMobileService: PatientOrderAppMobileService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {
      this.updateUserLogged();
      this.getCountNewOrder();
      this.listenerNewOrder();
    }
  }

  public listenerNewOrder() {

    let builder = new HubConnectionBuilder();
    //this.hubConnection = builder.withUrl("http://localhost:64854/neworder",
    this.hubConnection = builder.withUrl("https://homeservice.milaboratoriopr.com/neworder",
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      }).build();

    this.hubConnection.start()
      .then(() => console.log("Connection started"))
      .catch(error => console.log(error));

    this.hubConnection.on("BroadcastMessage", (message) => {

      this.messageNewOrder = message;

      if (this.messageNewOrder.laboratoryName === 'Record deleted successfully') {
        this.messageService.add({ key: 'navmenutkey', severity: 'error', summary: 'Deleted Order', detail: `Order #. ${this.messageNewOrder.orderId} ${this.messageNewOrder.laboratoryName}, Date of Service: ${this.messageNewOrder.dateService}` });
      } else if (this.messageNewOrder.laboratoryName === 'Record updated successfully') {
        this.messageService.add({ key: 'navmenutkey', severity: 'info', summary: 'Updated Order', detail: `Order #. ${this.messageNewOrder.orderId}, Date of Service: ${this.messageNewOrder.dateService}` });
      } else {
        this.messageService.add({ key: 'navmenutkey', severity: 'success', summary: 'New Order', detail: `Order #. ${this.messageNewOrder.orderId} ${this.messageNewOrder.laboratoryName}, Date of Service: ${this.messageNewOrder.dateService}` });
      }

      this.getCountNewOrder();
    });

  }

  public getCountNewOrder(): void {

    this.patientOrderAppMobileService.countNewOrder(this.userLogged.labLicenseNumberList)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
          this.countNewOrders = data as CountNewOrder[];
          //console.log(this.countNewOrders);
        },
        (error) => {
          this.messageService.add({ key: 'navmenutkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          this.newOrders = 0;
          for (let lab of this.countNewOrders) {
            this.newOrders += lab.quantity;
          }
        });
  }

  public getPatientOrders(): void {

    //this.router.navigate(['/patient-order-app-mobile/Nueva']);
    window.open('/patient-order-app-mobile/Nueva', '_self');
  }

  //public loadMenu(): void {

  //  this.items = [
  //    {
  //      label: 'PATIENTS',
  //      icon: '',
  //      visible: this.userLoggedIn,
  //      routerLink: ['/']
  //    },
  //    {
  //      label: 'File',
  //      items: [{
  //        label: 'New',
  //        icon: 'pi pi-fw pi-plus',
  //        items: [
  //          { label: 'Project' },
  //          { label: 'Other' },
  //        ]
  //      },
  //      { label: 'Open' },
  //      { label: 'Quit' }
  //      ]
  //    },
  //    {
  //      label: 'Edit',
  //      icon: 'pi pi-fw pi-pencil',
  //      items: [
  //        { label: 'Delete', icon: 'pi pi-fw pi-trash' },
  //        { label: 'Refresh', icon: 'pi pi-fw pi-refresh' }
  //      ]
  //    },
  //    {
  //      label: 'LOGOUT',
  //      icon: 'pi pi-fw pi-power-off',
  //      command: () => this.logout(),
  //      routerLink: ["/login"]
  //    }
  //  ];
  //}

  public downloadAndroidApp(): void {
    this.fileService.downloadAndroidApp().subscribe((blob: any) => {
      const newBlob = new Blob([blob], { type: 'application/octet-stream' });
      saveAs(newBlob, 'app-home-service v1.33.apk');
    });
  }

  public logout(): void {

    this.accountService.logout()
      .subscribe(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.userLogged = {} as User;
        this.userLoggedInService.setUserLoggedIn(false);
        this.userLoggedInService.updateUserLogged(null);
        this.router.navigate(['/login']);
      },
        (error: any) => {

          this.messageService.add({ key: 'navmenutkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }  

  public updateUserLogged(): void {

    this.userLoggedInService.userLoggedScast.subscribe(userLogged => {

      if (userLogged === undefined) {
        this.userLogged = this.accountService.getLoggedInUser();        
      } else {
        this.userLogged = userLogged;
      }

      if (this.userLogged != null) {
        this.passwordExpired = this.userLogged.passwordExpired;
      }

    });

    const userRoles = this.userLogged!.roles.filter(row => row === 'Admin' || row === 'SuperAdmin' || row === 'CustomerServiceUser');

    userRoles.forEach((value) => {

      if (value === 'Admin') {
        this.isAdmin = true;
      } else if (value === 'SuperAdmin') {
        this.isSuperAdmin = true;
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }

    });

  }

}
