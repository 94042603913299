// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Objects
import { Laboratory } from './laboratory';

@Injectable()
export class LaboratoryService {

  private readonly apiUrl = 'api/laboratory';

  public constructor(private readonly http: HttpClient) {
  }

  public getLaboratories(): Observable<Laboratory[]> {

    return this.http.get<Laboratory[]>(this.apiUrl);
  }

  public getUserDefaultLaboratories(userId: string): Observable<Laboratory[]> {

    return this.http.get<Laboratory[]>(`${this.apiUrl}/defaultLabs/${userId}`);
  }
    
}
