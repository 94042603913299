//Angualr
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../../core/base.service';

@Injectable()
export class FileService extends BaseService {

  private readonly apiUrl = 'api/file';

  constructor(private readonly http: HttpClient) {
    super();
  }

  public downloadAndroidApp(): Observable<Blob> {

    return this.http.get(`${this.apiUrl}/download-android-app`, { headers: this.PutPostHeaders, responseType: 'blob' });
  }
}
