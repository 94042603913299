// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//core
import { Procedure } from './procedure';

@Injectable()
export class ProcedureService extends BaseService {

  private readonly apiUrl = 'api/procedure';

  public constructor(private readonly http: HttpClient) {
    super();
  }  

  public getProcedures(): Observable<Procedure[]> {

    return this.http.get<Procedure[]>(this.apiUrl);
  }  
}
