// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

// Objects
import { TemperatureLog } from './temperature-log';
import { FilterTemperatureLogParams } from '../patient-order/model/filter-params';

@Injectable()
export class TemperatureLogService extends BaseService {

  private readonly apiUrl = 'api/temperaturelog';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getTemperatureLogsWithFilter(fromDate: Date, toDate: Date): Observable<TemperatureLog[]> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      phlebotomistName: ''
    } as FilterTemperatureLogParams;

    return this.http.post<TemperatureLog[]>(`${this.apiUrl}/temperature-log-with-filter`, fParams, { headers: this.PutPostHeaders });
  }

  public getTemperatureLogReport(fromDate: Date, toDate: Date, phlebotomist: string): Observable<Blob> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      phlebotomistName: phlebotomist
    } as FilterTemperatureLogParams;

    return this.http.post(`${this.apiUrl}/temperature-log-report`, fParams, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

}
