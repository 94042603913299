import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//PrimeNg
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { CaptchaModule } from 'primeng/captcha';
import { ToastModule } from 'primeng/toast';
import { FieldsetModule } from 'primeng/fieldset';
import { GMapModule } from 'primeng/gmap';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from "primeng/password";
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SpinnerModule } from 'primeng/spinner';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { GalleriaModule } from 'primeng/galleria';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    CardModule,
    CheckboxModule,
    CaptchaModule,
    ToastModule,
    FieldsetModule,
    FullCalendarModule,
    GMapModule,
    InputNumberModule,
    MultiSelectModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    InputMaskModule,
    FileUploadModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    EditorModule,
    PasswordModule,
    PanelModule,
    CarouselModule,
    CalendarModule,
    ChartModule,
    DropdownModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    TableModule,
    ScrollPanelModule,
    TooltipModule,
    SpinnerModule,
    SidebarModule,
    ListboxModule,
    RadioButtonModule,
    RatingModule,
    OverlayPanelModule,
    GalleriaModule,
    SplitButtonModule
  ],

  declarations: [
    //NavigationComponent,
  ],

  providers: [
    ConfirmationService,
    MessageService
  ],
  exports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    CardModule,
    CheckboxModule,
    CaptchaModule,
    ToastModule,
    FieldsetModule,
    FullCalendarModule,
    GMapModule,
    InputNumberModule,
    MultiSelectModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    InputMaskModule,
    FileUploadModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    EditorModule,
    PasswordModule,
    PanelModule,
    CarouselModule,
    CalendarModule,
    ChartModule,
    DropdownModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    TableModule,
    ScrollPanelModule,
    TooltipModule,
    SpinnerModule,
    SidebarModule,
    ListboxModule,
    RadioButtonModule,
    RatingModule,
    OverlayPanelModule,
    GalleriaModule,
    SplitButtonModule
  ]
})

export class SharedModule { }
