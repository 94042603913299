//Angular
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from '../../patient/patient.service';
import { AccountService } from '../../account/account.service';
import { PatientOrderService } from './../patient-order.service';
import { LaboratoryService } from '../../laboratory/laboratory.service';
import { PhlebotomistService } from '../../phlebotomist/phlebotomist.service';
import { ProcedureService } from '../../procedure/procedure.service';
import { OrderProcedureService } from '../../order-procedure/order-procedure.service';

//Core
import { Patient } from '../../patient/patient';
import { PatientOrder } from './../patient-order';
import { User } from '../../account/user'
import { Laboratory } from '../../laboratory/laboratory';
import { Phlebotomist } from '../../phlebotomist/phlebotomist';
import { MapLocation } from '../../map/location-model'
import { Item } from '../model/item';
import { PatientDocument } from '../model/patientDocument'
import { Procedure } from '../../procedure/procedure';
import { OrderProcedureDto } from '../../order-procedure/order-procedure-dto';

//Primeng
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-shared-visit',
  templateUrl: './shared-visit.component.html'
})
export class SharedVisitComponent extends OnDestroyMixin implements OnInit {

  public title = 'CREATE NEW SHARED VISIT';
  public patientOrder: PatientOrder;
  public patients: Patient[] = [];
  public selectedPatients: Patient[] = [];
  public patientDocument: PatientDocument;
  public patient: Patient;
  public userLogged: User;
  public laboratories: Laboratory[] = [];
  public selectLaboratory: Laboratory;
  public labInfo: string;
  public phlebotomists: Phlebotomist[] = [];
  public selectPhlebotomist: Phlebotomist;
  public progressSpinner = false;
  public isNewOrder = false;
  public orderStatusList: Item[] = [];
  public selectOrderStatus: Item;
  public readonlyOrderStatus = false;
  public cols: any[];
  public showDocument = false;
  public imageId = 0;
  public deleteModal = false;
  public orderId = 0;
  public orderStatusValidator = true;
  public isCancelledOrderStatus = false;
  public patientAddProcedure = {} as Patient;
  @ViewChild('patientOrderDetailsForm', { static: false }) public patientOrderDetailsForm: NgForm;
  public procedures: Procedure[] = [];
  public filteredProcedures: Procedure[] = [];
  public selectedProcedures: Procedure[] = [];
  public orderProcedures: OrderProcedureDto[] = [];
  public overlaypanel: OverlayPanel;
  public smallProgressSpinner = false;

  public markers: MapLocation[] = [];
  public lat = 18.042216923829237;
  public lng = -67.05580472946167;
  public zoom = 11;

  constructor(private readonly patientOrderService: PatientOrderService,
    private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly laboratoryService: LaboratoryService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly procedureService: ProcedureService,
    private readonly orderProcedureService: OrderProcedureService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly zone: NgZone) {
    super();

    this.orderStatusList = [
      { label: 'New Visit', value: 'New Visit' },
      { label: 'In Transit', value: 'In Transit' },
      { label: 'Take Samples', value: 'Take Samples' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Cancelled', value: 'Cancelled' }
    ];
  }

  public ngOnInit(): void {

    this.patient = {} as Patient;
    this.patientOrder = { documents: []} as PatientOrder;
    this.patientDocument = {} as PatientDocument;

    const patientId = this.route.snapshot.params['patientId'];
    this.orderId = +this.route.snapshot.params['orderId'];

    this.userLogged = this.getlocalUser();    

    if (patientId !== undefined) {

      this.getPatient(patientId);
    }
 
    if (this.orderId === 0) {

      this.title = 'ADD NEW SHARED VISIT';
      this.isNewOrder = true;
      this.patientOrder.estimatedTime = 15;
      this.patientOrder.dateService = undefined;
      this.patientOrder.dateService = new Date();
    } else {

      this.title = `EDIT SHARED VISIT: ${this.orderId}`;
      this.isNewOrder = false;
      this.getPatientOrder(this.orderId);
    }

  }

  public getPatient(id: number): void {

    this.patientService.getPatient(id)
      .subscribe((res: any) => this.patient = res as Patient,
        (error: any) => {

          this.messageService.add({ key: 'sharedvisitkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewOrder === true) {

            this.title = `Add new visit to ${this.patient.fullName}`;           
            this.getPatients();

          } else {

            this.title = `Edit visit to ${this.patient.fullName}`;
          }

          if (this.patient !== undefined && this.patient.latitude !== undefined) {

            this.patientOrder.latitude = this.patient.latitude
            this.lat = parseFloat(this.patient.latitude);
          }

          if (this.patient !== undefined && this.patient.longitude !== undefined) {

            this.patientOrder.longitude = this.patient.longitude
            this.lng = parseFloat(this.patient.longitude);
          }
          
        });
  }

  public getPatients(): void {

    this.patientService.getPatients()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.patients = data as Patient[],
        (error: any) => {
          this.messageService.add({ key: 'sharedvisitkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewOrder === false) {

            if (this.patientOrder.sharedVisitPatientIds !== undefined && this.patientOrder.sharedVisitPatientIds !== null) {

              var lstPatientIds: string[] = this.patientOrder.sharedVisitPatientIds.split(',');

              if (lstPatientIds.length == 0 && this.patientOrder.sharedVisitPatientIds.length > 0) {
                lstPatientIds.push(this.patientOrder.sharedVisitPatientIds);
              }

              if (lstPatientIds.length > 0) {
                this.selectedPatients.push(this.patient);
              }

              lstPatientIds.forEach((id) => {

                const filterPatient = this.patients.filter(p => p.id === +id);

                if (filterPatient !== undefined) {
                  this.selectedPatients.push(filterPatient[0]);
                }

              });

              this.loadColumns();
            }

          }

          //this.patients = this.patients.filter(p => p.id !== this.patient.id);
          this.createLabAndPatientMarkers();
          this.getLaboratories();
        });
  }  

  public getPatientOrder(orderid: number): void {

    this.patientOrderService.getVisitDetails(orderid)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.patientOrder = data as PatientOrder,
        (error: any) => {

          this.messageService.add({ key: 'sharedvisitkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.isNewOrder === false) {

            if (this.patientOrder.dateService !== null) {

              this.patientOrder.dateService = new Date(this.patientOrder.dateService);
            }

            const filterOrderStatus = this.orderStatusList.filter(a => a.value === this.patientOrder.orderStatus);

            if (filterOrderStatus !== undefined) {
              this.selectOrderStatus = filterOrderStatus[0];
            }

            if (this.patientOrder.orderStatus === 'Cancelled') {
              this.readonlyOrderStatus = true;
            } else {
              this.readonlyOrderStatus = false;
            }

            this.getPatients();
          }

        });
  }

  public getLaboratories(): void {

    this.laboratoryService.getUserDefaultLaboratories(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.laboratories = data as Laboratory[],
        (error: any) => {
          console.log(error);
        },
        () => {          

          if (this.isNewOrder === true) {

            if (this.laboratories.length === 1) {

              this.selectLaboratory = this.laboratories[0];
              this.patientOrder.labLicenseNumber = this.laboratories[0].licenseNumber;
              this.patientOrder.laboratoryName = this.laboratories[0].name;
            }

          } else {

            const filterLaboratory = this.laboratories.filter(a => a.licenseNumber === this.patientOrder.labLicenseNumber);

            if (filterLaboratory !== undefined) {
              this.selectLaboratory = filterLaboratory[0];
              this.createLabAndPatientMarkers();
            }

          }

          this.getPhlebotomists();
        });
  }

  public onChangeLaboratory(event: any): void {

    this.patientOrder.labLicenseNumber = event.licenseNumber;
    this.patientOrder.laboratoryName = event.name;
    this.labInfo = `${event.physicalAddressLine1} | Tel. ${event.phoneNumber}`;
    this.createLabAndPatientMarkers();
  }

  public getPhlebotomists(): void {

    this.phlebotomists = [];

    this.phlebotomistService.getUserDefaultPhlebotomists(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.phlebotomists = data as Phlebotomist[],
        (error: any) => {
          console.log(error);
        },
        () => {          
          this.getProcedures();
          if (this.isNewOrder === true) {

            if (this.phlebotomists.length === 1) {

              this.selectPhlebotomist = this.phlebotomists[0];
              this.patientOrder.phlebotomistId = this.phlebotomists[0].id;
            }

            if (this.phlebotomists.length > 0) {

              var isNotNeighborhood = false;

              this.phlebotomists.forEach(phlebotomist => {

                if (phlebotomist.neighborhoodList !== undefined && phlebotomist.neighborhoodList !== null) {

                  var neighborhoods: string[] = phlebotomist.neighborhoodList.split(',');

                  neighborhoods.forEach((n) => {

                    var id = Number(n);

                    if (this.patient.neighborhoodId === id) {

                      const filterPhlebotomist = this.phlebotomists.filter(a => a.id === phlebotomist.id);

                      if (filterPhlebotomist !== undefined) {
                        this.selectPhlebotomist = filterPhlebotomist[0];
                        this.patientOrder.phlebotomistId = filterPhlebotomist[0].id;
                        isNotNeighborhood = true;
                      }

                    }

                  });
                }

              });

              if (isNotNeighborhood === false) {

                this.selectPhlebotomist = this.phlebotomists[0];
                this.patientOrder.phlebotomistId = this.phlebotomists[0].id;
              }
            }
          } else {

            const filterPhlebotomist = this.phlebotomists.filter(a => a.id === this.patientOrder.phlebotomistId);

            if (filterPhlebotomist !== undefined) {
              this.selectPhlebotomist = filterPhlebotomist[0];
            }

          }
        });
  }

  public onChangePhlebotomist(event: any): void {

    this.patientOrder.phlebotomistId = event.id;
  }

  public createLabAndPatientMarkers(): void {

    this.markers = [];

    if (this.selectLaboratory !== undefined) {
      const labLocation: MapLocation = {
        name: this.selectLaboratory.name,
        lat: parseFloat(this.selectLaboratory.latitude),
        lng: parseFloat(this.selectLaboratory.longitude),
        iconUrl: '/assets/green-dot.png',
        label: ''
      };
      this.markers.push(labLocation);
    }

    const patientLocation: MapLocation = {
      name: this.patient.fullName,
      lat: parseFloat(this.patient.latitude),
      lng: parseFloat(this.patient.longitude),
      iconUrl: '/assets/red-dot.png',
      label: ''
    };
    this.markers.push(patientLocation);
  }

  public onSubmit(): void {

    this.progressSpinner = true;

    // === Shared Visit ===
    if (this.selectedPatients !== null && this.selectedPatients.length > 0) {

      this.patientOrder.sharedVisit = true;

      this.patientOrder.sharedVisitPatientIds = null;

      this.selectedPatients.forEach(row => {

        if (this.patientOrder.sharedVisitPatientIds !== undefined && this.patientOrder.sharedVisitPatientIds !== null) {                   

          if (row.id !== this.patient.id) {
            this.patientOrder.sharedVisitPatientIds = `${this.patientOrder.sharedVisitPatientIds},${row.id.toString()}`;
          }

        } else {
          if (row.id !== this.patient.id) {
            this.patientOrder.sharedVisitPatientIds = row.id.toString();
          }          
        }     

      });

    } else {

      this.patientOrder.sharedVisitPatientIds = null;
      this.patientOrder.sharedVisit = false;
    }

    if (this.isNewOrder === true) {

      this.patientOrder.patientId = this.patient.id
      this.patientOrder.userId = this.userLogged.id;
      this.patientOrder.orderStatus = "New Visit";

      this.patientOrderService.postSharedVisit(this.patientOrder)
        .pipe(untilComponentDestroyed(this))
        .subscribe(() => {

          this.messageService.add({ key: 'sharedvisitkey', severity: 'success', summary: 'Shared Visit Created', detail: 'Shared Visit was created successfully.' });
          this.patientOrderDetailsForm.reset();
          this.patientOrder = {} as PatientOrder;
        },
          (error: string) => {
            this.messageService.add({ key: 'sharedvisitkey', severity: 'error', summary: 'Error', detail: error });
          });

    } else {
      // == Edit ===

      this.patientOrderService.putPatientOrder(this.patientOrder)
        .subscribe(() => {

          this.messageService.add({ key: 'sharedvisitkey', severity: 'success', summary: 'Shared Visit Edited', detail: 'The record was edited successfully.' });
        },
          (error: any) => {

            this.messageService.add({ key: 'sharedvisitkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.patientOrderDetailsForm.reset();
            this.patientOrder = {} as PatientOrder;
          });

    }
  }

  public onChangeOrderStatus(event: any): void {

    if (event.value === 'Cancelled') {
      this.isCancelledOrderStatus = true;
      this.orderStatusValidator = false;
    } else {
      this.isCancelledOrderStatus = false;
      this.orderStatusValidator = true;
      this.patientOrder.cancelOrderComment = '';
    }

    this.patientOrder.orderStatus = event.value;
  }

  public onKeyCancelOrderComment(value: string): void {

    if (value.length > 2) {
      this.orderStatusValidator = true;
    } else {
      this.orderStatusValidator = false;
    }

  }

  public onCloseToast(): void {

    this.progressSpinner = false;

    this.zone.run(() => {
      this.router.navigate(['/visits']);
    });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'fullName', header: 'Name' },
      { field: 'phoneNumber', header: 'Phone Number' }
    ];
  }

  public openSharedPatientsTable(event: any, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }

  public goToPatientDetails(id: number): void {

    this.router.navigate([`/patient-details/${id}`]);
  }

  public openPatientAddProcedures(event: any, overlaypanel: OverlayPanel, id: number): void {
    overlaypanel.toggle(event);
    this.overlaypanel = overlaypanel;
    const filterPatient = this.patients.filter(a => a.id === id);
    if (filterPatient !== undefined) {
      this.patientAddProcedure = filterPatient[0];
    }
    //console.log(this.patientAddProcedure.fullName);
    this.orderProcedureService.getOrderProcedures(id, this.patientOrder.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.selectedProcedures = data as OrderProcedureDto[];
      },
        (error: any) => {
          console.log(error);
        });
  }

  public submitProcedures(id: number): void {
    //console.log(id);
    this.smallProgressSpinner = true;

    this.selectedProcedures.forEach(procedure => {
      let newRow = { patientId: id, patientOrderId: this.patientOrder.id, sharedVisit: true, quickSearchCode: procedure.quickSearchCode, descriptionCustom: procedure.descriptionCustom } as OrderProcedureDto;
      this.orderProcedures.push(newRow);
    });

    this.orderProcedureService.postOrderProcedure(this.orderProcedures)
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.orderProcedures = [];
        this.selectedProcedures = [];
        this.smallProgressSpinner = false;
        this.overlaypanel.hide();
      },
        (error: any) => {
          console.log(error);
          this.smallProgressSpinner = false;
        });
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

  public previewDocument(document: PatientDocument): void {

    this.patientDocument = document;
    this.showDocument = true;
  }

  public downloadDocument(document: PatientDocument): void {

    const nav = (window.navigator as any);

    if (nav && nav.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(image.pathFile);
    } else {
      const fileURL = document.uriDocumentPreview;
      window.open(fileURL);
    }
  }

  public showDeleteConf(id: number): void {

    this.imageId = id;
    this.deleteModal = true;
  }

  public deleteDocument(): void {

    this.deleteModal = false;

    this.patientOrderService.deletePatientDocument(this.imageId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'visitdetailskey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.patientOrder = {} as PatientOrder;
        this.getPatientOrder(this.orderId);

      },
        (error: any) => {

          this.messageService.add({ key: 'visitdetailskey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getProcedures(): void {

    this.procedureService.getProcedures()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.procedures = data as Procedure[];
      },
        (error: any) => {
          console.log(error);
        });
  }

  public filterProcedures(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.procedures.length; i++) {
      let procedure = this.procedures[i];
      if (isNaN(query)) {
        if (procedure.descriptionCustom.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(procedure);
        }
      } else {
        if (procedure.quickSearchCode.toString().indexOf(query) == 0) {
          filtered.push(procedure);
        }
      }
    }

    this.filteredProcedures = filtered;
  }

  //public selectProcedures(event) {

  //  console.log(event);
  //}

  public loadProcedures(procedures: OrderProcedureDto[]) {
    let json = JSON.stringify(procedures);
    this.selectedProcedures = JSON.parse(json);
  }

}
