//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PhlebotomistService } from './phlebotomist.service';
import { AccountService } from '../account/account.service';
import { UserLoggedInService } from '../account/user-logged-in.service';
import { NeighborhoodService } from '../neighborhood/neighborhood.service';
import { PatientOrderService } from '../patient-order/patient-order.service';

//Core
import { Phlebotomist } from './phlebotomist';
import { User } from '../account/user'
import { Neighborhood } from '../neighborhood/neighborhood';
import { ShortPatientOrder } from '../patient-order/short-patient-order';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';
import { count } from 'rxjs/operators';

@Component({
  selector: 'app-phlebotomist',
  templateUrl: './phlebotomist.component.html'
})
export class PhlebotomistComponent extends OnDestroyMixin implements OnInit {

  public phlebotomists: Phlebotomist[] = [];
  public phlebotomist: Phlebotomist;
  public selectPhlebotomist: Phlebotomist;
  public displayPhlebotomistDialog = false;
  public isNewPhlebotomist = false;
  public dialogHeader: string;
  public userLogged: User;
  public progressSpinner = false;
  public cols: any[];
  public loading: boolean;
  public phlebotomistId = 0;
  public btnChangeVisitsToPhleb = false;
  public showPinCodeByAdminUser = false;
  public neighborhoods: Neighborhood[] = [];
  public selectedNeighborhoods: Neighborhood[] = [];
  public eventsInactiveOptions: SelectItem[] = [];
  public displayChangeVisitsPhlebDialog = false;
  public patientOrders: ShortPatientOrder[] = [];
  public selectedPatientOrders: ShortPatientOrder[] = [];
  public setVisitsPhlebotomistId = 0;
  public newPhlebotomisValidator = false;
  @ViewChild('phlebotomistForm', { static: false }) public phlebotomistForm: NgForm;

  constructor(private readonly phlebotomistService: PhlebotomistService,
    private readonly accountService: AccountService,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly neighborhoodService: NeighborhoodService,
    private readonly patientOrderService: PatientOrderService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();

    this.eventsInactiveOptions = [
      { label: '', value: undefined },
      { label: 'true', value: 'true' },
      { label: 'false', value: 'false' }
    ];
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.phlebotomist = {} as Phlebotomist;

      this.userLogged = this.getlocalUser();

      this.getPhlebotomists();      

    } else {

      this.router.navigate(['/login']);
    }

  }

  public getPhlebotomists(): void {

    this.loading = true;
    this.phlebotomists = [];

    this.phlebotomistService.getPhlebotomists()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.phlebotomists = data as Phlebotomist[];
        this.loadColumns();
        this.loading = false;

        this.getNeighborhoods();        
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'phlebotomistkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public onSubmit(): void {

    this.progressSpinner = true;

    this.phlebotomist.userId = this.userLogged.id;

    if (this.selectedNeighborhoods !== null && this.selectedNeighborhoods.length > 0) {

      var counter = 0;

      this.selectedNeighborhoods.forEach(row => {

        counter = counter + 1;

        if (counter === 1) {
          this.phlebotomist.neighborhoodList = row.id.toString();
        } else {
          this.phlebotomist.neighborhoodList = `${this.phlebotomist.neighborhoodList},${row.id.toString()}`;
        }

      });
    } else {

      this.phlebotomist.neighborhoodList = null;
    }

    if (this.isNewPhlebotomist === true) {

      this.phlebotomistService.postPhlebotomist(this.phlebotomist)
        .pipe(untilComponentDestroyed(this))
        .subscribe((data) => this.phlebotomist = data as Phlebotomist,
          (error: string) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'phlebotomistkey', severity: 'error', summary: 'Error', detail: error });

          },
          () => {
            
            this.messageService.add({ key: 'phlebotomistkey', severity: 'success', summary: 'Phlebotomist Created', detail: `${this.phlebotomist.name} was created successfully.` });
            this.phlebotomistForm.reset();
            this.phlebotomist = {} as Phlebotomist;
            this.progressSpinner = false;
            this.displayPhlebotomistDialog = false;
            this.getPhlebotomists();
          });

    } else {

      this.phlebotomistService.putPhlebotomist(this.phlebotomist)
        .subscribe((res: any) => {
                    
          this.messageService.add({ key: 'phlebotomistkey', severity: 'success', summary: 'Phlebotomist Edited', detail: `The record from ${this.phlebotomist.name} was edited successfully.` });

        },
          (error: any) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'phlebotomistkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.phlebotomistForm.reset();
            this.phlebotomist = {} as Phlebotomist;
            this.progressSpinner = false;
            this.displayPhlebotomistDialog = false;
            this.getPhlebotomists();
          });
    }    
  }

  public addNewPhlebotomist(): void {

    this.phlebotomistForm.reset();
    this.phlebotomist = {} as Phlebotomist;
    this.isNewPhlebotomist = true;
    this.dialogHeader = "ADD NEW PHLEBOTOMIST";
    this.displayPhlebotomistDialog = true;
  }

  public editPhlebotomist(phlebotomist: Phlebotomist): void {

    this.phlebotomist = {} as Phlebotomist;
    this.phlebotomist = phlebotomist;
    this.selectedNeighborhoods = [];

    if (phlebotomist.neighborhoodList !== undefined && phlebotomist.neighborhoodList !== null) {

      var neighborhoods: string[] = phlebotomist.neighborhoodList.split(',');
      
      neighborhoods.forEach((n) => {

        var id = Number(n);

        const filterNeighborhood = this.neighborhoods.filter(a => a.id === id);

        if (filterNeighborhood !== undefined) {
          this.selectedNeighborhoods.push(filterNeighborhood[0]);
        }

      });
    }

    this.isNewPhlebotomist = false;
    this.dialogHeader = "EDIT PHLEBOTOMIST";
    this.displayPhlebotomistDialog = true;
  } 

  public loadColumns(): void {

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'route', header: 'Route' },
      { field: 'phoneNumber', header: 'Phone' },
      { field: 'submitDate', header: 'Submit Date' },
      { field: 'inactive', header: 'Inactive' }
    ];
  }

  public showFullMap(id: number): void {

    this.router.navigate([`/route-tracking/${id}`])
  }  

  public getNeighborhoods(): void {

    this.neighborhoodService.getNeighborhoods('allCities')
      .subscribe((res: any) => this.neighborhoods = res as Neighborhood[],
        (error: any) => {

          this.messageService.add({ key: 'phlebotomistkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public showChangeVisitsPhlebotomist(phlebotomist: Phlebotomist): void {

    this.selectedPatientOrders = [];
    this.displayChangeVisitsPhlebDialog = true;
    this.dialogHeader = `Current phlebotomist: (${phlebotomist.name})`;
    this.selectPhlebotomist = {} as Phlebotomist;
    this.getNewVisitsByPhlebotomist(phlebotomist.id);
  }

  public getNewVisitsByPhlebotomist(phlebotomistId: number): void {

    this.loading = true;
    this.patientOrders = [];

    this.patientOrderService.getNewVisitsByPhlebotomist(phlebotomistId)
      .subscribe((res: any) => {

        this.patientOrders = res as ShortPatientOrder[];
        this.loading = false;
        //console.log(this.patientOrders);
      },
        (error: any) => {
          this.loading = false;
          this.messageService.add({ key: 'phlebotomistkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public onChangePhlebotomist(event: any): void {

    this.setVisitsPhlebotomistId = event.id;
    this.newPhlebotomisValidator = true;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public onSubmitChangeVisitsPhleb(): void {

    this.progressSpinner = true;

    this.patientOrderService.putChangeVisitsPhlebotomist(this.setVisitsPhlebotomistId, this.selectedPatientOrders)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'phlebotomistkey', severity: 'success', summary: 'Phlebotomist Edited', detail: `All visits selected was changed fron phlebotomist to: ${this.selectPhlebotomist.name}.` });
        this.progressSpinner = false;
        this.selectedPatientOrders = [];
        this.displayChangeVisitsPhlebDialog = false;
        this.selectPhlebotomist = {} as Phlebotomist;
        this.setVisitsPhlebotomistId = 0;
        this.newPhlebotomisValidator = false;
      },
        (error: any) => {

          this.progressSpinner = false;
          this.messageService.add({ key: 'phlebotomistkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    this.userLoggedInService.updateUserLogged(userLogged);

    const userRoles = userLogged.roles.filter(row => row === 'Admin' || row === 'SuperAdmin');

    if (userRoles !== undefined) {
      this.btnChangeVisitsToPhleb = true;
      this.showPinCodeByAdminUser = true;
    }
    else {
      this.btnChangeVisitsToPhleb = false;
    }

    return userLogged;
  }

}
