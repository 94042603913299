// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

// Objects
import { Neighborhood } from './neighborhood';
import { NeighborhoodGroupedCities } from './neighborhood-grouped-cities';

@Injectable()
export class NeighborhoodService extends BaseService {

  private readonly apiUrl = 'api/neighborhood';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getNeighborhoods(city: string): Observable<Neighborhood[]> {

    const url = `${this.apiUrl}/neighborhoods/${city}`;

    return this.http.get<Neighborhood[]>(url);
  }

  public GetNeighborhoodGroupedCities(): Observable<NeighborhoodGroupedCities[]> {

    const url = `${this.apiUrl}/neighborhood-grouped-cities`;

    return this.http.get<NeighborhoodGroupedCities[]>(url);
  }

  public deleteNeighborhood(id: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/delete-neighborhood/${id}`);
  }

  public postNeighborhood(neighborhood: Neighborhood): Observable<Neighborhood> {
    //console.log(neighborhood);
    return this.http.post<Neighborhood>(`${this.apiUrl}/add-neighborhood`, neighborhood, { headers: this.PutPostHeaders });
  }

}
